import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { InputAdornment } from "@mui/material"
import Input from "../../Input"
import IconButton from "../../IconButton"
import Button from "../../Button"
import { useDispatch } from "../../../state/hooks"
import { changePassword } from "../../../state/userSlice"
import { Toast } from "../../../util/types"
import { pushToast } from "../../../state/toastSlice"

export default function ChangePasswordSettings() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.SettingsChangePasswordTab" })
  const dispatch = useDispatch()

  const [ showOldPassword, setShowOldPassword ] = React.useState<boolean>(false)
  const [ oldPassword, setOldPassword ] = React.useState<string>("")
  const [ oldPasswordError, setOldPasswordError ] = React.useState<boolean>(false)
  const [ oldPasswordErrorMessage, setOldPasswordErrorMessage ] = React.useState<string>("")
  const [ showNewPassword, setShowNewPassword ] = React.useState<boolean>(false)
  const [ newPassword, setNewPassword ] = React.useState<string>("")
  const [ newPasswordError, setNewPasswordError ] = React.useState<boolean>(false)
  const [ newPasswordFocused, setNewPasswordFocused ] = React.useState<boolean>(false)
  const [ newPasswordErrorMessage, setNewPasswordErrorMessage ] = React.useState<string>("")
  const [ showConfirmPassword, setShowConfirmPassword ] = React.useState<boolean>(false)
  const [ confirmPassword, setConfirmPassword ] = React.useState<string>("")
  const [ confirmPasswordError, setConfirmPasswordError ] = React.useState<boolean>(false)
  const [ confirmPasswordErrorMessage, setConfirmPasswordErrorMessage ] = React.useState<string>("")
  const [ confirmPasswordFocused, setConfirmPasswordFocused ] = React.useState<boolean>(false)

  const clearForm = () => {
    setShowOldPassword(false)
    setOldPassword("")
    setShowNewPassword(false)
    setNewPassword("")
    setNewPasswordError(false)
    setNewPasswordErrorMessage("")
    setShowConfirmPassword(false)
    setConfirmPassword("")
    setConfirmPasswordError(false)
    setConfirmPasswordErrorMessage("")
  }

  const showPasswordMatchError = (): boolean => {
    // Check state to display or not display error
    if (newPasswordFocused || confirmPasswordFocused) return false
    if (newPassword === "" && confirmPassword === "") return false
    if (newPassword === confirmPassword) return false
    return true
  }

  const confirmNewPassword = (password: string) => {
    setConfirmPassword(password)
    if (showPasswordMatchError()) {
      setConfirmPasswordError(true)
      setConfirmPasswordErrorMessage(translate("Passwords do not match!"))
    } else {
      setNewPasswordError(false)
      setConfirmPasswordError(false)
    }
  }

  const onNewPasswordChange = (password: string) => {
    setNewPassword(password)
    if (showPasswordMatchError()) {
      setNewPasswordError(true)
      setNewPasswordErrorMessage(translate("Passwords do not match!"))
    } else {
      setNewPasswordError(false)
      setConfirmPasswordError(false)
    }
  }

  const validForm = (): boolean => {
    // Result
    let valid: boolean = true

    // Check the old password
    if (oldPassword === "") {
      setOldPasswordError(true)
      setOldPasswordErrorMessage(translate("Please enter the old password!"))
      valid = false
    }

    // Check new password
    if (newPassword === "") {
      setNewPasswordError(true)
      setNewPasswordErrorMessage(translate("Please enter a new password!"))
      valid = false
    } else if (newPassword.length < 8) {
      setNewPasswordError(true)
      setNewPasswordErrorMessage(translate("Passwords must be at least 8 characters long"))
      valid = false
    }

    if (confirmPassword === "") {
      setConfirmPasswordError(true)
      setConfirmPasswordErrorMessage(translate("Please enter the new password for confirmation!"))
      valid = false
    }

    if (valid && newPassword !== confirmPassword) {
      setConfirmPasswordError(true)
      setConfirmPasswordErrorMessage(translate("Passwords do not match!"))
      valid = false
    }

    if (valid && oldPassword === newPassword) {
      setNewPasswordError(true)
      setNewPasswordErrorMessage(translate("New password must be different from the old password!"))
      valid = false
    }

    return valid
  }

  const submitPasswordChange = () => {
    // Validate form
    if (!validForm()) return

    // Make call to change the password
    dispatch(changePassword({
      vars: {
        oldPassword,
        newPassword,
      },
      onSuccess: () => {
        const toast: Toast = {
          type: "success",
          message: translate("Successfully changed password"),
        }
        dispatch(pushToast(toast))
        clearForm()
      },
      onError: () => {
        const toast: Toast = {
          type: "error",
          message: translate("Error saving password. Please try again later!"),
        }
        dispatch(pushToast(toast))
      },
      onPasswordInvalid() {
        setOldPasswordError(true)
        setOldPasswordErrorMessage(translate("Old password is incorrect!"))
      },
    }))
  }

  return (
    <div className="cp_component_change-pw">
      <section className="cp_component_change-pw_input">
        <div className="form-old-password">
          <Input
            id="settings-old-password"
            className="password-input"
            value={ oldPassword }
            onChange={ (e) => setOldPassword(e.currentTarget.value) }
            placeholder={ translate("Enter Old Password") }
            label={ translate("Old Password*") }
            type={ (showOldPassword) ? "text" : "password" }
            error={ oldPasswordError }
            helperText={ (oldPasswordError) ? oldPasswordErrorMessage : undefined }
            onBlur={ () => {
              if (oldPassword !== "") setOldPasswordError(false)
            } }
            InputProps={ {
              endAdornment: (
                <InputAdornment
                  className="pw-visibility-icon"
                  position="end"
                >
                  <IconButton
                    id="old-password-hide-show-pw-btn"
                    aria-label={ showOldPassword ? translate("hide the password") : translate("display the password") }
                    onClick={ () => setShowOldPassword(!showOldPassword) }
                    edge="end"
                  >
                    { showOldPassword ? <VisibilityOff /> : <Visibility /> }
                  </IconButton>
                </InputAdornment>
              ),
            } }
          />
        </div>
        <div className="form-new-password">
          <Input
            id="settings-new-password"
            className="password-input"
            value={ newPassword }
            onChange={ (e) => onNewPasswordChange(e.currentTarget.value) }
            placeholder={ translate("Enter New Password") }
            label={ translate("New Password*") }
            type={ (showNewPassword) ? "text" : "password" }
            error={ newPasswordError }
            helperText={ (newPasswordError) ? newPasswordErrorMessage : undefined }
            onFocus={ () => setNewPasswordFocused(true) }
            onBlur={ () => setNewPasswordFocused(false) }
            InputProps={ {
              endAdornment: (
                <InputAdornment
                  className="pw-visibility-icon"
                  position="end"
                >
                  <IconButton
                    id="new-password-hide-show-pw-btn"
                    aria-label={ showNewPassword ? translate("hide the password") : translate("display the password") }
                    onClick={ () => setShowNewPassword(!showNewPassword) }
                    edge="end"
                  >
                    { showNewPassword ? <VisibilityOff /> : <Visibility /> }
                  </IconButton>
                </InputAdornment>
              ),
            } }
          />
        </div>
        <div className="form-confirm-password">
          <Input
            id="settings-confirm-password"
            className="password-input"
            value={ confirmPassword }
            onChange={ (e) => confirmNewPassword(e.currentTarget.value) }
            placeholder={ translate("Confirm New Password") }
            label={ translate("Confirm New Password*") }
            type={ (showConfirmPassword) ? "text" : "password" }
            error={ confirmPasswordError }
            helperText={ (confirmPasswordError) ? confirmPasswordErrorMessage : undefined }
            onFocus={ () => setConfirmPasswordFocused(true) }
            onBlur={ () => setConfirmPasswordFocused(false) }
            InputProps={ {
              endAdornment: (
                <InputAdornment
                  className="pw-visibility-icon"
                  position="end"
                >
                  <IconButton
                    id="confirm-password-hide-show-pw-btn"
                    aria-label={ showConfirmPassword ? translate("hide the password") : translate("display the password") }
                    onClick={ () => setShowConfirmPassword(!showConfirmPassword) }
                    edge="end"
                  >
                    { showConfirmPassword ? <VisibilityOff /> : <Visibility /> }
                  </IconButton>
                </InputAdornment>
              ),
            } }
          />
        </div>
      </section>
      <section className="cp_component_change-pw_cta">
        <Button
          id="button-change-password"
          className="user-settings-save-password-btn"
          isEnabled={
            oldPassword !== "" && newPassword !== "" && confirmPassword !== ""
          }
          label={ translate("Save Changes") }
          onClick={ submitPasswordChange }
        />
      </section>
    </div>
  )
}
