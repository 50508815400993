import React, { JSX, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useDispatch } from "../../state/hooks"
import ToastController from "../../controller/Toast"

import "./style.sass"
import { fetchListGroup } from "../../state/listGroupSlice"
import ListGroupOverview from "../../component/ListGroupOverview"
import ListGroupManagement from "../../component/ListGroupManagement"
import PageShell from "../../component/PageShell"

export default function ListGroup(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.ListGroup" })
  const dispatch = useDispatch()
  const { listGroupID } = useParams()

  useEffect(
    () => {
      if (!listGroupID) return
      dispatch(fetchListGroup(listGroupID))
    },
    [ listGroupID, dispatch ],
  )

  return (
    <div className="cp_list-group_page">
      <ToastController />
      <PageShell title={ translate("Lists") } />
      <div className="cp_list-group_page-container">
        <section className="cp_list-group_page-overview">
          <ListGroupOverview />
        </section>
        <section className="cp_list-group_page-list-management">
          <ListGroupManagement />
        </section>
      </div>
    </div>
  )
}
