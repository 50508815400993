import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material"
import {
  Add,
  Remove,
  HelpOutline,
  ArrowForwardIos,
  DragIndicator,
} from "@mui/icons-material"
import { useParams } from "react-router-dom"

import Highlights from "./Visualizations/Highlights"
import HighlightsDisplay from "./Visualizations/HighlightsDisplay"
import ModalListAccountsDataPoint from "../ModalListAccountsDataPoint"
import Switch from "../Switch"
import { INSIGHTS_DISPLAY_TOGGLES } from "../../util/constant"
import Button from "../Button"
import { useDispatch, useSelector } from "../../state/hooks"
import { updateList } from "../../state/listConfigurationSlice"
import { pushToast } from "../../state/toastSlice"
import { isSuccess } from "../../util/apiClient"
import LoadingIndicatorCard from "../LoadingIndicatorCard"

import "./configuration-option-styles.sass"
import { fetchList } from "../../state/listSlice"

export default function ConfigurationVisualizations() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })
  const {
    t: translateConfigOptions,
  } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualizationsOptions" })
  const dispatch = useDispatch()
  const { listID } = useParams()

  const list = useSelector((state) => state.list.list)

  const [ expanded, setExpanded ] = React.useState(false)
  const [ open, setOpen ] = React.useState<boolean>(false)
  const [ acctInsightToggles, setAcctInsightToggles ] = useState<string[]>([])

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  const isToggleChecked = (toggleName: string): boolean => acctInsightToggles.includes(toggleName)
  const handleToggleUpdate = (toggleName: string): void => {
    if (acctInsightToggles.includes(toggleName)) {
      setAcctInsightToggles((prevToggles) => prevToggles.filter((t) => t !== toggleName))
    } else {
      setAcctInsightToggles((prevToggles) => [ ...prevToggles, toggleName ])
    }
  }

  const saveToggles = () => {
    // Make the call
    dispatch(updateList({
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Successfully saved toggles!"),
        }))
        dispatch(fetchList(listID || ""))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: "Unable to save toggles. Please try again later!",
        }))
      },
    }))
  }

  const renderHighlights = (): React.JSX.Element => {
    if (isSuccess(list)) {
      return (
        <>
          <Highlights />
          <HighlightsDisplay />
        </>
      )
    }

    return <LoadingIndicatorCard />
  }

  return (
    <div className="cp_list-configuration-visualizations cp_lcv">
      <div className="cp_lcv-top">
        <h2 className="cp_lcv-heading">{ translate("VISUALIZATIONS") }</h2>
        <IconButton
          id="open-accounts-data-point-modal-button"
          className="cp_lcv-help-icon"
          onClick={ () => setOpen(true) }
        >
          <HelpOutline fontSize="small" />
        </IconButton>
      </div>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion highlights"
        elevation={ 0 }
        id="cp_lcv-accordion_highlights"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary"
          expandIcon={ expanded ? <Remove className="cp_lcv-accordion-icon-md" /> : <Add className="cp_lcv-accordion-icon-md" /> }
          aria-controls="highlights-content"
          id="highlights-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Highlights") }</h3>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          { renderHighlights() }
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion account_insights"
        elevation={ 0 }
        id="cp_lcv-accordion_account_insights"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="account-insights-content"
          id="account-insights-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Account Insights") }</h3>
          <span id="acctInsigtsCounter" className="summary-counter">{ acctInsightToggles.length }</span>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details config-options">
          <div className="cp_lcv-configuration-options">
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME) }
              />
              <span className="config-option-label">{ translateConfigOptions("Engagement Rate over Time") }</span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Engagement by Post Type Organic vs Sponsored") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Engagement by Post Type Reel vs Non-Reel") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Engagement Heat Map") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Recent Media & AI Image Analysis") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Recent Media Stories") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Brands Mentions & Brands Worked With") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
            <p className="config-option">
              <Switch
                className="config-option-switch"
                isChecked={ isToggleChecked(INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS) }
                handleChange={ () => handleToggleUpdate(INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS) }
              />
              <span className="config-option-label">
                { translateConfigOptions("Personality Traits") }
              </span>
              <DragIndicator className="config-option-dragicon" />
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion audience"
        elevation={ 0 }
        id="cp_lcv-accordion_audience"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary  has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="audience-content"
          id="audience-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Audience") }</h3>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          <p>Audience</p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion content"
        elevation={ 0 }
        id="cp_lcv-accordion_content"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary  has-chevron-icon"
          expandIcon={ <ArrowForwardIos /> }
          aria-controls="content-content"
          id="content-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Content") }</h3>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          <p>Content</p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        onChange={ handleToggle }
        className="cp_lcv-accordion appendix last"
        elevation={ 0 }
        id="cp_lcv-accordion_appendix"
      >
        <AccordionSummary
          className="cp_lcv-accordion-summary"
          expandIcon={ expanded ? <Remove className="cp_lcv-accordion-icon-md" /> : <Add className="cp_lcv-accordion-icon-md" /> }
          aria-controls="appendix-content"
          id="appendix-header"
        >
          <h3 className="cp_component_accordion-heading">{ translate("Appendix") }</h3>
        </AccordionSummary>
        <AccordionDetails className="cp_lcv-accordion-details">
          <p>Appendix</p>
        </AccordionDetails>
      </Accordion>
      <div className="cp_lcv-save-toggles-button">
        <Button
          id="save-toggles-button"
          label={ translate("Save") }
          isPrimary={ true }
          fullWidth={ true }
          onClick={ saveToggles }
        />
      </div>
      <ModalListAccountsDataPoint open={ open } setOpen={ setOpen } />
    </div>
  )
}
