import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { Status } from "../../util/types"
import { RootState } from "../store"
import { highlightMetrics } from "../../component/ListConfiguration/constants"

export type Metric = {
  code: string
  name: string
}

export interface ListConfigurationState {
  highlightsMetrics: Metric[]
  audienceLocations: Status<GraphQL.SearchAudienceLocationsQuery>
  occupations: Status<GraphQL.SearchOccupationsForDemographicScoreQuery>
  affinities: Status<GraphQL.SearchAffinitiesForListQuery>
  updateListForm?: GraphQL.UpdateSuggestionListMutationVariables
}

const initialState: ListConfigurationState = {
  highlightsMetrics: [],
  audienceLocations: "init",
  occupations: "init",
  affinities: "init",
}

export const listConfigurationSlice = createSlice({
  name: "listConfigurationSlice",
  initialState,
  reducers: {
    setHighlightsMetrics: ((state, action: PayloadAction<Metric[]>) => ({
      ...state,
      highlightsMetrics: action.payload,
    })),
    setAudienceLocations: ((state, action: PayloadAction<Status<GraphQL.SearchAudienceLocationsQuery>>) => ({
      ...state,
      audienceLocations: action.payload,
    })),
    setOccupations: ((state, action: PayloadAction<Status<GraphQL.SearchOccupationsForDemographicScoreQuery>>) => ({
      ...state,
      occupations: action.payload,
    })),
    setAffinities: ((state, action: PayloadAction<Status<GraphQL.SearchAffinitiesForListQuery>>) => ({
      ...state,
      affinities: action.payload,
    })),
    setUpdateListForm: ((state, action: PayloadAction<GraphQL.UpdateSuggestionListMutationVariables>) => ({
      ...state,
      updateListForm: action.payload,
    })),
    resetState: () => initialState,
  },
})

export const {
  setHighlightsMetrics,
  setAudienceLocations,
  setOccupations,
  setAffinities,
  resetState,
  setUpdateListForm,
} = listConfigurationSlice.actions
export default listConfigurationSlice.reducer

export const populateState = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  // Grab list from list state
  const { list } = getState().list

  // Check if the list was successfully fetched
  if (API.isSuccess(list)) {
    // Grab the suggestion list
    const suggestionList = list.payload.suggestionListById

    // Create update list form
    const updateData: GraphQL.UpdateSuggestionListMutationVariables = {
      id: suggestionList.id,
      affinities: suggestionList.affinities.map((aff) => aff.code),
      avatarId: suggestionList.avatar?.id,
      categories: suggestionList.suggestionListCategories.map((category) => category.name),
      clientIds: suggestionList.clients.map((client) => client.id),
      contextualRelevancy: suggestionList.contextualRelevancy,
      description: suggestionList.description,
      keywords: suggestionList.keywords.map((keyword) => keyword.name),
      imageTags: suggestionList.imageTags.map((imageTag) => imageTag.name),
      maxAge: suggestionList.maxAge,
      minAge: suggestionList.minAge,
      name: suggestionList.name,
      network: suggestionList.network,
      isPublic: suggestionList.public,
      sexes: suggestionList.sexes,
      subscribedUserIds: suggestionList.subscribedUsers.map((user) => user.id),
      suggestionAccessCode: suggestionList.suggestionAccessCode,
      suggestionListMode: suggestionList.suggestionListMode,
      suggestionListVerticalIds: suggestionList.verticals.map((vertical) => vertical.name),
      toggles: suggestionList.toggles.map((toggle) => toggle.name),
      vetting: suggestionList.vetting,
      vettingSubscriptionUserIds: suggestionList.subscribedVettingUsers.map((user) => user.id),
      demographicScoreGender: suggestionList.demographicScoreGender,
      demographicScoreEthnicity: suggestionList.demographicScoreEthnicity,
      demographicScoreFamily: suggestionList.demographicScoreFamily,
      demographicScoreReligion: suggestionList.demographicScoreReligion,
      demographicScoreIncome: suggestionList.demographicScoreIncome,
      demographicScoreLocations: suggestionList.demographicScoreLocations.map((location) => location.code),
      demographicScoreOccupations: suggestionList.demographicScoreOccupations.map((occupation) => occupation.code),
      demographicScoreAffinities: suggestionList.demographicScoreAffinities.map((affinity) => affinity.code),
    }

    // Update highlights metrics
    const hMetrics: Metric[] = []
    if (Array.isArray(updateData.toggles)) {
      updateData.toggles.forEach((toggleCode) => {
        const toggle = highlightMetrics.flatMap((group) => group.metrics).find((metric) => metric.code === toggleCode)
        if (toggle) hMetrics.push(toggle)
      })
    } else {
      const toggle = highlightMetrics.flatMap((group) => group.metrics).find((metric) => metric.code === updateData.toggles)
      if (toggle) hMetrics.push(toggle)
    }

    // Set the state
    dispatch(setUpdateListForm(updateData))
    dispatch(setHighlightsMetrics(hMetrics))
  }
}

export const fetchAudienceLocations = (
  startsWith: string,
) => async (dispatch: Dispatch) => {
  // Set state to loading
  dispatch(setAudienceLocations("loading"))

  // Run the query
  const results = await API.fetchAudienceLocations(startsWith)

  // Set the results
  dispatch(setAudienceLocations(results))
}

export const fetchOccupations = (
  startsWith: string,
) => async (dispatch: Dispatch) => {
  // Set state to loading
  dispatch(setOccupations("loading"))

  // Run the query
  const results = await API.fetchOccupationsForDemographicScore(startsWith)

  // Set the results
  dispatch(setOccupations(results))
}

export const fetchAffinities = (
  startsWith: string,
) => async (dispatch: Dispatch) => {
  // Set state to loading
  dispatch(setAffinities("loading"))

  // Run the query
  const results = await API.fetchAffinitiesForList(startsWith)

  // Set the results
  dispatch(setAffinities(results))
}

export const updateList = (params: {
  onSuccess: () => void,
  onError: () => void,
}) => async (dispatch: Dispatch, getState: () => RootState) => {
  // Pull in the list
  const { updateListForm } = getState().listConfiguration

  if (updateListForm) {
    // Call API to update list
    const result = await API.updateList(updateListForm)

    if (API.isSuccess(result)) params.onSuccess()
    if (API.isError(result)) params.onError()
  }
}
