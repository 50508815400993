import * as React from "react"
import { useTranslation } from "react-i18next"
import { DarkMode, LightMode } from "@mui/icons-material"
import SettingsIcon from "@mui/icons-material/Settings"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import LogoutIcon from "@mui/icons-material/Logout"
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

import "./style.sass"
import Switch from "../Switch"
import Avatar from "../Avatar"
import Dropdown from "../Dropdown"
import { Theme, Scope } from "../../util/types"
import { toggleThemeMode } from "../../state/themeModeSlice"
import { useDispatch, useSelector } from "../../state/hooks"

export interface AccountDropdownProps {
  user: {
    userName: string
    companyName: string
    profileImageUrl?: string
  },
  theme: Theme,
  logout: () => Promise<void>
  onSettingsClick: () => void
  onSupportClick: () => void
}

function DarkModeIcon() {
  return (
    <span className="cp-switch_dark-icon">
      <DarkMode />
    </span>
  )
}

function LightModeIcon() {
  return (
    <span className="cp-switch_light-icon">
      <LightMode />
    </span>
  )
}

export default function AccountDropdown({
  user,
  theme,
  logout,
  onSettingsClick,
  onSupportClick,
}: AccountDropdownProps) {
  const { scopes } = useSelector(({ user: usr }) => usr)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AccountDropdown" })
  const dispatch = useDispatch()

  const switchTheme = () => {
    dispatch(toggleThemeMode())
  }

  return (
    <Dropdown
      avatarUrl={ user.profileImageUrl }
      userName={ user.userName }
      buttonType="avatar"
    >
      <List disablePadding={ true } className="list-account">
        <ListItem
          disablePadding={ true }
          disableGutters={ true }
        >
          <ListItemButton disableRipple={ true }>
            <Avatar
              className="list-item-avatar"
              sizes="small"
              src={ user.profileImageUrl }
            />
            <ListItemText primary={ user.userName } />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding={ true }
          disableGutters={ true }
        >
          <ListItemButton disableRipple={ true }>
            <ListItemText primary={ user.companyName } />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding={ true }
          disableGutters={ true }
        >
          <ListItemButton
            disableRipple={ true }
            className="list-item-switch"
          >
            <Switch
              hasIcon={ true }
              checkedIcon={ <LightModeIcon /> }
              icon={ <DarkModeIcon /> }
              isChecked={ theme === "light" }
              handleChange={ switchTheme }
            />
            <ListItemText primary={ theme } />
          </ListItemButton>
        </ListItem>
        <Divider />
        { scopes.includes(Scope.FEATURE_USER) && scopes.includes(Scope.DEVELOPMENT) && (
          <ListItem
            disablePadding={ true }
            disableGutters={ true }
          >
            <ListItemButton onClick={ onSettingsClick }>
              <ListItemIcon>
                <SettingsIcon className="list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Settings") } />
            </ListItemButton>
          </ListItem>
        ) }
        <ListItem
          disablePadding={ true }
          disableGutters={ true }
        >
          <ListItemButton onClick={ onSupportClick }>
            <ListItemIcon>
              <HelpOutlineIcon className="list-item-icon" />
            </ListItemIcon>
            <ListItemText primary={ translate("Platform Support") } />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem
          disablePadding={ true }
          disableGutters={ true }
        >
          <ListItemButton onClick={ logout }>
            <ListItemIcon>
              <LogoutIcon className="list-item-icon" />
            </ListItemIcon>
            <ListItemText primary={ translate("Logout") } />
          </ListItemButton>
        </ListItem>
      </List>
    </Dropdown>
  )
}
