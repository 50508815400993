import React, { useMemo, useState } from "react"
import { ButtonBase } from "@mui/material"
import * as GraphQL from "../../../../graphql"
import { getSocialAvatarProps } from "../../../../util/componentHelper"

import "./recent-post.sass"
import ModalPostDetails from "../../../ModalPostDetails"

type RecentPostProps = GraphQL.SocialAccount["recentStories"][number]
interface RecentPostsProps {
  socialAccount: GraphQL.SocialAccount
}

export default function RecentPosts({ socialAccount }: RecentPostsProps) {
  const [ selectedPost, setSelectedPost ] = useState<undefined | RecentPostProps>()

  const recentStories = useMemo(() => socialAccount.recentStories, [ socialAccount ])

  if (recentStories.length === 0) { return null }

  return (
    <div className="cp_recent-posts_component">
      { recentStories.map((post) => (
        <ButtonBase
          key={ post.id }
          className="cp_recent-posts_component-trigger"
          onClick={ () => setSelectedPost(post as RecentPostProps) }
        >
          <img
            src={ post.postMedia[0].thumbnailUrl }
            alt={ post.postContent }
            className="cp_recent-posts_component-media"
          />
        </ButtonBase>
      )) }
      { socialAccount && (
      <ModalPostDetails
        closeAction={ () => setSelectedPost(undefined) }
        engagementRate={ `${ selectedPost?.engagementRate }` || "" }
        isReel={ Boolean(selectedPost?.isReel) }
        open={ Boolean(selectedPost) }
        isStory={ Boolean(selectedPost?.isStory) }
        media={ selectedPost?.postMedia || [] }
        permalink={ selectedPost?.permalink || "" }
        postComments={ selectedPost?.postComments || 0 }
        postContent={ selectedPost?.postContent || "" }
        postDate={ selectedPost?.created || 0 }
        postExits={ selectedPost?.postExits || 0 }
        postImpressions={ selectedPost?.postImpressions || 0 }
        postLikes={ selectedPost?.postLikes || 0 }
        postPlays={ selectedPost?.postPlays || 0 }
        postReach={ selectedPost?.postReach || 0 }
        postReachEstimated={ selectedPost?.estimatedPostReach?.value || 0 }
        postSaves={ selectedPost?.postSaves || 0 }
        postShares={ selectedPost?.postShares || 0 }
        postScreenshots={ selectedPost?.screenshots || 0 }
        postSwipeUps={ selectedPost?.swipeUps || 0 }
        postHighlighted={ Boolean(selectedPost?.highlighted) }
        postSaved={ Boolean(selectedPost?.saved) }
        postTapsBackward={ selectedPost?.postTapsBackward || 0 }
        postTapsForward={ selectedPost?.postTapsForward || 0 }
        postViews={ selectedPost?.postViews || 0 }
        socialAvatarProps={ getSocialAvatarProps(socialAccount as GraphQL.SocialAccount) }
      />
      ) }
    </div>
  )
}
