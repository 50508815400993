import React from "react"
import EngagementRateOverTimeIcon from "../../../asset/visualizations/LIST_ENGAGEMENT_RATE_EX.svg"
import EngagementByPostTypeOSIcon from "../../../asset/visualizations/LIST_ENG_POST_TYPE_EX.svg"
import EngagementByPostTypeRNIcon from "../../../asset/visualizations/LIST_ENG_POST_TYPE_REEL_EX.svg"
import EngagementHeatMapIcon from "../../../asset/visualizations/LIST_ENG_HEATMAP_EX.svg"
import RecentMediaAiAnalysisIcon from "../../../asset/visualizations/LIST_RECENT_MEDIA_AI_EX.svg"
import BrandsMentionedIcon from "../../../asset/visualizations/LIST_BRANDS_MENTIONED_EX.svg"
import RecentMedialStoriesIcon from "../../../asset/visualizations/LIST_RECENT_STORIES_EX.svg"
import PerconalityTraitsIcon from "../../../asset/visualizations/LIST_PERSONALITY_TRAITS_EX.svg"

export default function AccountInsightsVisualizations() {
  return (
    <div className="cp_lcp-preview_example-visualizations">
      <img src={ EngagementRateOverTimeIcon } alt="Engagement Rate Over Time" />
      <img src={ EngagementByPostTypeOSIcon } alt="Engagement by Post Type: Organic vs Sponsored" />
      <img src={ EngagementByPostTypeRNIcon } alt="Engagement by Post Type: Reel vs Non-Reel" />
      <img src={ EngagementHeatMapIcon } alt="Engagement Heat Map" />
      { /* eslint-disable-next-line jsx-a11y/img-redundant-alt */ }
      <img src={ RecentMediaAiAnalysisIcon } alt="Recent media and AI image analysis" />
      <img src={ BrandsMentionedIcon } alt="Brands mentioned and brands worked with" />
      <img src={ RecentMedialStoriesIcon } alt="Recent media stories" />
      <img src={ PerconalityTraitsIcon } alt="Personality traits" />
    </div>
  )
}
