import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  FormControlLabel,
  InputAdornment,
  RadioGroup,
} from "@mui/material"
import { Close, Search } from "@mui/icons-material"

import Input, { Props as InputProps } from "../../../../../Input"
import { useDispatch, useSelector } from "../../../../../../state/hooks"
import {
  fetchBrandManagersForManageModal,
  setManageModalBrandManagersOpen,
  updateBrandManagers,
} from "../../../../../../state/campaignSlice"
import LoadingIndicatorCard from "../../../../../LoadingIndicatorCard"
import ErrorHandler from "../../../../../ErrorHandler"
import EntityInfoRow from "../../../../../EntityInfoRow"
import { isError, isSuccess } from "../../../../../../util/apiClient"
import Select from "../../../../../Select"
import Modal from "../../../../../Modal"
import Autocomplete from "../../../../../Autocomplete"
import MenuItem from "../../../../../MenuItem"
import {
  CampaignPermissionType,
  CustomerFragment,
  UserProfileManagementFragment,
} from "../../../../../../graphql"
import Radio from "../../../../../Radio"

import "./modal-manage-brand-managers.sass"
import IconButton from "../../../../../IconButton"
import LoadingIndicator from "../../../../../LoadingIndicator"

type UserPermissionInputFormat = {
  // Used for mutation input
  userId: string
  permissions: Array<CampaignPermissionType>

  // Used for display purposes
  username: string
  name: string
  avatarSrc: string
}

export default function ModalManageBrandManagers() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalManageBrandManagers" })
  const {
    campaign,
    manageModalBrandManagersOpen: modalOpen,
    manageModalBrandManagers,
  } = useSelector(({ campaignPage }) => campaignPage)
  const [ submitLoading, setSubmitLoading ] = useState(false)

  const [ clients, setClients ] = useState<Array<CustomerFragment>>([])
  const [ selectedClientId, setSelectedClientId ] = useState<string>()

  const [ activeUserPermissions, setActiveUserPermissions ] = useState<UserPermissionInputFormat[]>([])
  const [ autoCompleteOptions, setAutoCompleteOptions ] = useState<Array<UserProfileManagementFragment>>([])
  const [ selectedUserPermissionIdx, setSelectedUserPermissionIdx ] = useState<number>()
  const [ userSearchInput, setUserSearchInput ] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    if (modalOpen && isSuccess(campaign)) {
      const {
        id: campaignId,
        customers,
        userPermissions,
      } = campaign.payload.campaign

      // Load state
      setSelectedUserPermissionIdx(undefined)
      setActiveUserPermissions([])
      setClients([])
      if (customers.length > 0) {
        setClients(customers)
        setSelectedClientId(customers[0].id)
        dispatch(fetchBrandManagersForManageModal("", campaignId, customers[0].id))
      }
      if (userPermissions.length > 0) {
        const formattedPermissions = userPermissions.map(({ user, permissions }) => ({
          avatarSrc: user.contact.avatar.url.address,
          name: user.contact.name,
          permissions,
          userId: user.id,
          username: user.username,
        }))
        setActiveUserPermissions(formattedPermissions)
        setSelectedUserPermissionIdx(0)
      }
    }
  }, [ modalOpen, campaign ])

  useEffect(() => {
    if (isSuccess(manageModalBrandManagers)) {
      setAutoCompleteOptions(manageModalBrandManagers.payload.searchCampaignUsers.rows)
    }
  }, [ manageModalBrandManagers ])

  // NOTE: Temporarily removing this until logic is finalized
  // For now, changed query to just query all available users, so no need for multiple quries.
  // Autocomplete also has built in search filtering so no need to filter via query
  // const debouncedSearchUsers = useCallback(debounce((e, inputValue, clientId) => {
  // if (isSuccess(campaign) && clientId) {
  //   const {
  //     id: campaignId,
  //   } = campaign.payload.campaign
  //   dispatch(fetchBrandManagersForManageModal(inputValue, campaignId, clientId))
  // }
  // }, DEFAULT_DEBOUNCE_WAIT), [ ])

  const handleAutocompleteSelect = (
    user: string
    | UserProfileManagementFragment
    | (string | UserProfileManagementFragment)[]
    | null,
  ): void => {
    if (
      user == null
      || typeof user === "string"
      || Array.isArray(user)
    ) {
      return
    }
    if (!activeUserPermissions.some((userPermission) => userPermission.userId === user.id)) {
      const newUserPermission = {
        avatarSrc: user.contact.avatar.url.address,
        name: user.contact.name,
        userId: user.id,
        permissions: [],
        username: user.username,
      }
      setUserSearchInput("")
      setSelectedUserPermissionIdx(0)
      setActiveUserPermissions([ newUserPermission, ...activeUserPermissions ])
    }
  }

  const handleClientSelect = (newClientId: string) => {
    setUserSearchInput("")
    setSelectedClientId(newClientId)

    if (isSuccess(campaign)) {
      const {
        id: campaignId,
      } = campaign.payload.campaign
      dispatch(fetchBrandManagersForManageModal("", campaignId, newClientId))
    }
  }

  const modifySelectedPermission = (
    option: string,
  ) => {
    if (selectedUserPermissionIdx !== undefined) {
      const newPermissions = [ ...activeUserPermissions ]
      switch (option) {
        case "NONE":
          newPermissions[selectedUserPermissionIdx].permissions = []
          break
        case CampaignPermissionType.View.toString():
          newPermissions[selectedUserPermissionIdx].permissions = [ CampaignPermissionType.View ]
          break
        case CampaignPermissionType.Approve.toString():
          newPermissions[selectedUserPermissionIdx].permissions = [ CampaignPermissionType.View, CampaignPermissionType.Approve ]
          break
        default:
          break
      }
      setActiveUserPermissions(newPermissions)
    }
  }

  const removeFromActivePersmissions = (
    idx: number,
  ) => {
    const newPermissions = [ ...activeUserPermissions ]
    newPermissions.splice(idx, 1)
    setSelectedUserPermissionIdx(undefined)
    setActiveUserPermissions(newPermissions)
  }

  const onSubmit = async () => {
    setSubmitLoading(true)
    await dispatch(updateBrandManagers(activeUserPermissions.map(({ permissions, userId }) => ({ permissions, userId }))))
    dispatch(setManageModalBrandManagersOpen(false))
    setSubmitLoading(false)
  }

  /**
   * filterAutoSelectOptions: Filters the auto complete options based on the input value
   * @param options The auto complete options
   * @param inputValue The auto complete input value
   * @returns An array of options that match the input value
   */
  const filterAutoSelectOptions = (options: Array<UserProfileManagementFragment>, inputValue: string) => (
    options.filter((option) => {
      const name = option.contact.name.toLowerCase()
      const username = option.username.toLowerCase()
      const id = option.id.toLowerCase()
      const input = inputValue.toLowerCase()
      return name.includes(input) || username.includes(input) || id.includes(input)
    })
  )

  const renderContent = () => {
    if (campaign === "init" || campaign === "loading") {
      return <LoadingIndicatorCard />
    }

    if (isError(manageModalBrandManagers) || isError(campaign)) {
      if (isError(manageModalBrandManagers)) {
        return <ErrorHandler err={ manageModalBrandManagers } />
      }

      if (isError(campaign)) {
        return <ErrorHandler err={ campaign } />
      }
    }

    return (
      <div className="modal-body">
        <div className="search-container">
          <p className="label_small-caps-bold">{ translate("Client") }</p>
          <Select
            id="modal-manage-brand-managers-select"
            className="client-select"
            label=""
            labelId="modal-manage-brand-managers-label"
            fullWidth={ true }
            value={ selectedClientId || "" }
            onChange={ (e) => handleClientSelect(e.target.value) }
            menuItems={ clients.map((client) => <MenuItem key={ client.id } value={ client.id }>{ client.vanity }</MenuItem>) }
          />
          <Autocomplete
            key={ `user-permissions-${ activeUserPermissions.length }` }
            className="cp_modal-manage-brand-managers-auto-complete"
            filterSelectedOptions={ true }
            onChange={ (_, newValue) => handleAutocompleteSelect(newValue) }
            options={ autoCompleteOptions }
            filterOptions={ (options, params) => filterAutoSelectOptions(options, params.inputValue) }
            renderOption={ (props, option) => (
              <li { ...props }>
                <EntityInfoRow
                  className="auto-complete-row"
                  name={ option.contact.name }
                  avatarSrc={ option.contact.avatar.url.address }
                />
              </li>
            ) }
            getOptionLabel={ (o) => typeof o === "string" ? o : o.username }
            multiple={ false }
            renderTags={ () => null }
            // NOTE: Temporarily removing this until logic is finalized
            // For now, changed query to just query all available users, so no need for multiple quries.
            // Autocomplete also has built in search filtering so no need to filter via query
            // onInputChange={ (e, inputValue) => debouncedSearchUsers(e, inputValue, selectedClientId) }
            renderInput={ (params) => (
              <Input
                { ...params as InputProps }
                className="auto-complete-input"
                value={ userSearchInput }
                onChange={ (e) => setUserSearchInput(e.target.value) }
                label={ translate("User") }
                InputProps={ {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search className="cp_component_search-bar-search-icon" />
                    </InputAdornment>
                  ),
                } }
              />
            ) }
            clearOnBlur={ false }
            selectOnFocus={ false }
            id="autocomplete-users"
            openOnFocus={ true }
            handleHomeEndKeys={ true }
            isOptionEqualToValue={ (option, value) => option.id === value.id }
          />
          <div className="active-permissions-container">
            { activeUserPermissions.map((row, idx) => {
              const classNames = [
                "permission-row",
                selectedUserPermissionIdx === idx ? "permission-row-selected" : "",
              ].join(" ")
              return (
                <div className={ classNames }>
                  <EntityInfoRow
                    onClick={ () => setSelectedUserPermissionIdx(idx) }
                    key={ row.userId }
                    name={ `${ row.name } (${ row.username })` }
                    avatarSrc={ row.avatarSrc }
                  />
                  <IconButton onClick={ () => removeFromActivePersmissions(idx) }>
                    <Close />
                  </IconButton>
                </div>
              )
            }) }
          </div>
        </div>
        <div className="permissions-container">
          <p className="label_small-caps-bold">{ translate("Permissions") }</p>
          { selectedUserPermissionIdx !== undefined && (
          <RadioGroup className="cp_radio-group">
            <FormControlLabel
              className="cp_radio-label"
              onClick={ () => modifySelectedPermission(CampaignPermissionType.Approve) }
              value={ CampaignPermissionType.Approve }
              control={ <Radio /> }
              label={ translate("Approve") }
              checked={ activeUserPermissions[selectedUserPermissionIdx].permissions.includes(CampaignPermissionType.View)
                && activeUserPermissions[selectedUserPermissionIdx].permissions.includes(CampaignPermissionType.Approve) }
            />
            <FormControlLabel
              className="cp_radio-label"
              onClick={ () => modifySelectedPermission(CampaignPermissionType.View) }
              value={ CampaignPermissionType.View }
              control={ <Radio /> }
              label={ translate("View") }
              checked={ activeUserPermissions[selectedUserPermissionIdx].permissions.includes(CampaignPermissionType.View)
                    && !activeUserPermissions[selectedUserPermissionIdx].permissions.includes(CampaignPermissionType.Approve) }
            />
            <FormControlLabel
              onClick={ () => modifySelectedPermission("NONE") }
              className="cp_radio-label"
              value="NONE"
              control={ <Radio /> }
              label={ translate("None") }
              checked={ activeUserPermissions[selectedUserPermissionIdx].permissions.length === 0 }
            />
          </RadioGroup>
          )
        }
        </div>
      </div>
    )
  }

  return (
    <Modal
      maxWidth="lg"
      className="cp_component_modal-manager-brand-managers"
      title={ translate("Update Brand Managers") }
      subtitle={ translate("Set user permissions for the campaign.") }
      open={ modalOpen }
      closeAction={ () => dispatch(setManageModalBrandManagersOpen(false)) }
      secondaryAction={ () => dispatch(setManageModalBrandManagersOpen(false)) }
      primaryAction={ onSubmit }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Save") }
      secondaryLabel={ translate("Cancel") }
    >
      { renderContent() }
    </Modal>
  )
}
