import React from "react"
import { useTranslation } from "react-i18next"

import { Typography } from "@mui/material"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import { closeModalSearchAISummary } from "../../state/ModalSearchAISummary"

export default function ModalSummary() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalSummary" })
  const dispatch = useDispatch()
  const {
    open,
    summary,
  } = useSelector((state) => state.ModalSearchAISummary)
  return (
    <Modal
      title={ translate("Summary") }
      open={ open }
      closeAction={ () => dispatch(closeModalSearchAISummary()) }
      hasFooter={ false }
    >
      <Typography>
        { summary }
      </Typography>
    </Modal>
  )
}
