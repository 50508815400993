import React from "react"
import "./config-preview-hero-banner.sass"
import { useTranslation } from "react-i18next"
import { Stack } from "@mui/material"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as Constants from "../../util/constant"
import SocialAvatar from "../SocialAvatar"
import { useSelector } from "../../state/hooks"
import Carousel, { CardData } from "../Carousel"

export default function ConfigurationPreviewHeroBanner() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })
  const { list: fetchedList } = useSelector(({ list }) => list)

  const [ listNetwork, setListNetwork ] = React.useState<GraphQL.Network>(GraphQL.Network.Facebook)

  React.useEffect(() => {
    // Determine the network
    if (API.isSuccess(fetchedList)) {
      // Set the network
      setListNetwork(fetchedList.payload.suggestionListById.network)
    }
  }, [ fetchedList ])

  const cardData = (): CardData[] => {
    const allCards = [ {
      card: createCardElement("Age", "0", 100),
      name: "Age",
      width: 100,
    }, {
      card: createCardElement("Gender", "0", 120),
      name: "Gender",
      width: 120,
    }, {
      card: createCardElement("Location", "0", 145),
      name: "Location",
      width: 145,
    }, {
      card: createCardElement("Engagement Score", "0", 225),
      name: "Engagement Score",
      width: 225,
    }, {
      card: createCardElement("Engagement Rate", "0", 215),
      name: "Engagement Rate",
      width: 215,
    }, {
      card: createCardElement("TTCM Engagement Rate", "0", 275),
      name: "TTCM Engagement Rate",
      width: 275,
    }, {
      card: createCardElement("Age/Gender In-Demo Percent", "0", 300),
      name: "Age/Gender In-Demo Percent",
      width: 300,
    }, {
      card: createCardElement("Demographic Score", "0", 215),
      name: "Demographic Score",
      width: 215,
    }, {
      card: createCardElement("Ad Council Score", "0", 215),
      name: "Ad Council Score",
      width: 215,
    }, {
      card: createCardElement("I-Score", "0", 120),
      name: "I-Score",
      width: 120,
    } ]

    return (listNetwork === GraphQL.Network.Tiktok)
      ? allCards
      : allCards.filter((card) => !card.name.startsWith("TTCM"))
  }

  const createCardElement = (name: string, value: string, width: number): React.JSX.Element => (
    <Stack
      spacing={ 1 }
      className={ `carousel-card-${ width }` }
      direction="row"
      alignContent="center"
      justifyContent="center"
      height="100%"
    >
      { (name === "Gender")
        ? (
          <>
            <p className="body_large">{ value }</p>
            <p className="label_large-caps-bold">{ `${ translate("M") } | ` }</p>
            <p className="body_large">{ value }</p>
            <p className="label_large-caps-bold">{ translate("F") }</p>
          </>
        ) : (
          <>
            <p className="body_large">{ value }</p>
            <p className="label_large-caps-bold">{ translate(name) }</p>
          </>
        )
      }
    </Stack>
  )

  return (
    <div className="cp_component_cfg-prev_hero-banner">
      <SocialAvatar
        followers={ 0 }
        username="username"
        network={ listNetwork }
        fullName=""
        isBlacklisted={ false }
        isNSFW={ false }
        isPlaceholder={ false }
        isUnsubscribed={ false }
        profilePictureUrl={ Constants.DEFAULT_PROFILE_URL }
      />
      <Carousel
        id="hero-banner-carousel"
        className="cp_component_cfg-prev_hero-banner_carousel"
        cards={ cardData() }
      />
    </div>
  )
}
