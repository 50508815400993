/* eslint-disable no-shadow */
import {
  INSIGHTS_DISPLAY_TOGGLES,
  LIST_DISPLAY_TOGGLES,
  PUBLIC_DETAILS_DISPLAY_TOGGLES,
  AUDIENCE_DISPLAY_TOGGLES,
} from "../../util/constant"
import { Metric } from "../../state/listConfigurationSlice"

import * as GraphQL from "../../graphql"

// ############## ENUMS ####################

// #############  TYPES #####################
export type DemoScoreFormValueCompare =
  GraphQL.Sex[]
  | GraphQL.Ethnicity[]
  | GraphQL.Family[]
  | GraphQL.Religion[]
  | GraphQL.IncomeBrackets[]
  | GraphQL.AudienceLocation[]
  | Occupation[]
  | Affinity[]
  | { from: number, to: number }

export type Occupation = { __typename?: "Tag", id: string, name: string, code: string }

export type Affinity = { __typename?: "Tag", id: string, name: string, code: string }

export type GroupMetrics = {
  groupName: string
  metrics: Metric[]
}

export type DemographicScoreForm = {
  sexes: GraphQL.Sex[]
  age: {
    from: number,
    to: number,
  },
  ethnicity: GraphQL.Ethnicity[]
  family: GraphQL.Family[]
  religion: GraphQL.Religion[]
  income: GraphQL.IncomeBrackets[]
  locations: GraphQL.AudienceLocation[]
  occupations: Occupation[]
  affinities: Affinity[]
}

// ################ CONSTANTS ###############
export const highlightMetrics: GroupMetrics[] = [ {
  groupName: "Metrics",
  metrics: [],
}, {
  groupName: "Public List Account Details",
  metrics: [
    {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.AGE,
      name: "Age",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.GENDER,
      name: "Gender",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.LOCATION,
      name: "Location",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.TAGS,
      name: "Tags",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.BIO,
      name: "Bio",
    }, {
      code: PUBLIC_DETAILS_DISPLAY_TOGGLES.RATIONALE,
      name: "Rationale",
    },
  ],
}, {
  groupName: "Scores & Engagement Rate",
  metrics: [ {
    code: LIST_DISPLAY_TOGGLES.ENG_SCORE,
    name: "Engagement Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.ENG_RATE,
    name: "Engagement Rate (90 days)",
  }, {
    code: LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE,
    name: "TTCM Engagement Rate",
  }, {
    code: LIST_DISPLAY_TOGGLES.AGE_GENDER_IN_DEMO,
    name: "Age/Gender In Demo",
  }, {
    code: LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
    name: "Demographic Score*",
  }, {
    code: LIST_DISPLAY_TOGGLES.AUDIENCE_AUTHENTICITY,
    name: "Audience Authenticity Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
    name: "Ad Council Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.ISCORE,
    name: "I-Score",
  }, {
    code: LIST_DISPLAY_TOGGLES.NO_SCORE,
    name: "None",
  } ],
}, {
  groupName: "Account Insights",
  metrics: [ {
    code: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME,
    name: "Engagement Rate over Time",
  }, {
    code: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE,
    name: "Engagement by Post Type Organic vs Sponsored",
  }, {
    code: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL,
    name: "Engagement by Post Type Reel vs Non-Reel",
  }, {
    code: INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP,
    name: "Engagement Heat Map",
  }, {
    code: INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS,
    name: "Recent Media & AI Image Analysis",
  }, {
    code: INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES,
    name: "Recent Media Stories",
  }, {
    code: INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH,
    name: "Brands Mentions & Brands Worked With",
  }, {
    code: INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS,
    name: "Personality Traits",
  } ],
}, {
  groupName: "Audience Metrics",
  metrics: [],
}, {
  groupName: "Content",
  metrics: [],
} ]

export const initialDemographicScoreForm: DemographicScoreForm = {
  sexes: [],
  age: {
    from: 0,
    to: 0,
  },
  ethnicity: [],
  family: [],
  religion: [],
  income: [],
  locations: [],
  occupations: [],
  affinities: [],
}

export const ReadableEthnicity = {
  [GraphQL.Ethnicity.AfricanAmerican]: "African American",
  [GraphQL.Ethnicity.AsianPacificIslander]: "Asian / Pacific Islander",
  [GraphQL.Ethnicity.HispanicLatino]: "Hispanic / Latino",
  [GraphQL.Ethnicity.WhiteCaucasian]: "White / Caucasian",
}

export const ReadableFamily = {
  [GraphQL.Family.Married]: "Married",
  [GraphQL.Family.Parent]: "Parent",
  [GraphQL.Family.Single]: "Single",
}

export const ReadableReligion = {
  [GraphQL.Religion.Christian]: "Christian",
  [GraphQL.Religion.Jewish]: "Jewish",
  [GraphQL.Religion.Muslim]: "Muslim",
}

export const ReadableIncome = {
  [GraphQL.IncomeBrackets["100000Above"]]: "$100,000+",
  [GraphQL.IncomeBrackets["10000_19999"]]: "$10,000 - $19,999",
  [GraphQL.IncomeBrackets["20000_29999"]]: "$20,000 - $29,999",
  [GraphQL.IncomeBrackets["30000_39999"]]: "$30,000 - $39,999",
  [GraphQL.IncomeBrackets["40000_49999"]]: "$40,000 - $49,999",
  [GraphQL.IncomeBrackets["50000_74999"]]: "$50,000 - $74,999",
  [GraphQL.IncomeBrackets["75000_100000"]]: "$75,000 - $99,999",
  [GraphQL.IncomeBrackets.Under_10000]: "$0 - $9,999",
}

export const engagementToggles = [
  LIST_DISPLAY_TOGGLES.ENG_SCORE,
  LIST_DISPLAY_TOGGLES.ENG_RATE,
  LIST_DISPLAY_TOGGLES.AUDIENCE_AUTHENTICITY,
  LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE,
  LIST_DISPLAY_TOGGLES.ISCORE,
  LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE,
  LIST_DISPLAY_TOGGLES.NO_SCORE,
  LIST_DISPLAY_TOGGLES.TTCM_ENGAGEMENT_RATE,
  AUDIENCE_DISPLAY_TOGGLES.AUTHENTICITY,
]
