import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormLabel,
  SelectChangeEvent,
} from "@mui/material"
import { KeyboardArrowUp, KeyboardArrowRight } from "@mui/icons-material"
import { useParams } from "react-router-dom"

import * as misc from "../../../../util/miscHelper"
import { useDispatch, useSelector } from "../../../../state/hooks"
import Select from "../../../Select"
import MenuItem from "../../../MenuItem"
import Button from "../../../Button"
import { setUpdateListForm, updateList } from "../../../../state/listConfigurationSlice"
import { isSuccess } from "../../../../util/apiClient"
import { pushToast } from "../../../../state/toastSlice"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import { fetchList } from "../../../../state/listSlice"

interface RelevancyFormProps {
  heading: string
}

export default function ContextualRelevancyForm({
  heading,
}: RelevancyFormProps) {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationVisualHighlights" })
  const { t: CROTranslations } = useTranslation("common.ContextualRelevancy")
  const dispatch = useDispatch()
  const contextualRelevancyOptions = React.useMemo(() => [
    { name: CROTranslations("Art and Entertainment"), id: "cont_rel_art_and_entertainment" },
    { name: CROTranslations("Automotive and Vehicles"), id: "cont_rel_automotive_and_vehicles" },
    { name: CROTranslations("Business and Industrial"), id: "cont_rel_business_and_industrial" },
    { name: CROTranslations("Careers"), id: "cont_rel_careers" },
    { name: CROTranslations("Education"), id: "cont_rel_education" },
    { name: CROTranslations("Family and Parenting"), id: "cont_rel_family_and_parenting" },
    { name: CROTranslations("Finance"), id: "cont_rel_finance" },
    { name: CROTranslations("Food and Drink"), id: "cont_rel_food_and_drink" },
    { name: CROTranslations("Health and Fitness"), id: "cont_rel_health_and_fitness" },
    { name: CROTranslations("Hobbies and Interests"), id: "cont_rel_hobbies_and_interests" },
    { name: CROTranslations("Home and Garden"), id: "cont_rel_home_and_garden" },
    { name: CROTranslations("Government and Politics"), id: "cont_rel_law,_govt_and_politics" },
    { name: CROTranslations("News"), id: "cont_rel_news" },
    { name: CROTranslations("Pets"), id: "cont_rel_pets" },
    { name: CROTranslations("Real Estate"), id: "cont_rel_real_estate" },
    { name: CROTranslations("Religion and Spirituality"), id: "cont_rel_religion_and_spirituality" },
    { name: CROTranslations("Science"), id: "cont_rel_science" },
    { name: CROTranslations("Shopping"), id: "cont_rel_shopping" },
    { name: CROTranslations("Society"), id: "cont_rel_society" },
    { name: CROTranslations("Sports"), id: "cont_rel_sports" },
    { name: CROTranslations("Style and Fashion"), id: "cont_rel_style_and_fashion" },
    { name: CROTranslations("Technology and Computing"), id: "cont_rel_technology_and_computing" },
    { name: CROTranslations("Travel"), id: "cont_rel_travel" },
  ], [ ])
  const { listID } = useParams()

  const { updateListForm } = useSelector((state) => state.listConfiguration)
  const list = useSelector((state) => state.list.list)

  const [ expanded, setExpanded ] = React.useState(true)
  const [ saved, setSaved ] = React.useState<boolean>(false)

  React.useEffect(() => {
    // Make sure list is loaded
    if (isSuccess(list)) {
      // Check to see if there is a saved value
      if (list.payload.suggestionListById.contextualRelevancy
        && list.payload.suggestionListById.contextualRelevancy !== "") {
        // Mark as saved and don't expand
        setSaved(true)
        setExpanded(false)
      } else {
        // Mark as not saved and expand
        setSaved(false)
        setExpanded(true)
      }
    }
  }, [ list ])

  const handleRelevancyChange = (e: SelectChangeEvent<string>) => {
    if (updateListForm) {
      // Make copy of the list form
      const newListForm = misc.copyObject(updateListForm)

      // Set the relevancy
      newListForm.contextualRelevancy = (e.target.value !== "") ? e.target.value : undefined

      // Save form
      dispatch(setUpdateListForm(newListForm))
    }
  }

  const handleRelevancyFormPersistence = () => {
    // Make the call
    dispatch(updateList({
      onSuccess: () => {
        dispatch(pushToast({
          type: "success",
          message: translate("Successfully save contextual relevancy form!"),
        }))
        setSaved((updateListForm?.contextualRelevancy !== undefined && updateListForm?.contextualRelevancy !== null))
        setExpanded((updateListForm?.contextualRelevancy === undefined || updateListForm?.contextualRelevancy === null))
        dispatch(fetchList(listID || ""))
      },
      onError: () => {
        dispatch(pushToast({
          type: "error",
          message: "Unable to save contextual relevancy form. Please try again later!",
        }))
      },
    }))
  }

  if (list === "init" || list === "loading") return <LoadingIndicatorCard />

  return (
    <Accordion
      onChange={ () => setExpanded(!expanded) }
      className="highlights-relevancy-form"
      elevation={ 0 }
      id="highlights-relevancy-form"
      defaultExpanded={ true }
      expanded={ expanded }
    >
      <AccordionSummary
        className="highlights-relevancy-form-summary"
        expandIcon={ expanded
          ? <KeyboardArrowUp className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
          : <KeyboardArrowRight className={ `cp_lcv-accordion-icon-md${ (saved) ? " dsf-saved" : " dsf-not-saved" }` } />
        }
        aria-controls="panel1a-content"
        id="highlights-relevancy-header"
      >
        <h3 className={ `highlights-relevancy-form-summary-heading${ (saved) ? " dsf-saved" : " dsf-not-saved" }` }>
          { translate(heading) }
        </h3>
      </AccordionSummary>
      <AccordionDetails className="highlights-relevancy-form-details">
        <div className="relevancy-form">
          <FormControl
            className="relevancy-form-control"
            component="fieldset"
            fullWidth={ true }
          >
            <FormLabel component="legend" className="section-label">
              { translate("Contextual Relevancy") }
            </FormLabel>
            <Select
              id="cp_component_contextual-relevancy_select"
              className="relevancy-form-control-select"
              label=""
              labelId="cp_component_contextual-relevancy_id"
              menuItems={
                [ { name: translate("None"), id: "" },
                  ...contextualRelevancyOptions,
                ].map((option) => <MenuItem key={ option.id } value={ option.id }>{ option.name }</MenuItem>) }
              onChange={ (e) => handleRelevancyChange(e) }
              value={ (updateListForm && updateListForm.contextualRelevancy) ? updateListForm.contextualRelevancy : "" }
            />
          </FormControl>
        </div>
        <div className="relevancy-form-cta">
          <Button
            id="contextual-relevancy-form-save-button"
            label={ translate("Save") }
            isPrimary={ true }
            fullWidth={ true }
            onClick={ handleRelevancyFormPersistence }
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
