import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import {
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import daysjs from "dayjs"
import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import Pill from "../Pill"
import DataGrid from "../DataGrid"
import { useDispatch } from "../../state/hooks"
import {
  fetchRole,
  openRoleModal,
  setEditRoleId,
  setModalRoleEditing,
} from "../../state/ModalRoleSlice"

type Props = {
  rawRows: GraphQL.SearchTableRoleQuery["searchRole"]["rows"] | null
  loading: boolean
}

export default function RolesTable({
  rawRows, loading,
}: Props) {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.RolesOverview" })
  const dispatch = useDispatch()

  // Local state
  const [ sortModel, setSortModel ] = React.useState<GridSortModel>([ {
    field: GraphQL.SearchRoleSort.Code.toLocaleLowerCase(),
    sort: null,
  }, {
    field: GraphQL.SearchRoleSort.Display.toLocaleLowerCase(),
    sort: null,
  }, {
    field: GraphQL.SearchRoleSort.Created.toLocaleLowerCase(),
    sort: null,
  } ])

  // React hooks

  // Build headers for table
  const headers = React.useMemo((): GridColDef[] => ([ {
    field: GraphQL.SearchRoleSort.Code.toLocaleLowerCase(),
    headerName: translate(GraphQL.SearchRoleSort.Code),
    sortable: true,
    disableColumnMenu: true,
    resizable: false,
    renderCell: (params) => (
      <p>{ params.row.code }</p>
    ),
    flex: 1,
  }, {
    field: GraphQL.SearchRoleSort.Display.toLocaleLowerCase(),
    headerName: translate(GraphQL.SearchRoleSort.Display),
    sortable: true,
    disableColumnMenu: true,
    resizable: false,
    renderCell: (params) => (
      <Pill
        id={ `role-display-item-${ params.row.index }` }
        className="cell-pill-color0"
        label={ params.row.display }
      />
    ),
    flex: 1,
  }, {
    field: GraphQL.SearchRoleSort.Created.toLocaleLowerCase(),
    headerName: translate(GraphQL.SearchRoleSort.Created),
    sortable: true,
    disableColumnMenu: true,
    resizable: false,
    renderCell: (params) => (
      <p>{ params.row.created }</p>
    ),
    flex: 1,
    sortComparator: (v1, v2) => {
      const date1 = daysjs(v1, Constant.MONTH_DAY_ABBR_FORMAT).unix()
      const date2 = daysjs(v2, Constant.MONTH_DAY_ABBR_FORMAT).unix()
      return date1 - date2
    },
  } ]), [ ])

  // Build row data for table
  const rows = React.useMemo(() => (
    (rawRows === null) ? []
      : rawRows.map((row, index): GridValidRowModel | null => ({
        id: row.id,
        code: row.code,
        display: row.display,
        created: daysjs.unix(row.created).format(Constant.MONTH_DAY_ABBR_FORMAT),
        index,
      }))
  ), [ rawRows ])

  /**
   * clickOnRole: Open the role for editing
   * @param params The row parameters
   */
  const clickOnRole = (params: GridRowParams) => {
    // Set the id, editing, modal open
    dispatch(fetchRole(params.row.id))
    dispatch(setEditRoleId(params.row.id))
    dispatch(setModalRoleEditing(true))
    dispatch(openRoleModal(true))
  }

  // Return the table
  return (
    <DataGrid
      getRowId={ (row) => row.id }
      checkboxSelection={ false }
      columns={ headers }
      rows={ rows }
      loading={ loading }
      sortModel={ sortModel }
      onSortModelChange={ setSortModel }
      onRowClick={ clickOnRole }
      disableColumnReorder={ true }
    />
  )
}
