import React from "react"
import CampaignIcon from "@mui/icons-material/Campaign"
import CheckIcon from "@mui/icons-material/Check"
import DownloadIcon from "@mui/icons-material/Download"
import ImageIcon from "@mui/icons-material/Image"
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo"
import { Avatar, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

import Badge from "../Badge"
import Tooltip from "../Tooltip"
import { CampaignPermissionType, DeliverableMediaFragment } from "../../graphql"
import { Scope } from "../../util/types"
import { isSuccess } from "../../util/apiClient"
import {
  setMediaFeedback,
  setMediaToApprove,
  setEdited,
} from "../../state/campaignDeliverableContentModalSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./media-card.sass"

interface DeliverableMediaCardProps {
  deliverableMedia: DeliverableMediaFragment
}

function DeliverableMediaCard({ deliverableMedia }: DeliverableMediaCardProps): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    mediaFeedback, deliverable, campaign,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)
  // const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const {
    user,
    scopes,
  } = useSelector(({ user: userSlice }) => userSlice)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })
  const hasFeedbackPermission = React.useMemo(() => {
    /*
      if they are not from the team owner of the deliverable,
      then they must be assigned the permission to VIEW as a brand manager.
    */
    if (!campaign || !isSuccess(user)) return false
    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id
    const brandManager = campaign.userPermissions.find((permission) => permission.user.id === user.payload.currentUser?.id)
    const brandManagerWithViewPermission = !!brandManager && brandManager.permissions
      .includes(CampaignPermissionType.View)
    if (!userTeamIsCreator) return brandManagerWithViewPermission
    return true
  }, [ campaign, user, scopes ])

  const hasApprovalPermission = React.useMemo(() => {
    /*
      if they are not from the team owner of the deliverable,
      then they must be assigned the permission to APPROVE as a brand manager.
    */
    if (!campaign || !isSuccess(user)) return false

    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id
    const brandManager = campaign.userPermissions.find((permission) => permission.user.id === user.payload.currentUser?.id)
    const brandManagerWithApprovalPermission = !!brandManager && brandManager.permissions
      .includes(CampaignPermissionType.Approve)
    if (!userTeamIsCreator) return brandManagerWithApprovalPermission
    return brandManagerWithApprovalPermission || scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  }, [ campaign, user, scopes ])

  return (
    <div className="cp_campaign-deliverable-media-card_component">
      <div className="cp_campaign-deliverable-media-card_component-avatar">
        <Avatar variant="square" src={ deliverableMedia.media.url.address } />
        <a
          className="cp_campaign-deliverable-media-card_component-avatar-download"
          aria-label="download-deliverable-media"
          download={ deliverableMedia.media.originalFilename }
          href={ `/api/media/url/${ deliverableMedia.media.originalUrl?.code || "" }` }
          target="_blank"
          rel="noreferrer"
        >
          <DownloadIcon />
        </a>
        { deliverableMedia.media.isVideo ? <OndemandVideoIcon /> : <ImageIcon /> }
      </div>
      <div className="cp_campaign-deliverable-media-card_component-controls">
        <IconButton
          className={ `btn-approve-media ${ deliverableMedia.accepted ? "approved" : "" }` }
          onClick={ () => {
            dispatch(setMediaToApprove({ media: deliverableMedia }))
            dispatch(setEdited(true))
            dispatch(setMediaFeedback({ media: undefined }))
          } }
          disabled={ deliverableMedia.accepted || !hasApprovalPermission }
        >
          <CheckIcon />
        </IconButton>
        <Tooltip title={ translate("Leave Feedback") }>
          <div>
            <Badge badgeContent={ deliverableMedia.feedback.length }>
              <IconButton
                className={ `btn-feedback ${ mediaFeedback?.id === deliverableMedia.id ? "active" : "" }` }
                disabled={ !hasFeedbackPermission }
                onClick={ () => {
                  dispatch(setMediaToApprove({ media: undefined }))
                  dispatch(setMediaFeedback({ media: deliverableMedia }))
                } }
              >
                <CampaignIcon />
              </IconButton>
            </Badge>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default DeliverableMediaCard
