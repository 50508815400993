import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import { CreateRoleMap, ScopeContent } from "../../util/types"
import { setScopeMap } from "../../state/ModalRoleSlice"

/**
 * createScopeMap: Creates an object used by the create role modal process to keep track of scopes
 * that are enabled for the role.
 * @param groups The database set of scope groups their scopes
 * @param dispatch Handle state updates
 */
export function createScopeMap(groups: GraphQL.ScopeGroupsQuery["scopeGroups"], dispatch: Dispatch) {
  // Create the map object
  const scopeMap: CreateRoleMap = {}

  // Filter and loop through groups to build scope map
  const moveItems: { group: string, scopeToMove: ScopeContent }[] = []
  groups
    .filter((group) => {
      let allow = true
      if (group.name === "Uncategorized") allow = false
      if (group.name === "Team") allow = false
      return allow
    })
    .forEach((group) => {
      const scopes: ScopeContent[] = group.scopes
        .filter((scope) => {
          let allow = true
          // Filter out
          if (scope.code === "delete_social_account") allow = false
          if (scope.code === "feature_enable_twitter") allow = false
          if (scope.code === "post_location") allow = false
          if (scope.code === "engagement_rate_distribution") allow = false
          if (scope.code === "entity_sentiment") allow = false
          if (scope.code === "feature_clients") allow = false
          if (scope.code === "social_account_oauth_twitter_create") allow = false
          if (scope.code === "social_account_assoc_twitter_create") allow = false
          if (scope.code === "social_account_assoc_twitter_delete") allow = false
          if (scope.code === "feature_content_search") allow = false
          if (scope.code === "feature_facial_similarity") allow = false
          if (scope.code === "feature_personality_similarity") allow = false
          if (scope.code === "feature_demographic_similarity") allow = false
          if (scope.code === "feature_content_similarity") allow = false
          if (scope.code === "feature_search_content_geotag") allow = false

          // Filter out to be placed elsewhere
          if (scope.code === "placeholder_account") {
            moveItems.push({
              group: "Profiles",
              scopeToMove: {
                id: Number(scope.id),
                code: scope.code,
                description: scope.display,
                enabled: false,
              },
            })
            allow = false
          }

          // Return allow
          return allow
        })
        .map((scope) => {
          // Create scope data for group
          const scopeData: ScopeContent = {
            id: Number(scope.id),
            code: scope.code,
            description: scope.display,
            enabled: false,
          }

          // Return the data
          return scopeData
        })

      // Set the scopes for given group
      scopeMap[group.name] = scopes
    })

  // Add moved items
  moveItems.forEach((mitem) => {
    scopeMap[mitem.group].push(mitem.scopeToMove)
  })

  // Update the scope map in the state
  dispatch(setScopeMap(scopeMap))
}

export const updateScopeMap = (scopeMap: CreateRoleMap, scopes: GraphQL.GetRoleQuery["role"]["scopes"], dispatch: Dispatch) => {
  // Create new map object
  const newScopeMap: CreateRoleMap = {}

  // Loop through old map
  Object.keys(scopeMap).forEach((section) => {
    // Create new scopes
    const mscopes: ScopeContent[] = scopeMap[section].map((scope) => {
      const found = scopes.find((rscope) => rscope.id.toLowerCase() === scope.id.toString().toLowerCase())

      // If it was found, mark it enabled
      if (found) {
        return {
          id: scope.id,
          code: scope.code,
          description: scope.description,
          enabled: true,
        }
      }

      // Not found mark disabled
      return {
        id: scope.id,
        code: scope.code,
        description: scope.description,
        enabled: false,
      }
    })

    // Update the section
    newScopeMap[section] = mscopes
  })

  // Update the scope map in the state
  dispatch(setScopeMap(newScopeMap))
}

/**
 * getActiveToggles: Finds all the toggle switches that are enabled for the given scopes
 * @param scopes An array of scopes (usually part of a scope group)
 * @returns The number of active scopes
 */
export const getActiveToggles = (scopes: ScopeContent[]): number => scopes.filter((scope) => scope.enabled).length

/**
 * areAllTogglesSwitched: Checks to see if all the toggles are enabled for the given list of scopes
 * @param scopes An array of scopes (usually part of a scope group)
 * @returns True if all are enabled, otherwise false
 */
export const areAllTogglesSwitched = (scopes: ScopeContent[]): boolean => getActiveToggles(scopes) === scopes.length

/**
 * toggleAllScopes: Toggles all the scopes int the list to the oppositve of the given toggle state
 * @param scopes An array of scopes (usually part of a scope group)
 * @param currentAllToggle The current all toggle state
 * @returns All the scopes with them enabled or disabled
 */
export const toggleAllScopes = (scopes: ScopeContent[], currentAllToggle: boolean): ScopeContent[] => {
  // Create updated scope variable
  const updated: ScopeContent[] = scopes.map((scope) => ({
    id: scope.id,
    code: scope.code,
    description: scope.description,
    enabled: !currentAllToggle,
  }))

  // Return value
  return updated
}

/**
 * toggleScope: This will toggle a single scope within the given scopes to the opposite of the
 * current state
 * @param scopes An array of scopes (usually part of a scope group)
 * @param id The id of the scope to toggle
 * @returns The array of scopes with scope that has the ID value toggled
 */
export const toggleScope = (scopes: ScopeContent[], id: number): ScopeContent[] => {
  // Create updated scopes variable
  const updated: ScopeContent[] = scopes.map((scope) => {
    // Check to see if the scope is the one that needs to be toggled
    if (scope.id === id) {
      // Toggle the scope
      return {
        id,
        code: scope.code,
        description: scope.description,
        enabled: !scope.enabled,
      }
    }

    // Copy over the scope
    return {
      id: scope.id,
      code: scope.code,
      description: scope.description,
      enabled: scope.enabled,
    }
  })

  // Return updated scopes
  return updated
}
