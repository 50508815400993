import moment from "moment"
import * as SearchGQL from "../../graphql/search"
import * as SearchHelper from "../../state/searchAISlice/helper"
import { Scope } from "../../util/types"
import { prettyPrintDecimal } from "../../util/miscHelper"
import * as Constant from "../../util/constant"

/**
 * CSVHelper: Helper class for generating CSV data and downloading CSV files
 */
export default class CSVHelper {
  /**
   * getPostType: Get the post type based on the network and post type
   * @param network The social network
   * @param isReel Is the post a reel
   * @param isShort Is the post a short
   * @param isStory Is the post a story
   * @param isHighlighted Is the post a highlighted post
   * @returns The post type
   */
  private static getPostType(
    network: SearchGQL.Network,
    isReel: boolean,
    isShort: boolean,
    isStory: boolean,
    isHighlighted: boolean,
  ): string {
    // Determine if snapchat or not
    if (network === SearchGQL.Network.Snapchat) {
      // Determine if post is highlighted, story, or snap
      if (isHighlighted) {
        return "Spotlight"
      }
      if (isStory) {
        return "Story Snap"
      }
      return "Snap"
    }

    // Determine if post is reel, short, or story
    if (isReel) {
      return "Reel"
    }

    if (isShort) {
      return "Short"
    }

    if (isStory) {
      return "Story"
    }

    // Nothing matched
    return ""
  }

  /**
   * generateAccountCsvData: Generate the CSV data for accounts
   * @param data The account data from the search
   * @param scopes The user's scopes
   * @returns A string of CSV data
   */
  public static generateAccountCsvData(data: SearchGQL.SearchAccountsTableRowFragment[], scopes: string[]): string {
    // Create the headers for accounts
    const headers = [
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.Account),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.Followers),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.EngagementRate),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.Summary),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.Biography),
    ]
      .filter((header) => (
        header !== SearchHelper.getHeaderName(SearchHelper.SearchColumn.IScore)
        || scopes.includes(Scope.FEATURE_SEARCH_ISCORE)
      ))
      .filter((header) => (
        header !== SearchHelper.getHeaderName(SearchHelper.SearchColumn.InDemoPercentage)
        || scopes.includes(Scope.FEATURE_SEARCH_IN_DEMO)
      ))
      .filter((header) => (
        header !== SearchHelper.getHeaderName(SearchHelper.SearchColumn.AdCouncilScore)
        || scopes.includes(Scope.SCORE_AD_COUNCIL)
      ))

    // Create the header row
    let csv = `${ headers.map((header) => `"${ header }"`).join(",") }\n`

    // Create the data rows
    csv += data.map((row) => {
      const rowData = [
        `${ row.username } (${ Constant.ReadableNetwork[row.network] })`,
        row.followers,
        `${ prettyPrintDecimal(row.median_engagement_rate) }%`,
        row.summary,
        row.bio,
      ]
        .filter((cell, index) => (
          headers[index] !== SearchHelper.getHeaderName(SearchHelper.SearchColumn.IScore)
          || scopes.includes(Scope.FEATURE_SEARCH_ISCORE)
        ))
        .filter((cell, index) => (
          headers[index] !== SearchHelper.getHeaderName(SearchHelper.SearchColumn.InDemoPercentage)
          || scopes.includes(Scope.FEATURE_SEARCH_IN_DEMO)
        ))
        .filter((cell, index) => (
          headers[index] !== SearchHelper.getHeaderName(SearchHelper.SearchColumn.AdCouncilScore)
          || scopes.includes(Scope.SCORE_AD_COUNCIL)
        ))

      return rowData.map((cell) => {
        if (typeof cell === "string") {
          return `"${ cell.replace(/"/g, "'").replace(/(\r\n|\n)+/g, " ") }"`
        }
        return cell
      }).join(",")
    }).join("\n")

    return csv
  }

  /**
   * generateContentCsvData: Generate the CSV data for content search
   * @param data The content data from the search
   * @returns A string of CSV data
   */
  public static generateContentCsvData(data: SearchGQL.SearchPostsTableRowFragment[]): string {
    // Create the headers for accounts
    const headers = [
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.Account),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.Followers),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.PostDate),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.PostType),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.Media),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.PostDetails),
      SearchHelper.getHeaderName(SearchHelper.SearchColumn.EngagementRate),
    ]

    // Create the header row
    let csv = `${ headers.map((header) => `"${ header }"`).join(",") }\n`

    // Create the data rows
    csv += data.map((row) => {
      const rowData = [
        `${ row.username } (${ Constant.ReadableNetwork[row.network] })`,
        row.followers,
        moment.unix(row.postedTime).format(Constant.LONGFORM_DATE_TIME),
        this.getPostType(
          row.network,
          row.postType === SearchGQL.PostType.Reel,
          row.postType === SearchGQL.PostType.Short,
          row.postType === SearchGQL.PostType.Story,
          false,
        ),
        row.permalink,
        row.postContent,
        `${ row.engagementRate >= 1 ? 100 : prettyPrintDecimal(row.engagementRate) }%${ row.engagementRate >= 1 ? "+" : "" }`,
      ]

      return rowData.map((cell) => {
        if (typeof cell === "string") {
          return `"${ cell.replace(/"/g, "'").replace(/(\r\n|\n)+/g, " ") }"`
        }
        return cell
      }).join(",")
    }).join("\n")

    return csv
  }

  /**
   * downloadCsv: Download the CSV file
   * @param data The CSV data
   * @param filename The name of the file
   * @param fileType The file type
   */
  public static downloadCsv(data: string, filename: string, fileType: string): void {
    // Create the file contents
    const blob = new Blob([ data ], { type: fileType })

    // Create the download link
    const a = document.createElement("a")
    a.download = filename
    a.href = URL.createObjectURL(blob)

    // Create click event
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    })

    // Dispatch the click event and remove the link
    a.dispatchEvent(clickEvent)
    a.remove()
  }
}

export const {
  generateAccountCsvData,
  generateContentCsvData,
  downloadCsv,
} = CSVHelper
