import React from "react"
import { TFunction } from "i18next"
import {
  GridColDef,
  GridValidRowModel,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro"

import { Typography } from "@mui/material"
import * as SearchGQL from "../../graphql/search"
import * as SearchHelper from "../../state/searchAISlice/helper"
import AccountEllipsisMenuCell from "./AccountEllipsisMenuCell"
import Pill from "../Pill"
import SocialAvatar from "../SocialAvatar"
import Tooltip from "../Tooltip"
import { Scope, ScoreBreakDown } from "../../util/types"
import { prettyPrintDecimal } from "../../util/miscHelper"
import BoldedKeywords from "../BoldedKeywords"

type Params = GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
interface ScorePill {
  params: Params;
  scoreType: string;
  modalType: string;
  isAdCouncilScore: boolean;
}

export function generateAccountResultsColumns(
  translate: TFunction,
  scopes: string[],
  handleScoreModal: (scoreBreakDown: ScoreBreakDown, modalType: string, isAdCouncilScore?: boolean) => void,
): GridColDef[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderScorePill({
    params, scoreType, modalType, isAdCouncilScore,
  }: ScorePill) {
    const { scoreBreakDown } = params.row
    const score = params.row[scoreType]
    return (
      <Pill
        label={ score }
        handleClick={
          (scoreBreakDown && score)
            ? () => { handleScoreModal(scoreBreakDown, modalType, isAdCouncilScore) }
            : () => {}
         }
      />
    )
  }
  const columns: GridColDef[] = [
    {
      field: SearchHelper.SearchColumn.Account,
      headerName: translate("Account"),
      sortable: false,
      renderCell: (params) => (
        <SocialAvatar { ...params.row.account } />
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 300,
    },
    {
      field: SearchHelper.SearchColumn.Followers,
      description: translate("Tooltip Followers"),
      headerName: translate("Followers"),
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.followers }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.EngagementRate,
      description: translate("Tooltip Engagement Rate"),
      headerName: translate("Engagement Rate"),
      sortable: false,
      renderCell: (params) => (
        <p>{ params.row.engagementRate }</p>
      ),
      disableColumnMenu: true,
    },
    {
      field: SearchHelper.SearchColumn.Summary,
      description: translate("AI Generated Summarization"),
      headerName: translate("Summary"),
      sortable: false,
      renderCell: (params) => (
        <Tooltip
          arrow={ true }
          title={ params.row.summary }
        >
          <Typography
            component="p"
            noWrap={ true }
          >
            { params.row.summary }
          </Typography>
        </Tooltip>
      ),
      disableColumnMenu: true,
      width: 500,
    },
    {
      field: SearchHelper.SearchColumn.Biography,
      description: translate("Tooltip Biography"),
      headerName: translate("Biography"),
      sortable: false,
      renderCell: (params) => (
        <Tooltip
          arrow={ true }
          title={ <BoldedKeywords text={ params.row.biography || "" } shouldBeBold={ params.row.bioKeywords } /> }
        >
          <Typography
            component="p"
            noWrap={ true }
          >
            <div>
              <BoldedKeywords text={ params.row.biography || "" } shouldBeBold={ params.row.bioKeywords } />
            </div>
          </Typography>
        </Tooltip>
      ),
      disableColumnMenu: true,
      width: 300,
    },
    {
      field: SearchHelper.SearchColumn.EllipsisMenu,
      headerName: "", // This column should be pinned and doesn't need a header
      sortable: false,
      renderCell: (params) => (
        <AccountEllipsisMenuCell
          hasPersonality={ params.row.account.hasPersonality }
          network={ params.row.account.network }
          socialAccountId={ params.row.id }
          socialAccountUrl={ params.row.account.profileNetworkUrl }
        />
      ),
      disableColumnMenu: true,
      width: 50,
    },
  ]

  return columns
    // Hide or display IScore column depending on scope...
    .filter((column) => {
      if (column.field !== SearchHelper.SearchColumn.IScore) return true
      return scopes.includes(Scope.FEATURE_SEARCH_ISCORE)
    })

    // Hide or display In Demo column depending on scope...
    .filter((column) => {
      if (column.field !== SearchHelper.SearchColumn.InDemoPercentage) return true
      return scopes.includes(Scope.FEATURE_SEARCH_IN_DEMO)
    })

    // Hide or display Ad Council column depending on scope...
    .filter((column) => {
      if (column.field !== SearchHelper.SearchColumn.AdCouncilScore) return true
      return scopes.includes(Scope.SCORE_AD_COUNCIL)
    })
}

export function generateAccountResultsRow(
  row: SearchGQL.SearchAccountsTableRowFragment,
  translateCommon: TFunction,
  bioKeywords: string[],
): GridValidRowModel | null {
  return {
    id: row.id,
    account: {
      followers: row.followers,
      isBlacklisted: row.isBlockListed,
      isNSFW: row.isNsfw,
      isPlaceholder: row.isPlaceholder,
      lastPostedDate: row.lastPostedDate,
      network: row.network,
      profilePictureUrl: row.profilePictureUrl,
      profileNetworkUrl: row.networkUrl,
      username: row.username,
      hasPersonality: row.hasPersonality,
      oauthed: row.oauthed,
    },
    followers: translateCommon("intlNumber", { val: row.followers }),
    engagementRate: `${ prettyPrintDecimal(row.median_engagement_rate) }%`,
    engagementScore: row.engagementScore,
    audienceQualityScore: row.audienceQualityScore,
    adCouncilScore: row.adCouncilScore,
    biography: row.bio,
    summary: row.summary,
    bioKeywords,
  }
}
