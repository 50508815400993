import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import dayjs, { Dayjs } from "dayjs"
import * as Constant from "../../../util/constant"
import DatePicker from "../../DatePicker"
import Input from "../../Input"
import LoadingIndicator from "../../LoadingIndicator"
import Modal from "../../Modal"
import ReturnToTag from "../../ReturnToTag"
import { Network } from "../../../graphql"
import {
  hideDeliverableContentModal,
  setDeliverable,
  setEdited,
} from "../../../state/campaignDeliverableContentModalSlice"
import { useDispatch, useSelector } from "../../../state/hooks"
import {
  closeDeliverableMetricModal,
  setModalOpen,
  updateDeliverableMetrics,
} from "../../../state/campaignDeliverableMetricModalSlice"
import {
  MetricKey,
  getDeliverableMetricType,
  getMetricInputFieldKeys,
} from "./util"
import { fetchDeliverable, isSuccess } from "../../../util/apiClient"

import "./campaign-update-deliverable-metric-modal.sass"

export default function CampaignUpdateDeliverableMetricsModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignUpdateDeliverableMetricsModal" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()

  const {
    modalOpen,
    deliverable,
    updateCallback,
    campaign,
    source,
  } = useSelector(({ campaignDeliverableMetricModal }) => campaignDeliverableMetricModal)

  const [ postDate, setPostDate ] = useState<Dayjs | string | null>(null)
  const [ comments, setComments ] = useState<string | null>(null)
  const [ stickerTaps, setStickerTaps ] = useState<string | null>(null)
  const [ replies, setReplies ] = useState<string | null>(null)
  const [ likes, setLikes ] = useState<string | null>(null)
  const [ saves, setSaves ] = useState<string | null>(null)
  const [ shares, setShares ] = useState<string | null>(null)
  const [ linkClicks, setLinkClicks ] = useState<string | null>(null)
  const [ views, setViews ] = useState<string | null>(null)
  const [ actualReach, setActualReach ] = useState<string | null>(null)
  const [ videoViews, setVideoViews ] = useState<string | null>(null)
  const [ trueImpressions, setTrueImpressions ] = useState<string | null>(null)
  const [ grossImpressions, setGrossImpressions ] = useState<string | null>(null)

  const [ submitLoading, setSubmitLoading ] = useState(false)

  useEffect(() => {
    if (deliverable && deliverable.metrics && modalOpen) {
      const {
        metrics,
      } = deliverable

      setPostDate(metrics.postedDate ? dayjs(metrics.postedDate) : null)
      setComments(metrics.comments ? metrics.comments.toString() : null)
      setStickerTaps(metrics.stickerTaps ? metrics.stickerTaps.toString() : null)
      setReplies(metrics.replies ? metrics.replies.toString() : null)
      setLikes(metrics.likes ? metrics.likes.toString() : null)
      setSaves(metrics.saves ? metrics.saves.toString() : null)
      setShares(metrics.shares ? metrics.shares.toString() : null)
      setLinkClicks(metrics.linkClicks ? metrics.linkClicks.toString() : null)
      setViews(metrics.views ? metrics.views.toString() : null)
      setActualReach(metrics.reach ? metrics.reach.toString() : null)
      setVideoViews(metrics.videoViews ? metrics.videoViews.toString() : null)
      setTrueImpressions(metrics.impressions ? metrics.impressions.toString() : null)
      setGrossImpressions(metrics.followerCount ? metrics.followerCount.toString() : null)
    }
  }, [ deliverable, modalOpen ])

  const resetFields = () => {
    setPostDate(null)
    setComments(null)
    setStickerTaps(null)
    setReplies(null)
    setLikes(null)
    setSaves(null)
    setShares(null)
    setLinkClicks(null)
    setViews(null)
    setActualReach(null)
    setVideoViews(null)
    setTrueImpressions(null)
    setGrossImpressions(null)
  }

  const onClose = async () => {
    dispatch(setModalOpen(false))
    dispatch(hideDeliverableContentModal(false))
    resetFields()
  }

  const onSubmit = async () => {
    if (deliverable) {
      setSubmitLoading(true)
      let newPostDateDayjs
      if (postDate) {
        newPostDateDayjs = !dayjs.isDayjs(postDate) ? dayjs(postDate) : postDate
      } else {
        newPostDateDayjs = null
      }
      await dispatch(updateDeliverableMetrics({
        variables: {
          deliverableId: deliverable.id,
          comments: comments ? parseInt(comments, 10) : null,
          followerCount: grossImpressions ? parseInt(grossImpressions, 10) : null,
          impressions: trueImpressions ? parseInt(trueImpressions, 10) : null,
          likes: likes ? parseInt(likes, 10) : null,
          linkClicks: linkClicks ? parseInt(linkClicks, 10) : null,
          postedDate: newPostDateDayjs ? newPostDateDayjs.format(Constant.MARIA_DATE_FORMAT) : null,
          reach: actualReach ? parseInt(actualReach, 10) : null,
          replies: replies ? parseInt(replies, 10) : null,
          saves: saves ? parseInt(saves, 10) : null,
          shares: shares ? parseInt(shares, 10) : null,
          stickerTaps: stickerTaps ? parseInt(stickerTaps, 10) : null,
          videoViews: videoViews ? parseInt(videoViews, 10) : null,
          views: views ? parseInt(views, 10) : null,
        },
        onSuccess: () => {},
        onError: () => {},
      }))
      await dispatch(setEdited(true))
      if (source === "menu") {
        setSubmitLoading(false)
        await dispatch(closeDeliverableMetricModal())
        if (updateCallback) {
          await updateCallback(true)
        }
      } else {
        if (updateCallback) {
          await updateCallback(true)
        }
        const result = await fetchDeliverable({ deliverableId: deliverable.id })
        setSubmitLoading(false)
        await dispatch(closeDeliverableMetricModal())
        if (isSuccess(result) && campaign) {
          await dispatch(setDeliverable({ deliverable: result.payload.deliverable }))
          await dispatch(hideDeliverableContentModal(false))
        }
      }

      resetFields()
    }
  }

  const deliverableMetricType = useMemo(() => {
    if (deliverable) {
      const {
        campaignNetworkAccount: {
          socialAccount: {
            network,
          },
        },
        postType,
        postFormat,
      } = deliverable
      if (postType && postFormat) {
        return getDeliverableMetricType(network, postType, postFormat)
      }
    }
    return null
  }, [ deliverable ])

  const [
    displayPostDate,
    displayComments,
    displayStickerTaps,
    displayReplies,
    displayLikes,
    displaySaves,
    displayShares,
    displayLinkClicks,
    displayViews,
    displayActualReach,
    displayVideoViews,
    displayTrueImpressions,
    displayGrossImpressions,
  ] = useMemo(() => {
    if (deliverableMetricType) {
      const displayKeys = getMetricInputFieldKeys(deliverableMetricType)
      return [
        displayKeys.includes(MetricKey.POST_DATE),
        displayKeys.includes(MetricKey.COMMENTS),
        displayKeys.includes(MetricKey.STICKER_TAPS),
        displayKeys.includes(MetricKey.REPLIES),
        displayKeys.includes(MetricKey.LIKES),
        displayKeys.includes(MetricKey.SAVES),
        displayKeys.includes(MetricKey.SHARES),
        displayKeys.includes(MetricKey.LINK_CLICKS),
        displayKeys.includes(MetricKey.VIEWS),
        displayKeys.includes(MetricKey.ACTUAL_REACH),
        displayKeys.includes(MetricKey.VIDEO_VIEWS),
        displayKeys.includes(MetricKey.TRUE_IMPRESSIONS),
        displayKeys.includes(MetricKey.GROSS_IMPRESSIONS),
      ]
    }
    return Array(9).fill(false)
  }, [ deliverableMetricType ])

  const organicSuffix = useMemo(() => (deliverable?.campaignNetworkAccount.socialAccount.network === Network.Instagram
    ? translate("(Organic)")
    : ""), [ deliverable, translate ])

  if (!deliverable) return null
  if (!campaign) return null

  return (
    <Modal
      className="cp_component_campaign-update-deliverable-metric-modal"
      open={ modalOpen }
      title={ translate("Update Metrics") }
      subtitle={ translate("Input metrics that are provided by the influencer.") }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Save") }
      secondaryLabel={ translateCommon("Cancel") }
      primaryAction={ onSubmit }
      secondaryAction={ onClose }
      closeAction={ onClose }
      maxWidth="xl"
    >
      { source !== "menu" ? (
        <ReturnToTag
          className="return-tag"
          label={ translate("< BACK TO DELIVERABLE") }
          onClick={ () => {
            dispatch(hideDeliverableContentModal(false))
            dispatch(closeDeliverableMetricModal())
          }
      }
        />
      ) : <br />
      }
      { displayPostDate && (
      <div className="post-date-container">
        <p className="label_small-caps-bold">{ translate("Post Date") }</p>
        <DatePicker
          value={ dayjs(postDate) }
          onDateChange={ (d) => setPostDate(d) }
          onAccept={ (d) => setPostDate(d) }
        />
      </div>
      ) }
      { displayComments && (
      <Input
        className="metric-input"
        label={ `${ translate("Comments") } ${ organicSuffix }` }
        placeholder={ translate("Enter Comments") }
        value={ comments }
        onChange={ (e) => setComments(e.target.value) }
      />
      ) }
      { displayStickerTaps && (
      <Input
        className="metric-input"
        label={ `${ translate("Sticker Taps") } ${ organicSuffix }` }
        placeholder={ translate("Enter Sticker Taps") }
        value={ stickerTaps }
        onChange={ (e) => setStickerTaps(e.target.value) }
      />
      ) }
      { displayReplies && (
      <Input
        className="metric-input"
        label={ `${ translate("Replies") } ${ organicSuffix }` }
        placeholder={ translate("Enter Replies") }
        value={ replies }
        onChange={ (e) => setReplies(e.target.value) }
      />
      ) }
      { displayLikes && (
      <Input
        className="metric-input"
        label={ `${ translate("Likes") } ${ organicSuffix }` }
        placeholder={ translate("Enter Likes") }
        value={ likes }
        onChange={ (e) => setLikes(e.target.value) }
      />
      ) }
      { displaySaves && (
      <Input
        className="metric-input"
        label={ `${ translate("Saves") } ${ organicSuffix }` }
        placeholder={ translate("Enter Saves") }
        value={ saves }
        onChange={ (e) => setSaves(e.target.value) }
      />
      ) }
      { displayShares && (
      <Input
        className="metric-input"
        label={ `${ translate("Shares") } ${ organicSuffix }` }
        placeholder={ translate("Enter Shares") }
        value={ shares }
        onChange={ (e) => setShares(e.target.value) }
      />
      ) }
      { displayLinkClicks && (
      <Input
        className="metric-input"
        label={ `${ translate("Link Clicks") } ${ organicSuffix }` }
        placeholder={ translate("Enter Link Clicks") }
        value={ linkClicks }
        onChange={ (e) => setLinkClicks(e.target.value) }
      />
      ) }
      { displayViews && (
      <Input
        className="metric-input"
        label={ `${ translate("Views") } ${ organicSuffix }` }
        placeholder={ translate("Enter Views") }
        value={ views }
        onChange={ (e) => setViews(e.target.value) }
      />
      ) }
      { displayActualReach && (
      <Input
        className="metric-input"
        label={ `${ translate("Actual Reach") } ${ organicSuffix }` }
        placeholder={ translate("Enter Actual Reach") }
        value={ actualReach }
        onChange={ (e) => setActualReach(e.target.value) }
      />
      ) }
      { displayVideoViews && (
      <Input
        className="metric-input"
        label={ `${ translate("Video Views") } ${ organicSuffix }` }
        placeholder={ translate("Enter Video Views") }
        value={ videoViews }
        onChange={ (e) => setVideoViews(e.target.value) }
      />
      ) }
      { displayTrueImpressions && (
      <Input
        className="metric-input"
        label={ `${ translate("True Impressions") } ${ organicSuffix }` }
        placeholder={ translate("Enter True Impressions") }
        value={ trueImpressions }
        onChange={ (e) => setTrueImpressions(e.target.value) }
      />
      ) }
      { displayGrossImpressions && (
      <Input
        className="metric-input"
        label={ translate("Gross Impressions (Follower Count)") }
        placeholder={ translate("Enter Gross Impressions") }
        value={ grossImpressions }
        onChange={ (e) => setGrossImpressions(e.target.value) }
      />
      ) }
    </Modal>
  )
}
