import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Scope, Status } from "../../util/types"

// Add Accounts to Lists Modal Slice Interface and Initial State
interface ModalNewTracker {
  isModalOpen: boolean,
  socialAccounts: Status<GraphQL.SearchSocialAccountsQuery>,
  newTrack: Status<GraphQL.CreateContentAlertMutation | null>,
  isEditTracker: boolean,
  editTrackerId: string,
  editTracker: Status<GraphQL.GetContentAlertsAndSearchContentViaContentAlertQuery>,
}

const initialState: ModalNewTracker = {
  isModalOpen: false,
  socialAccounts: "init",
  newTrack: "init",
  isEditTracker: false,
  editTrackerId: "",
  editTracker: "init",
}

// Add Accounts to Lists Modal Slice
export const ModalNewTrackerSlice = createSlice({
  name: "modalNewTracker",
  initialState,
  reducers: {
    setModalNewTrackerOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isModalOpen: action.payload,
    }),
    setSocialAccounts: (
      state,
      action: PayloadAction<Status<GraphQL.SearchSocialAccountsQuery>>,
    ) => ({
      ...state,
      socialAccounts: action.payload,
    }),
    newTrack: (
      state,
      action: PayloadAction<Status<GraphQL.CreateContentAlertMutation | null>>,
    ) => ({
      ...state,
      newTrack: action.payload,
    }),
    setTrackerForEdit: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isEditTracker: action.payload,
    }),
    setEditTrackerId: (state, action: PayloadAction<string>) => ({
      ...state,
      editTrackerId: action.payload,
    }),
    setEditTracker: (
      state,
      action: PayloadAction<Status<GraphQL.GetContentAlertsAndSearchContentViaContentAlertQuery>>,
    ) => ({
      ...state,
      editTracker: action.payload,
    }),
    setNewTrackerToInitState: () => initialState,
  },
})

export const {
  setModalNewTrackerOpen,
  setSocialAccounts,
  newTrack,
  setNewTrackerToInitState,
  setTrackerForEdit,
  setEditTrackerId,
  setEditTracker,
} = ModalNewTrackerSlice.actions
export default ModalNewTrackerSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchSocialAccounts = (
  startsWith: string,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setSocialAccounts("loading"))
  const socialAccountsResult = await API.fetchSocialAccounts(startsWith)

  dispatch(setSocialAccounts(socialAccountsResult))
}

export const createNewTrack = (params: {
  contentAlert: GraphQL.CreateContentAlertMutationVariables,
  scopes: string[],
  onSuccess: (newTrackerId?: string) => void,
  onError: () => void,
}) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(newTrack("loading"))

  const sanitizedContentAlert = { ...params.contentAlert }
  const sanitizedSearchInput = { ...params.contentAlert.params }
  const sanitizedNetworks = [ ...params.contentAlert.params.socialNetworks ]
    .filter((n) => {
      if (params.scopes.includes(Scope.FEATURE_TIKTOK)) return true
      return n !== GraphQL.Network.Tiktok
    })
    .filter((n) => {
      if (params.scopes.includes(Scope.FEATURE_ENABLE_SNAPCHAT)) return true
      return n !== GraphQL.Network.Snapchat
    })

  sanitizedSearchInput.socialNetworks = sanitizedNetworks
  sanitizedContentAlert.params = sanitizedSearchInput

  const socialTracker = await API.createContentAlert(sanitizedContentAlert)

  if (API.isSuccess(socialTracker)) params.onSuccess(socialTracker.payload?.createContentAlert.xref)
  if (API.isError(socialTracker)) params.onError()

  dispatch(newTrack(socialTracker))
}

export const fetchTrackerForEdit = (xref : string) => async (dispatch: Dispatch) => {
  dispatch(setEditTracker("loading"))
  const tracker = await API.fetchSocialTrackerById(xref, 1, 20)
  dispatch(setEditTracker(tracker))
}

export const deleteSocialTracker = (params: {
  id: string,
  onSuccess: () => void,
  onError: () => void,
}) => async () => {
  // Delete the old tracker
  const result = await API.deleteContentAlert({ id: params.id })

  // Check for success/fail
  if (API.isSuccess(result)) params.onSuccess()
  if (API.isError(result)) params.onError()
}
