import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import "../../Audience/index.sass"
import { useParams } from "react-router-dom"
import * as API from "../../../../util/apiClient"

import Divider from "../../../Divider"
import { useDispatch, useSelector } from "../../../../state/hooks"
import { fetchAudience } from "../../../../state/publicListSlice"
import LoadingIndicatorCard from "../../../LoadingIndicatorCard"
import FollowersChartWrapper from "./FollowersChartWrapper"
import GenderChartWrapper from "./GenderChartWrapper"
import MaritalChartWrapper from "./MaritalChartWrapper"
import FamilyChartWrapper from "./FamilyChartWrapper"
import BasicChartWrapper from "./BasicChartWrapper"
import EthnicityChartWrapper from "./EthnicityChartWrapper"
import ReligionsChartWrapper from "./ReligionsChartWrapper"
import VocationWrapper from "./VocationWrapper"
import LocationWrapper from "./LocationWrapper"
import AffinitiesWrapper from "./AffinitiesWrapper"
import LanguagesChartWrapper from "./LanguagesChartWrapper"
import { ListTogglesType } from "../../../../util/types"
import { getAudienceQualityScores } from "../../../../state/socialProfileSlice"

type Props = {
  toggles: ListTogglesType
}

export default function AccountAudienceDetails({ toggles }: Props) {
  const { audience, selectedListSocialAccount } = useSelector(({ publicList }) => publicList)
  const audienceQualityScore = useSelector(({ socialProfile }) => socialProfile.audienceQualityScore)
  const { t: translate } = useTranslation([], { keyPrefix: "component.AudienceDetails" })
  const dispatch = useDispatch()
  const { listCode } = useParams()

  useEffect(() => {
    if (!selectedListSocialAccount || !listCode) return
    dispatch(fetchAudience({
      code: listCode,
      socialAccountId: selectedListSocialAccount.socialAccount.id,
    }))
    if (audienceQualityScore === "init") {
      dispatch(getAudienceQualityScores(selectedListSocialAccount.socialAccount.id))
    }
  }, [ selectedListSocialAccount, audienceQualityScore ])

  // Memoized widget booleans check if each widget has data to display. If not, hide the widget.
  const showGenderWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(
      audience.payload.publicSocialAccount.audienceDemographics?.genderStatistics?.female
      && audience.payload.publicSocialAccount.audienceDemographics?.genderStatistics?.male,
    )
  }, [ audience ])
  const showMaritalWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.familyStatistics?.married
      && audience.payload.publicSocialAccount.audienceDemographics?.familyStatistics?.single)
  }, [ audience ])
  const showParentalWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.familyStatistics?.parents)
  }, [ audience ])
  const showBasicWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.ageGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.incomeGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.educationLevel.length)
  }, [ audience ])

  const showLocationWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.cityGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.countryGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.stateGroups.length)
  }, [ audience ])

  const showAffinitiesWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.brandAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.interestAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.influenceAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.mediaAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.musicAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.sportAffinities.length
      || audience.payload.publicSocialAccount.audienceDemographics?.hashtagAffinities.length)
  }, [ audience ])

  const showVocationWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.occupationGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.industryGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.employerGroups.length
      || audience.payload.publicSocialAccount.audienceDemographics?.universityGroups.length)
  }, [ audience ])

  const showEthnicityWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.ethnicityGroups.length)
  }, [ audience ])
  const showLanguagesWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.languageGroups.length)
  }, [ audience ])
  const showReligionWidget = useMemo(() => {
    if (audience === "init" || audience === "loading" || API.isError(audience)) return true
    return Boolean(audience.payload.publicSocialAccount.audienceDemographics?.religionGroups.length)
  }, [ audience ])

  return (
    <div className="cp_audience-details_component">
      { toggles.displayFollowersOverTime && (
        <div className="cp_audience-details_component-widget">
          <FollowersChartWrapper />
        </div>
      ) }
      <div className="cp_audience-details_component-row">
        { showGenderWidget && toggles.displayGender && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-1">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Gender") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <GenderChartWrapper />
          </div>
        </div>
        ) }
        { (showMaritalWidget || showParentalWidget) && toggles.displayFamilyStatus && (
        <div className="cp_audience-details_component-widget cp_audience-details_component-column-2">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Family") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            { audience === "init" || audience === "loading"
              ? <LoadingIndicatorCard />
              : (
                <>
                  { showMaritalWidget && <MaritalChartWrapper /> }
                  { showParentalWidget && <FamilyChartWrapper /> }
                </>
              )
            }
          </div>
        </div>
        ) }
      </div>
      { showBasicWidget && (toggles.displayAgeRange || toggles.displayIncomeRange || toggles.displayEducationLevel) && (
      <div className="cp_audience-details_component-widget">
        <h6 className="cp_audience-details_component-widget-title">
          { translate("Basic") }
        </h6>
        <Divider />
        <div className="cp_audience-details_component-charts">
          <BasicChartWrapper
            displayAgeRange={ toggles.displayAgeRange }
            displayIncomeRange={ toggles.displayIncomeRange }
            displayEducationLevel={ toggles.displayEducationLevel }
          />
        </div>
      </div>
      ) }
      <div className="cp_audience-details_component-row">
        { (showEthnicityWidget || showLanguagesWidget || showReligionWidget) && toggles.displayEthnicities && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Ethnicity") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <EthnicityChartWrapper />
          </div>
        </div>
        ) }
        { showLanguagesWidget && toggles.displayLanguages && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Languages") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <LanguagesChartWrapper />
          </div>
        </div>
        ) }
        { showReligionWidget && toggles.displayReligions && (
        <div className="cp_audience-details_component-widget">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Religion") }
          </h6>
          <Divider />
          <div className="cp_audience-details_component-charts">
            <ReligionsChartWrapper />
          </div>
        </div>
        ) }
      </div>
      { showLocationWidget && (toggles.displayCities || toggles.displayCountries || toggles.displayStates) && (
      <div className="cp_audience-details_component-widget">
        <div className="cp_audience-details_component">
          <h6 className="cp_audience-details_component-widget-title">
            { translate("Location") }
          </h6>
          <LocationWrapper
            displayCities={ toggles.displayCities }
            displayCountries={ toggles.displayCountries }
            displayStates={ toggles.displayStates }
          />
        </div>
      </div>
      )
        }

      { showVocationWidget
      && (toggles.displayOccupations || toggles.displayIndustries || toggles.displayEmployers || toggles.displayUniversities) && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <VocationWrapper
              displayOccupations={ toggles.displayOccupations }
              displayIndustries={ toggles.displayIndustries }
              displayEmployers={ toggles.displayEmployers }
              displayUniversities={ toggles.displayUniversities }
            />
          </div>
        </div>
      ) }
      { showAffinitiesWidget && toggles.displayAffinities && (
        <div className="cp_audience-details_component-widget">
          <div className="cp_audience-details_component">
            <AffinitiesWrapper />
          </div>
        </div>
      ) }
    </div>
  )
}
