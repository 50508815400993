import React from "react"
import { useTranslation } from "react-i18next"
import Tabs, { TabLabelProps } from "../Tabs"
import ConfigurationAccountsTab from "./AccountsTab"
import ConfigurationHighlightsTab from "./HighlightsTab"

// eslint-disable-next-line no-shadow
export enum ConfigPreviewTab {
  HIGHLIGHTS = "highlights",
  ACCOUNTS = "accounts"
}

interface ConfigPreviewTabLabel extends TabLabelProps {
  path: ConfigPreviewTab.HIGHLIGHTS | ConfigPreviewTab.ACCOUNTS
}

type ConfigPreviewTabProps = {
  onTabChange: (tab: ConfigPreviewTab) => void
}

export default function ConfigurationPreviewTabs({ onTabChange }: ConfigPreviewTabProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurations" })

  const [ tabIndex, setTabIndex ] = React.useState<number>(0)

  const tabs: ConfigPreviewTabLabel[] = React.useMemo(() => {
    const t: ConfigPreviewTabLabel[] = [
      { label: translate("Highlights"), path: ConfigPreviewTab.HIGHLIGHTS },
      { label: translate("Accounts"), path: ConfigPreviewTab.ACCOUNTS },
    ]
    return t
  }, [])

  const tabContent = React.useMemo(() => {
    const tabPath: ConfigPreviewTab = tabs[tabIndex].path

    switch (tabPath) {
      case ConfigPreviewTab.HIGHLIGHTS:
        onTabChange(ConfigPreviewTab.HIGHLIGHTS)
        return <ConfigurationHighlightsTab />
      case ConfigPreviewTab.ACCOUNTS:
        onTabChange(ConfigPreviewTab.ACCOUNTS)
        return <ConfigurationAccountsTab />
      default:
        return <p>Error or this tab does not exist yet</p>
    }
  }, [ tabIndex ])

  return (
    <div className="cp_lcp-tabs_container">
      <Tabs
        className="cp_lcp-tabs_container-tabs"
        handleChange={ setTabIndex }
        tabs={ tabs }
        defaultValue={ tabIndex }
        controlledValue={ tabIndex }
        controls={ (
          <>
            <p className="cp_lcp-tabs_container-tabs-placeholder">
              Placeholder
            </p>
          </>
        ) }
      />
      <div className="cp_lcp-tabs_container-content">
        { tabContent }
      </div>
    </div>
  )
}
