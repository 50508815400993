import * as React from "react"
import { useTranslation } from "react-i18next"

import "./overview.sass"
import "./modals.sass"

import * as API from "../../util/apiClient"
import { useDispatch, useSelector } from "../../state/hooks"
import LoadingIndicator from "../LoadingIndicator"
import {
  setArchiveModalOpen,
  submitArchiveSuggestionListGroup,
  submitUnarchiveSuggestionListGroup,
} from "../../state/listGroupSlice"
import Modal from "../Modal"
import ListGroupHeaderCard from "../ListGroupHeaderCard"

export default function ListGroupOverview() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListGroupOverview" })
  const {
    listGroup: fetchedListGroup, archiveModalOpen,
  } = useSelector(({ listGroup }) => listGroup)

  const dispatch = useDispatch()

  const [ numberOfLists, numberOfAccounts, numberOfFollowers ] = React.useMemo(() => {
    let totalLists = 0
    let totalAccounts = 0
    let totalFollowers = 0

    if (
      fetchedListGroup === "init"
      || fetchedListGroup === "loading"
      || fetchedListGroup.status === "error"
    ) {
      return [ totalAccounts, totalAccounts, totalFollowers ]
    }

    const {
      suggestionLists,
    } = fetchedListGroup.payload.suggestionListGroupById

    suggestionLists.forEach((l) => {
      totalLists += 1
      totalAccounts += l.numberOfLinkedSocialAccounts
      totalFollowers += parseInt(l.reach, 10)
    })
    return [ totalLists, totalAccounts, totalFollowers ]
  }, [ fetchedListGroup ])

  if (fetchedListGroup === "init" || fetchedListGroup === "loading") {
    return (
      <LoadingIndicator flexWrapperEnabled={ true } size={ 50 } />
    )
  }
  if (API.isError(fetchedListGroup)) {
    return (
      <div className="cp_list-overview_component-error">
        <p>
          { translate("Unable to fetch list group.") }
        </p>
      </div>
    )
  }

  const {
    id,
    archived,
    name,
  } = fetchedListGroup.payload.suggestionListGroupById

  return (
    <>
      <div className="cp_list-group-overview_component">
        <ListGroupHeaderCard
          name={ name }
          numberOfAccounts={ numberOfAccounts }
          numberOfFollowers={ numberOfFollowers }
          numberOfLists={ numberOfLists }
        />
      </div>

      { !archived
        ? (
          <Modal
            className="cp_list-group-overview_modal-archive"
            open={ archiveModalOpen }
            title={ translate("Archive Group") }
            subtitle={ `${ name } ${ translate("and all of its lists will be archived") }` }
            primaryLabel={ translate("Archive") }
            secondaryLabel={ translate("Cancel") }
            primaryAction={ () => dispatch(submitArchiveSuggestionListGroup(id, translate)) }
            secondaryAction={ () => dispatch(setArchiveModalOpen(false)) }
            closeAction={ () => dispatch(setArchiveModalOpen(false)) }
          >
            <p>{ `${ translate("Are you sure you want to archive this group?") }` }</p>
          </Modal>
        )
        : (
          <Modal
            className="cp_list-group-overview_modal-unarchive"
            open={ archiveModalOpen }
            title={ translate("Unarchive Group") }
            primaryLabel={ translate("Unarchive") }
            secondaryLabel={ translate("Cancel") }
            primaryAction={ () => dispatch(submitUnarchiveSuggestionListGroup(id, translate)) }
            secondaryAction={ () => dispatch(setArchiveModalOpen(false)) }
            closeAction={ () => dispatch(setArchiveModalOpen(false)) }
          >
            <p>{ `${ translate("Are you sure you want to unarchive") } ${ name }?` }</p>
          </Modal>
        )
      }
    </>
  )
}
