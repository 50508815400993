import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Comms create template modal slice Interface and Initial State
export interface ModalSearchAISummaryState {
  open: boolean
  summary: string
}

const initialState: ModalSearchAISummaryState = {
  open: false,
  summary: "",
}

export const modalSearchAISummary = createSlice({
  name: "ModalSearchAISummary",
  initialState,
  reducers: {
    openModalSearchAISummary: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      open: true,
      summary: action.payload,
    }),
    closeModalSearchAISummary: (
      state,
    ) => ({
      ...state,
      open: false,
      summary: "",
    }),
  },
})

export const {
  openModalSearchAISummary,
  closeModalSearchAISummary,
} = modalSearchAISummary.actions
export default modalSearchAISummary.reducer
