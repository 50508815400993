import React, { useState } from "react"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined"
import "./recent-media.sass"
import {
  PlayArrowOutlined,
  ScreenshotMonitorOutlined,
  Share,
} from "@mui/icons-material"
import ModalPostDetails, { ModalPostDetailsProps } from "../../ModalPostDetails"
import * as Misc from "../../../util/miscHelper"
import { Props as SocialAvatarProps } from "../../SocialAvatar"
import { prettyPrintDecimal } from "../../../util/miscHelper"
import * as GraphQL from "../../../graphql"

type SelectedPost = Omit<ModalPostDetailsProps, "open" | "closeAction" >

type Props = {
  socialProfile: GraphQL.SocialProfileFragment
}

function MetricPreview(props: {
  network: GraphQL.Network
  postViews: number
  postShares: number
  postScreenshots: number | null | undefined
  postLikes: number
  postComments: number
}) {
  switch (props.network) {
    case GraphQL.Network.Snapchat:
      return (
        <>
          <aside>
            <PlayArrowOutlined />
            { Misc.shorthandNumber(props.postViews) }
          </aside>
          <aside>
            <Share />
            { Misc.shorthandNumber(props.postShares) }
          </aside>
          <aside>
            <ScreenshotMonitorOutlined />
            { Misc.shorthandNumber(props.postScreenshots || 0) }
          </aside>
        </>
      )
    default:
      return (
        <>
          <aside>
            <FavoriteBorderIcon />
            { props.postLikes }
          </aside>
          <aside>
            <ChatBubbleOutlineOutlinedIcon />
            { props.postComments }
          </aside>
        </>
      )
  }
}

export default function RecentMedia({ socialProfile }: Props) {
  const [ selectedPost, selectPost ] = useState<undefined | SelectedPost>()

  const {
    recentMedia,
    userName,
    isNsfw,
    socialAccountStatistics,
    network,
    name,
    isPlaceholder,
    profilePictureUrl,
    personality,
    emails,
    emailsSourcedFromTeam,
  } = socialProfile

  const socialAvatarProps: SocialAvatarProps = {
    username: userName,
    isNSFW: isNsfw,
    followers: socialAccountStatistics.followers,
    fullName: name || "",
    isBlacklisted: personality?.blacklist || false,
    isPlaceholder,
    isUnsubscribed: [
      ...emails,
      ...emailsSourcedFromTeam,
    ].some(({ unsubscribedTags }) => unsubscribedTags.length > 0),
    network,
    profilePictureUrl,
  }

  const posts = recentMedia.map(({
    engagementRate,
    estimatedPostReach,
    id,
    isReel,
    isStory,
    permalink,
    postComments,
    postContent,
    postExits,
    postImpressions,
    postLikes,
    postMedia,
    postPlays,
    postReach,
    postSaves,
    postShares,
    postTapsBackward,
    postTapsForward,
    postViews,
    postedTime,
    screenshots,
    swipeUps,
    highlighted,
    saved,
  }) => (
    <div className="cp_recent-media_component-post" key={ id }>
      <figure className="cp_recent-media_component-figure">
        <button
          onClick={ () => selectPost({
            engagementRate: `${ prettyPrintDecimal(engagementRate) }%`,
            isReel,
            isStory,
            media: postMedia,
            permalink,
            postComments,
            postContent,
            postDate: postedTime,
            postExits: postExits || 0,
            postImpressions: postImpressions || 0,
            postLikes,
            postPlays: postPlays || 0,
            postReach: postReach || 0,
            postReachEstimated: estimatedPostReach?.value || 0,
            postSaves: postSaves || 0,
            postShares,
            postTapsBackward: postTapsBackward || 0,
            postTapsForward: postTapsForward || 0,
            postViews,
            socialAvatarProps,
            postScreenshots: screenshots || 0,
            postSwipeUps: swipeUps || 0,
            postHighlighted: Boolean(highlighted),
            postSaved: Boolean(saved),
          }) }
          type="button"
          className="cp_recent-media_component-figure-btn"
        >
          <p
            className="cp_recent-media_component-figure-image"
            style={ { backgroundImage: `url(${ postMedia[0]?.thumbnailUrl })` } }
          >
            { null }
          </p>
        </button>
        <figcaption className="cp_recent-media_component-figure-metrics">
          <MetricPreview
            network={ network }
            postViews={ postViews }
            postShares={ postShares }
            postScreenshots={ screenshots }
            postLikes={ postLikes }
            postComments={ postComments }
          />
        </figcaption>
      </figure>
    </div>
  ))

  return (
    <div className="cp_recent-media_component">
      { posts }
      <ModalPostDetails
        closeAction={ () => selectPost(undefined) }
        engagementRate={ selectedPost?.engagementRate || "" }
        isReel={ Boolean(selectedPost?.isReel) }
        open={ Boolean(selectedPost) }
        isStory={ Boolean(selectedPost?.isStory) }
        media={ selectedPost?.media || [] }
        permalink={ selectedPost?.permalink || "" }
        postComments={ selectedPost?.postComments || 0 }
        postContent={ selectedPost?.postContent || "" }
        postDate={ selectedPost?.postDate || 0 }
        postExits={ selectedPost?.postExits || 0 }
        postImpressions={ selectedPost?.postImpressions || 0 }
        postLikes={ selectedPost?.postLikes || 0 }
        postPlays={ selectedPost?.postPlays || 0 }
        postReach={ selectedPost?.postReach || 0 }
        postReachEstimated={ selectedPost?.postReachEstimated || 0 }
        postSaves={ selectedPost?.postSaves || 0 }
        postShares={ selectedPost?.postShares || 0 }
        postTapsBackward={ selectedPost?.postTapsBackward || 0 }
        postTapsForward={ selectedPost?.postTapsForward || 0 }
        postScreenshots={ selectedPost?.postScreenshots || 0 }
        postSwipeUps={ selectedPost?.postSwipeUps || 0 }
        postHighlighted={ Boolean(selectedPost?.postHighlighted) }
        postSaved={ Boolean(selectedPost?.postSaved) }
        postViews={ selectedPost?.postViews || 0 }
        socialAvatarProps={ socialAvatarProps }
      />
    </div>
  )
}
