import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import { Stack } from "@mui/material"
import * as API from "../../util/apiClient"
import Button from "../../component/Button"
import PageShell from "../../component/PageShell"
import AISearchFilter from "../../component/AISearchFilter"
import SearchInitiator from "./SearchInitiator"
import SearchResultsAI from "../../component/SearchAIResults"
import ToastController from "../../controller/Toast"
import { fetchAccountSearchResults, fetchContentSearchResults } from "../../state/searchAISlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"
import { SearchAIType } from "../../util/types"
import Tooltip from "../../component/Tooltip"

export default function SearchAI(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.SearchAI" })
  const dispatch = useDispatch()
  const { user } = useSelector(({ user: userSlice }) => userSlice)
  const { searchAIInput } = useSelector(({ searchAI }) => searchAI)

  // User state is loading... show minimal page frame without results...
  if (!API.isSuccess(user)) {
    return (
      <div className="cp_page_search-ai">
        <ToastController />
        <PageShell
          title={ translate("Search") }
          showTopNav={ false }
        />
      </div>
    )
  }

  return (
    <div className="cp_page_search-ai">
      <ToastController />
      <PageShell title={ translate("Search") } />
      <div className="cp_page_search-ai-container">
        <section className="cp_page_search-ai-form">
          <AISearchFilter />
          <aside className="cp_page_search-ai-button">
            <Tooltip
              disableHoverListener={ searchAIInput.socialNetworks.length > 0 }
              title={ translate("Please select a network to run search.") }
            >
              <Stack width="100%" mx="1rem">
                <Button
                  id="cp_page_search-ai-button"
                  fullWidth={ true }
                  isEnabled={ searchAIInput.socialNetworks.length > 0 }
                  isPrimary={ true }
                  label={ translate("Search") }
                  onClick={ () => {
                    if (searchAIInput.type === SearchAIType.Accounts) dispatch(fetchAccountSearchResults())
                    if (searchAIInput.type === SearchAIType.Content) dispatch(fetchContentSearchResults())
                  } }
                />
              </Stack>
            </Tooltip>
          </aside>
        </section>
        <section className="cp_page_search-ai-results">
          <SearchResultsAI />

          { /* User data is loaded... initiate search! */ }
          <SearchInitiator />
        </section>
      </div>
    </div>
  )
}
