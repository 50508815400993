import React, { useState, useEffect } from "react"
import daysjs from "dayjs"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded"
import WebAssetIcon from "@mui/icons-material/WebAsset"
import { Collapse, MenuItem } from "@mui/material"
import {
  BlockOutlined,
  LockOutlined,
  PendingActions,
} from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import * as MiscHelper from "../../util/miscHelper"
import Avatar from "../Avatar"
import Button from "../Button"
import Divider from "../Divider"
import IconButton from "../IconButton"
import LoadingIndicator from "../LoadingIndicator"
import ModalContactInformation from "./ModalContactInformation"
import ModalScoreBreakDown from "../ModalScoreBreakDown"
import Networks from "./Networks"
import Select from "../Select"
import StatusDot from "../StatusDot"
import Tooltip from "../Tooltip"
import { Scope, ScoreBreakDown } from "../../util/types"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import { scoreModalTypes } from "../../util/constant"
import { setPrimaryEmail, fetchSocialProfile } from "../../state/socialProfileSlice"
import { updateProfile } from "../../state/profileSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export function ProfileOverview(): React.JSX.Element {
  const [ collapsed, setCollapsed ] = useState(false)
  const [ openContactInformation, setOpenContactInformation ] = useState(false)
  const [ isLoading, setIsLoading ] = React.useState(false)
  const [ engagementScoreModal, setEngagementScoreModal ] = useState(false)
  const socialProfile = useSelector(({ socialProfile: slice }) => slice.profile)
  const { scopes } = useSelector(({ user }) => user)

  const dispatch = useDispatch()

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ProfileOverview" })

  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })

  useEffect(() => {
    if (engagementScoreModal === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ engagementScoreModal ])

  const showContactInfo = React.useMemo(() => scopes.includes(Scope.SOCIAL_ACCOUNT_CONTACT_INFO), [ scopes ])

  if (socialProfile === "loading" || socialProfile === "init") {
    return (
      <div className="cp_profile-overview_component_loading">
        <LoadingIndicator size={ 30 } />
      </div>
    )
  }

  if (socialProfile.status === "error") {
    return (
      <p>
        { translate("Unable to load social profile") }
      </p>
    )
  }

  const {
    id,
    bio,
    engagementScore,
    emails,
    emailsSourcedFromTeam,
    personality,
    profilePictureUrl,
    primaryEmail,
    socialAccountStatistics,
    userName,
    websiteUrl,
    isPlaceholder,
  } = socialProfile.payload.socialAccount

  const activeNetworks = personality?.socialAccounts || []
  const contact = (personality?.contacts) ? personality.contacts[(personality.contacts.length - 1)] : undefined

  const contactEmails = contact?.emails.map(({ email, primaryEmail: isPrimaryEmail }) => ({
    address: email,
    isPrimaryEmail,
  })) || []

  const computedPrimaryEmail = contactEmails.find(({ isPrimaryEmail }) => isPrimaryEmail) || primaryEmail

  const emailsFromTeam = emailsSourcedFromTeam.map((email) => {
    const ea = { address: email.address.toLowerCase() }
    return ea
  })
  const addresses = emailsFromTeam.map((email) => email.address)
  contactEmails.forEach((email) => {
    if (!addresses.includes(email.address.toLowerCase())) emailsFromTeam.push({ address: email.address.toLowerCase() })
  })
  const allEmails = [ ...emails, ...emailsFromTeam ]

  const emailOptions = allEmails.map(
    ({ address }) => (
      <MenuItem
        disabled={ address === computedPrimaryEmail?.address }
        key={ address }
        value={ address }
      >
        { address }
      </MenuItem>
    ),
  )

  const updateProfileEmail = async (primaryEmailIndex: number) => {
    if (!personality) {
      return
    }

    const socialAccountsProfileInput = personality.socialAccounts
      .map((account) => ({
        foreignUserId: account.id,
        id: account.id,
        network: account.network,
        userName: account.userName,
      }))

    const updatedContacts:GraphQL.PersonalityContactInput[] = [
      {
        personalityContactId: contact?.id,
        firstName: contact?.firstName || undefined,
        lastName: contact?.lastName || undefined,
        emails: (contact?.emails || [])
          .filter(({ email }) => email.trim() !== "")
          .map(({ email }, i) => ({ address: email, primary: i === primaryEmailIndex })),
      },
    ]

    const updatedProfile: GraphQL.EditProfileMutationVariables = {
      id: personality?.id,
      name: personality.name,
      verticalIds: personality.verticals.map(({ id: verticalId }) => verticalId),
      socialAccounts: socialAccountsProfileInput,
      avatarUrl: personality.avatar?.url.address,
      vip: personality.vip,
      blacklist: personality.blacklist,
      blacklistReason: personality.blacklistReason,
      contacts: updatedContacts,
    }
    // Update the profile
    const updated = await dispatch(updateProfile({
      vars: updatedProfile,
      changedEmails: [],
    }))
    if (updated) {
      await dispatch(fetchSocialProfile(id))
    }
  }

  const setPrimaryEmailHandler = async (email: string) => {
    setIsLoading(true)
    const profileMailIndex = contactEmails.findIndex(({ address }) => address === email)
    if (profileMailIndex !== -1) {
      await updateProfileEmail(profileMailIndex)
    } else {
      await dispatch(setPrimaryEmail({
        networkAccountId: id,
        email,
      }))
    }
    setIsLoading(false)
  }

  const handleEngagmentScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string) => {
    if (scoreBreakDown.scores.some((item) => item === null)) return
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType))
    setEngagementScoreModal(true)
  }

  const blocklistText = socialProfile
    .payload.socialAccount.personality?.blacklistReason
    || translate("Blocklisted")

  return (
    <div className="cp_profile-overview_component">
      <div className="cp_profile-overview_component-avatar">
        <div className="cp_profile-overview_component-avatar-outer-wrapper">
          <div className="cp_profile-overview_component-avatar-wrapper">
            { !isPlaceholder
              ? <Avatar src={ profilePictureUrl } className="cp_profile-overview_component-avatar-image" />
              : <PendingActions className="cp_profile-overview-placeholder-icon" /> }
            <p className="cp_profile-overview_component-avatar-name">
              { contact?.firstName && contact?.lastName
                ? `${ contact.firstName } ${ contact.lastName }`
                : userName }
            </p>
            <p className="cp_profile-overview_component-avatar-email">
              { computedPrimaryEmail?.address || translate("No Primary Email") }
            </p>
          </div>
        </div>
      </div>
      { activeNetworks.length > 0 && (
        <>
          <Networks
            activeNetworks={ activeNetworks }
            headingLabel={ translate("Available Accounts") }
          />
          <Divider />
        </>
      ) }
      <div className="cp_profile-overview_component-scores-wrapper">
        <div>
          <p className="label_small-caps-semibold cp_profile-overview_component-scores-heading">
            { translate("FOLLOWERS") }
          </p>
          <p className="label_large-caps-bold scores-value">
            { MiscHelper.shorthandNumber(
              socialAccountStatistics.followers,
            ) }
          </p>
        </div>
        <div>
          <p className="label_small-caps-semibold cp_profile-overview_component-scores-heading">
            { translate("ENG. SCORE") }
          </p>
          { engagementScore && (
            <Button
              className="scores-value-button"
              label={ Math.round(engagementScore.value) }
              isTextButton={ true }
              onClick={ () => {
                const { socialAccount } = socialProfile.payload
                const scoreBreakDown = {
                  socialAccount: socialAccount as GraphQL.SocialAccount,
                  scores: [ engagementScore as GraphQL.Score ],
                }
                handleEngagmentScoreModal(scoreBreakDown, scoreModalTypes.ENGAGEMENT)
              } }
            />
          ) }
        </div>
        <div>
          <p className="label_small-caps-semibold cp_profile-overview_component-scores-heading">
            { translate("ENG. RATE") }
          </p>
          <p className="label_large-caps-bold scores-value">
            { `${ MiscHelper.prettyPrintDecimal(
              socialAccountStatistics.engagementRate,
            ) }%` }
          </p>
        </div>
      </div>
      <Divider />
      <div className="cp_profile-overview_component-social-account-wrapper">
        <aside>
          <p className="account-handle">
            { `@${ socialProfile.payload.socialAccount.userName }` }
          </p>
        </aside>
        { socialProfile.payload.socialAccount.oauthed && (
          <aside>
            <p className="icon-text">
              <LockOutlined />
              { translate("OAuthed") }
            </p>
          </aside>
        ) }
        { socialProfile.payload.socialAccount.personality?.blacklist && (
          <aside>
            <Tooltip arrow={ true } title={ blocklistText }>
              <p className="icon-text">
                <BlockOutlined />
                { blocklistText }
              </p>
            </Tooltip>
          </aside>
        ) }
      </div>
      <Divider />
      <div className="cp_profile-overview_component-bio-wrapper">
        <p className="label_small-caps-semibold cp_profile-overview_component-bio-heading">
          { translate("Account Biography") }
        </p>
        <p className="label_small-caps-semibold cp_profile-overview_component-bio-body">
          { bio || translate("Biography Not Available") }
        </p>
      </div>
      { showContactInfo ? (
        <>
          <Divider />
          <div className="cp_profile-overview_component-contact-wrapper">
            <p className="label_small-caps-semibold cp_profile-overview_component-contact-heading">
              { translate("Contact Information") }
              <IconButton
                className="cp_component_contact-information-button"
                onClick={ () => setCollapsed(!collapsed) }
              >
                { collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
              </IconButton>
            </p>
            <Collapse in={ !collapsed }>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <WebAssetIcon />
                { translate("Websites from Public Profile") }
              </p>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-website">
                { websiteUrl }
              </p>
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <MailOutlineRoundedIcon />
                { translate("Emails From Public Profile") }
              </p>
              { emails.map(({ address }) => (
                <p key={ address } className="label_small-caps-semibold cp_profile-overview_component-contact-email">
                  { address }
                </p>
              )) }
              <p className="label_small-caps-semibold cp_profile-overview_component-contact-title">
                <MailOutlineRoundedIcon />
                { translate("Emails Added By Your Team") }
                <Button
                  className="cp_btn_component_highlight"
                  onClick={ () => setOpenContactInformation(true) }
                  type="button"
                  variant="text"
                  isPrimary={ false }
                  isEnabled={ true }
                  label={ translate("Edit") }
                  size="small"
                />
              </p>
              { emailsFromTeam.map(({ address }) => (
                <div key={ address } className="cp_profile-overview_component-contact-email-wrapper">
                  <p className="label_small-caps-semibold cp_profile-overview_component-contact-email">
                    { address }
                  </p>
                  <p className="label_small-caps-semibold cp_profile-overview_component-email_status">
                    { computedPrimaryEmail?.address === address && <StatusDot status="support" size="sm" /> }
                    { computedPrimaryEmail?.address === address && translate("PRIMARY") }
                  </p>
                </div>
              )) }
              <p className="label_small-caps-semibold cp_profile-overview_component-email-select">
                { translate("Choose a primary Email Address") }
              </p>
              <Select
                disabled={ isLoading }
                value={ computedPrimaryEmail?.address }
                fullWidth={ true }
                id="cp_profile-overview_select-email"
                label=""
                labelId=""
                menuItems={ emailOptions }
                onChange={ (e) => setPrimaryEmailHandler(e.target.value as string) }
                IconComponent={ isLoading ? LoadingIndicator : undefined }
              />
            </Collapse>
          </div>
        </>
      ) : null }
      <Divider />
      <div className="cp_profile-overview_component-created-updated-wrapper">
        <p>
          { translate("Account Added") }
          { daysjs
            .unix(socialProfile.payload.socialAccount.created)
            .format(Constant.INPUT_DATE_FORMAT)
          }
        </p>
        { socialProfile.payload.socialAccount.lastScannedDate && (
          <p>
            { translate("Last Updated") }
            { daysjs
              .unix(socialProfile.payload.socialAccount.lastScannedDate)
              .format(Constant.INPUT_DATE_FORMAT)
            }
          </p>
        ) }
        <p>
          { translate("Account Followers") }
          {
            translateCommon(
              "intlNumber",
              { val: socialAccountStatistics.followers },
            )
          }
        </p>
      </div>
      <ModalContactInformation
        open={ openContactInformation }
        closeAction={ () => setOpenContactInformation(false) }
        secondaryAction={ () => setOpenContactInformation(false) }
      />
      <ModalScoreBreakDown isModalScoreOpen={ engagementScoreModal } closeModal={ () => setEngagementScoreModal(false) } />
    </div>
  )
}

export default ProfileOverview
