import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import { FormControlLabel, FormGroup } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import daysjs, { Dayjs } from "dayjs"
import Modal from "../Modal"
import * as Constant from "../../util/constant"
import { useDispatch, useSelector } from "../../state/hooks"
import { RootState } from "../../state/store"
import {
  GroupAccountFilters,
  setSelectedlFilters,
  setModalOpenState as setOpen,
  resetFilterState as resetFilters,
} from "../../state/ModalGroupAccountsFilterSlice"
import Divider from "../Divider"
import * as GraphQL from "../../graphql"
import NetworkIconButton from "../NetworkButton"
import DatePicker from "../DatePicker"
import * as API from "../../util/apiClient"
import Checkbox from "../Checkbox"
import Button from "../Button"
import Pill from "../Pill"

type FilterType = "Network" | "DateAdded" | "ListStatus" | "LabelStatus" | "ListCategory" | "EmailStatus"

type GaFilterType = {
  key: string
  name: string
  type: FilterType
}

export default function GroupAccountsFilterModal() {
  // common use variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalGroupAccountsFilter" })
  const dispatch = useDispatch()

  // Selectors
  const {
    open,
    selectedFilters,
  } = useSelector((root: RootState) => root.groupAccountsFilters)
  const { communicationGroup, labelColors: labels } = useSelector((root: RootState) => root.communicationsPage)

  // Local state variables
  const [ filters, setFilters ] = useState<GaFilterType[]>([])
  const [ dateAdded, setDateAdded ] = useState<Dayjs | null>()
  const [ networkEnabled, setNetworkEnabled ] = useState<Map<GraphQL.Network, boolean>>(new Map([
    [ GraphQL.Network.Facebook, false ],
    [ GraphQL.Network.Instagram, false ],
    [ GraphQL.Network.Snapchat, false ],
    [ GraphQL.Network.Tiktok, false ],
    [ GraphQL.Network.Youtube, false ],
  ]))

  // ******* React Hooks *******
  useEffect(() => {
    // Create variable to hold filters
    const updatedFilters: GaFilterType[] = []

    // Set the networks
    selectedFilters.networks.forEach((network) => updatedFilters.push({
      key: `filter-${ network.toLowerCase() }`,
      name: network,
      type: "Network",
    }))

    // Set the date added
    if (selectedFilters.dateAdded) {
      updatedFilters.push({
        key: "filter-date",
        name: selectedFilters.dateAdded,
        type: "DateAdded",
      })
    }

    // Set the statuses
    selectedFilters.listStatus.forEach((status) => updatedFilters.push({
      key: `filter-${ status }`,
      name: status,
      type: "ListStatus",
    }))
    selectedFilters.labelStatus.forEach((status) => updatedFilters.push({
      key: `filter-${ status }`,
      name: status,
      type: "LabelStatus",
    }))
    selectedFilters.listCategories.forEach((status) => updatedFilters.push({
      key: `filter-${ status }`,
      name: status,
      type: "ListCategory",
    }))
    selectedFilters.emailStatus.forEach((status) => updatedFilters.push({
      key: `filter-${ status }`,
      name: status,
      type: "EmailStatus",
    }))

    // Set the date added
    const date = filters.find((f) => f.type === "DateAdded")

    // Update the filters
    if (date) setDateAdded(daysjs(date.name, Constant.INPUT_DATE_FORMAT))
    setFilters(updatedFilters)
  }, [ selectedFilters ])

  // ******* Component Functions *******
  const onClose = () => {
    // Close the dialog box
    dispatch(setOpen(false))
  }

  const getNetwork = (name: string): GraphQL.Network => {
    let nw: GraphQL.Network
    if (name === GraphQL.Network.Facebook) nw = GraphQL.Network.Facebook
    else if (name === GraphQL.Network.Instagram) nw = GraphQL.Network.Instagram
    else if (name === GraphQL.Network.Snapchat) nw = GraphQL.Network.Snapchat
    else if (name === GraphQL.Network.Tiktok) nw = GraphQL.Network.Tiktok
    else nw = GraphQL.Network.Youtube

    // Return the network
    return nw
  }

  const handleUpdate = () => {
    if (filters.length > 0) {
      // Update the state for filters
      const newFilters: GroupAccountFilters = {
        networks: filters.filter((f) => f.type === "Network").map((f) => getNetwork(f.name)),
        dateAdded: (filters.filter((f) => f.type === "DateAdded").length > 0)
          ? filters.find((f) => f.type === "DateAdded")?.name : undefined,
        listStatus: filters.filter((f) => f.type === "ListStatus").map((f) => f.name as ("Approved" | "Not Approved")),
        labelStatus: filters.filter((f) => f.type === "LabelStatus").map((f) => f.name),
        listCategories: filters.filter((f) => f.type === "ListCategory").map((f) => f.name),
        emailStatus: filters.filter((f) => f.type === "EmailStatus").map((f) => f.name as ("No Email" | "Unsubscribed")),
      }

      // Update the state filters
      dispatch(setSelectedlFilters(newFilters))
    } else dispatch(resetFilters())

    // Update state filters and Close the dialog box
    dispatch(setOpen(false))
  }

  const toggleFilter = (type: FilterType, value: string) => {
    // Find the filter, if it exists
    const filter = filters.find((f) => f.type === type && f.name === value)

    // Check to see if it was found
    if (!filter) {
      // Add the filter
      setFilters([ ...filters, {
        key: `filter-${ value.toLowerCase() }`,
        name: value,
        type,
      } ])
    } else if (filters.length > 1) {
      // Remove network filter
      const index = filters.findIndex((f) => f.type === type && f.name === value)
      const nfilters = [ ...filters ]
      nfilters.splice(index, 1)

      // Set the filters state
      setFilters(nfilters)
    } else setFilters([])

    // Check if network
    if (type === "Network") {
      const map: Map<GraphQL.Network, boolean> = new Map()
      Object.keys(GraphQL.Network).forEach((network) => {
        if (network.toUpperCase() === value) {
          map.set(getNetwork(value), !networkEnabled.get(getNetwork(network.toUpperCase())) || false)
        } else map.set(getNetwork(network.toUpperCase()), networkEnabled.get(getNetwork(network.toUpperCase())) || false)
      })
      setNetworkEnabled(map)
    }
  }

  const acceptDateFilter = (date: Dayjs | null) => {
    if (date) addDateFilter(date.format(Constant.INPUT_DATE_FORMAT))
  }

  const addDateFilter = (date: string) => {
    // See if filter is set
    const filter = filters.find((f) => f.type === "DateAdded")

    if (date.length === 10) {
      // Set or update
      if (!filter) {
        // Update the filters to add date
        setFilters([ ...filters, {
          key: "filter-date",
          name: date,
          type: "DateAdded",
        } ])
      } else {
        // Remove old date
        const index = filters.findIndex((f) => f.type === "DateAdded")
        const nfilters = [ ...filters ]
        nfilters.splice(index, 1)

        // Add updated date
        setFilters([ ...nfilters, {
          key: "filter-date",
          name: date,
          type: "DateAdded",
        } ])
      }
    } else if (filter) {
      // Remove old date
      const index = filters.findIndex((f) => f.type === "DateAdded")
      const nfilters = [ ...filters ]
      nfilters.splice(index, 1)

      // Update the filter state
      setDateAdded(null)
      setFilters(nfilters)
    }
  }

  const clearAllFilters = () => {
    // Clear all the filters on the screen
    setDateAdded(null)
    setFilters([])
  }

  const deleteFilter = (filter: GaFilterType) => {
    // Remove the filter
    if (filter.type === "DateAdded") addDateFilter("")
    else toggleFilter(filter.type, filter.name)
  }

  // ***********  General data prep ***********
  // Pull out all communication group information
  let inCampaignMode: boolean = false
  const categories: string[] = []
  if (API.isSuccess(communicationGroup)) {
    const { campaign } = communicationGroup.payload.communicationGroup
    inCampaignMode = (campaign !== null)
  }

  return (
    <Modal
      id="group-accounts-filter-modal"
      open={ open }
      title={ translate("Apply Filters") }
      subtitle={ translate("Select the filters that you would like to apply.") }
      closeAction={ onClose }
      primaryLabel={ translate("APPLY") }
      primaryAction={ handleUpdate }
      disabled={ false }
      hasSecondaryButton={ false }
    >
      <div id="group-accounts-filter_contents" className="cp-component_ga-filter-content">
        <div id="ga-filters" className="cp-component_ga-filter-content_filters">
          <p className="filter-header label_small-caps-bold">{ translate("Network") }</p>
          <NetworkIconButton
            id="facebook-icon-btn"
            additionalClasses="cp-component_ga-filter-content_filters_network-icon-btn"
            isActive={ networkEnabled.get(GraphQL.Network.Facebook) || false }
            network={ GraphQL.Network.Facebook }
            onClick={ () => toggleFilter("Network", GraphQL.Network.Facebook) }
          />
          <NetworkIconButton
            id="instagram-icon-btn"
            additionalClasses="cp-component_ga-filter-content_filters_network-icon-btn"
            isActive={ networkEnabled.get(GraphQL.Network.Instagram) || false }
            network={ GraphQL.Network.Instagram }
            onClick={ () => toggleFilter("Network", GraphQL.Network.Instagram) }
          />
          <NetworkIconButton
            id="snapchat-icon-btn"
            additionalClasses="cp-component_ga-filter-content_filters_network-icon-btn"
            isActive={ networkEnabled.get(GraphQL.Network.Snapchat) || false }
            network={ GraphQL.Network.Snapchat }
            onClick={ () => toggleFilter("Network", GraphQL.Network.Snapchat) }
          />
          <NetworkIconButton
            id="tiktok-icon-btn"
            additionalClasses="cp-component_ga-filter-content_filters_network-icon-btn"
            isActive={ networkEnabled.get(GraphQL.Network.Tiktok) || false }
            network={ GraphQL.Network.Tiktok }
            onClick={ () => toggleFilter("Network", GraphQL.Network.Tiktok) }
          />
          <NetworkIconButton
            id="youtube-icon-btn"
            additionalClasses="cp-component_ga-filter-content_filters_network-icon-btn"
            isActive={ networkEnabled.get(GraphQL.Network.Youtube) || false }
            network={ GraphQL.Network.Youtube }
            onClick={ () => toggleFilter("Network", GraphQL.Network.Youtube) }
          />
          <p className="filter-header label_small-caps-bold">{ translate("Date Added") }</p>
          <DatePicker
            className="cp-component_ga-filter-content_filters_date-picker"
            onAccept={ acceptDateFilter }
            onDateChange={ addDateFilter }
            value={ dateAdded }
          />
          { (inCampaignMode) && (
            <>
              <p className="filter-header label_small-caps-bold">{ translate("List Status") }</p>
              <FormGroup>
                <FormControlLabel
                  className="form-control-label"
                  label={ translate("Approved") }
                  control={ (
                    <Checkbox
                      id="list-status-approved-cb"
                      size="small"
                      onClick={ () => toggleFilter("ListStatus", "Approved") }
                      checked={
                        filters.find((filter) => filter.type === "ListStatus" && filter.name === "Approved") !== undefined
                      }
                    />
                  ) }
                />
                <FormControlLabel
                  className="form-control-label"
                  label={ translate("Not Approved") }
                  control={ (
                    <Checkbox
                      id="list-status-not-approved-cb"
                      size="small"
                      onClick={ () => toggleFilter("ListStatus", "Not Approved") }
                      checked={
                        filters.find((filter) => filter.type === "ListStatus" && filter.name === "Not Approved") !== undefined
                      }
                    />
                  ) }
                />
              </FormGroup>
            </>
          ) }
          <p className="filter-header label_small-caps-bold">{ translate("Label Status") }</p>
          <FormGroup>
            <FormControlLabel
              className="form-control-label"
              label={ translate("Has Label") }
              control={ (
                <Checkbox
                  id="label-status-has-label-cb"
                  size="small"
                  onClick={ () => toggleFilter("LabelStatus", "Has Label") }
                  checked={
                    filters.find((filter) => filter.type === "LabelStatus" && filter.name === "Has Label") !== undefined
                  }
                />
              ) }
            />
            <FormControlLabel
              className="form-control-label"
              label={ translate("No Label") }
              control={ (
                <Checkbox
                  id="label-status-no-label-cb"
                  size="small"
                  onClick={ () => toggleFilter("LabelStatus", "No Label") }
                  checked={
                    filters.find((filter) => filter.type === "LabelStatus" && filter.name === "No Label") !== undefined
                  }
                />
              ) }
            />
            { labels.filter((label) => label.type === "Default").map((label) => (
              <FormControlLabel
                className="form-control-label default-labels"
                label={ translate(label.label) }
                control={ (
                  <Checkbox
                    id={ `label-status-${ label.label.toLowerCase().replaceAll(" ", "-") }-cb` }
                    size="small"
                    onClick={ () => toggleFilter("LabelStatus", label.label) }
                    checked={
                      filters.find((filter) => filter.type === "LabelStatus" && filter.name === label.label) !== undefined
                    }
                  />
                ) }
              />
            )) }
            { labels.filter((label) => label.type === "Custom").map((label) => (
              <FormControlLabel
                className="form-control-label"
                label={ label.label }
                control={ (
                  <Checkbox
                    id={ `label-status-${ label.label.toLowerCase().replaceAll(" ", "-") }-cb` }
                    size="small"
                    onClick={ () => toggleFilter("LabelStatus", label.label) }
                    checked={
                      filters.find((filter) => filter.type === "LabelStatus" && filter.name === label.label) !== undefined
                    }
                  />
                ) }
              />
            )) }
          </FormGroup>
          { (categories.length > 0) && (
            <>
              <p className="filter-header label_small-caps-bold">{ translate("List Category") }</p>
              <FormGroup>
                { categories.map((category) => (
                  <FormControlLabel
                    className="form-control-label"
                    label={ category }
                    control={ (
                      <Checkbox
                        id={ `list-categories-${ category.toLowerCase().replaceAll(" ", "-") }-cb` }
                        size="small"
                        onClick={ () => toggleFilter("ListCategory", category) }
                        checked={
                          filters.find((f) => f.type === "ListCategory" && f.name === category) !== undefined
                        }
                      />
                    ) }
                  />
                )) }
              </FormGroup>
            </>
          ) }
          <p className="filter-header label_small-caps-bold">{ translate("Email Status") }</p>
          <FormGroup>
            <FormControlLabel
              className="form-control-label"
              label={ translate("No Email") }
              control={ (
                <Checkbox
                  id="email-status-no-email-cb"
                  size="small"
                  onClick={ () => toggleFilter("EmailStatus", "No Email") }
                  checked={
                    filters.find((f) => f.type === "EmailStatus" && f.name === "No Email") !== undefined
                  }
                />
              ) }
            />
            <FormControlLabel
              className="form-control-label"
              label={ translate("Unsubscribed") }
              control={ (
                <Checkbox
                  id="email-status-unsubscribed-cb"
                  size="small"
                  onClick={ () => toggleFilter("EmailStatus", "Unsubscribed") }
                  checked={
                    filters.find((f) => f.type === "EmailStatus" && f.name === "Unsubscribed") !== undefined
                  }
                />
              ) }
            />
          </FormGroup>
        </div>
        <div id="ga-selected-filters" className="cp-component_ga-filter-content_selected-filters">
          <div className="selected-filters-title-section">
            <p className="selected-filters-title-section_title label_large">Filters</p>
            { (filters.length > 0) && (
              <Button
                id="clear-all-filters-btn"
                isTextButton={ true }
                label={ translate("Clear All") }
                onClick={ clearAllFilters }
              />
            ) }
          </div>
          <Divider className="applied-filter-divider" />
          { (filters.length === 0) && (
            <p className="body_small">No filters have been applied.</p>
          ) }
          { (filters.length > 0) && filters.map((filter) => (
            <Pill
              id={ filter.key }
              className="filter-chip"
              key={ filter.key }
              label={ filter.name.toLowerCase() }
              deleteIcon={ <CloseIcon /> }
              onDelete={ () => deleteFilter(filter) }
            />
          )) }
        </div>
      </div>
    </Modal>
  )
}
