import React, {
  JSX,
  useEffect,
  useMemo,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { useDispatch, useSelector } from "../../state/hooks"
import Input from "../Input"
import Modal from "../Modal"
import Select from "../Select"
import MenuItem from "../MenuItem"
import Checkbox from "../Checkbox"
import Divider from "../Divider"
import CommGroupEmailsAutocomplete, { NetworkInfo } from "./CommGroupEmailsAutocomplete"
import { pushToast } from "../../state/toastSlice"
import { attachCommFile } from "../../util/miscHelper"
import {
  fetchCommTemplates,
  setModalCreateMessageOpen,
  createCommSubmission,
  resetCommSubmission,
  saveCommunicationAsDraft,
  resetCommSaveAsDraft,
  createBatchConversation,
} from "../../state/modalCreateMessageSlice"
import RichTextEditor from "../RichTextEditor"

import "./style.sass"
import MessageBar from "../MessageBar"
import {
  CommunicationDraftData,
  CommunicationDraftReciptiant,
  Toast,
  UploadedFile,
} from "../../util/types"
import { fetchDraftsList } from "../../state/commEmailListView"

export default function ModalCreateMessage(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCreateMessage" })
  const { selectedRecipients, isBatch } = useSelector(({ ModalCreateMessageSlice }) => ModalCreateMessageSlice)
  const { commGroupID } = useParams()
  const [ selectedTemplate, setSelectedTemplate ] = React.useState("")
  const [ isContentUploadLink, setIsContentUploadLink ] = React.useState(false)
  const [ subject, setSubject ] = React.useState("")
  const [ commTo, setCommTo ] = React.useState<NetworkInfo[] | []>(selectedRecipients)
  const [ commCCEmails, setCommCCEmails ] = React.useState<NetworkInfo[] | []>([])
  const [ showCommCC, setShowCommCC ] = React.useState(false)
  const [ editorContent, setEditorContent ] = React.useState("")
  const [ attachments, setAttachments ] = React.useState<GraphQL.ConversationAttachmentInput[]>([])
  const [ uploadedfiles, setUploadedFiles ] = React.useState<UploadedFile[]>([])

  const dispatch = useDispatch()
  const {
    modalCreateMessageOpen, commTemplates, commSubmisssion, commSaveAsDraft,
  } = useSelector(({ ModalCreateMessageSlice }) => ModalCreateMessageSlice)

  useEffect(() => {
    if (!API.isSuccess(commTemplates)) {
      dispatch(fetchCommTemplates())
    }

    // NOTE: Do not add commTemplates as a dependency, as it may call the API
    // many times. Error states from GraphQL will cause an endless stream of
    // API calls.
  }, [])

  useEffect(() => {
    if (selectedRecipients.length > 0) setCommTo(selectedRecipients)
    else setCommTo([])
  }, [ selectedRecipients ])

  const clearForm = () => {
    setSubject("")
    setCommTo(selectedRecipients)
    setCommCCEmails([])
    setEditorContent("")
    setIsContentUploadLink(false)
    setAttachments([])
    setUploadedFiles([])
    setSelectedTemplate("")
  }

  useEffect(() => {
    let toast: Toast
    if (API.isSuccess(commSubmisssion)) {
      toast = {
        type: "success",
        message: translate("Success! Message sent."),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetCommSubmission())
      dispatch(setModalCreateMessageOpen(false))
    } else if (API.isError(commSubmisssion)) {
      toast = {
        type: "error",
        message: translate("Failed to create message. try again later."),
      }
      dispatch(pushToast(toast))
    }
  }, [ commSubmisssion ])

  useEffect(() => {
    let toast: Toast
    if (API.isSuccess(commSaveAsDraft)) {
      toast = {
        type: "success",
        message: translate("Success! Message saved as draft."),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetCommSaveAsDraft())
      dispatch(setModalCreateMessageOpen(false))
    } else if (API.isError(commSaveAsDraft)) {
      toast = {
        type: "error",
        message: translate("Failed to save message to draft. try again later."),
      }
      dispatch(pushToast(toast))
    }
  }, [ commSaveAsDraft ])

  const handleCancelAction = () => {
    clearForm()
    dispatch(setModalCreateMessageOpen(false))
  }

  const getRecipients = () => commTo.map((recipient) => ({
    ccEmailAddresses: commCCEmails.map((cc) => cc.email),
    socialAccountId: recipient.socialAccountId,
    toEmailAddress: recipient.email,
  }))

  const handleCreateMessageSubmission = () => {
    if (commGroupID) {
      const messageInfo = {
        subject,
        enableUploadLink: isContentUploadLink,
        attachments,
        body: editorContent,
        communicationGroupId: commGroupID,
        recipients: getRecipients(),
      }

      // Send single vs send batch
      if (!isBatch) dispatch(createCommSubmission(messageInfo))
      else {
        dispatch(createBatchConversation({
          vars: messageInfo,
          onSuccess: () => {
            const toast: Toast = {
              type: "success",
              message: translate("Success! Message sent."),
            }
            dispatch(pushToast(toast))
            clearForm()
            dispatch(setModalCreateMessageOpen(false))
          },
          onError: () => {
            const toast: Toast = {
              type: "error",
              message: translate("Failed to create message. try again later."),
            }
            dispatch(pushToast(toast))
          },
        }))
      }
    }
  }

  const handleSaveAsDraftSubmission = async () => {
    // Ensure we have comm group id
    if (commGroupID) {
      commTo.map((to) => {
        const recipient: CommunicationDraftReciptiant = {
          email: to.email,
          unsubscribed: false,
          socialAccountId: to.socialAccountId,
          socialAccountUsername: to.accountName,
        }
        return recipient
      })
      // Gather data from the rich editor
      const data: CommunicationDraftData = {
        mode: (isBatch) ? GraphQL.CommunicationGroupDraftType.Batch : GraphQL.CommunicationGroupDraftType.Single,
        toList: commTo.map((to) => {
          const recipient: CommunicationDraftReciptiant = {
            email: to.email,
            unsubscribed: false,
            socialAccountId: to.socialAccountId,
            socialAccountUsername: to.accountName,
          }
          return recipient
        }),
        ccList: commCCEmails.map((cc) => {
          const recipient: CommunicationDraftReciptiant = {
            email: cc.email,
            unsubscribed: false,
            socialAccountId: cc.socialAccountId,
            socialAccountUsername: cc.accountName,
          }
          return recipient
        }),
        enableUploadLink: isContentUploadLink,
        subject,
        template: selectedTemplate,
        richTextEditorState: {
          attachmentHistory: [],
          attachementHistoryIndex: 0,
          attachments,
          uploadedfiles,
          content: editorContent,
        },
      }
      const vars: GraphQL.CreateCommunicationsDraftMutationVariables = {
        communicationGroupId: commGroupID,
        type: (isBatch) ? GraphQL.CommunicationGroupDraftType.Batch : GraphQL.CommunicationGroupDraftType.Single,
        data: JSON.stringify(data),
      }
      await dispatch(saveCommunicationAsDraft(vars))
      await dispatch(fetchDraftsList({ communicationGroupID: commGroupID }))
    }
  }

  const handleShowCommCC = () => {
    setShowCommCC(!isBatch && !showCommCC)
  }

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId)
    // filter template and add to editor
    if (API.isSuccess(commTemplates)) {
      const { getCommunicationTemplates } = commTemplates.payload
      if (getCommunicationTemplates) {
        const currTemplate = getCommunicationTemplates.find((template) => template.id === templateId)
        if (currTemplate) {
          setSubject(currTemplate.subject)
          setEditorContent(currTemplate.body)
        }
      }
    }
  }

  const handleFileAttachment = async (file: File) => {
    const fileStatus = await attachCommFile(file)
    if (fileStatus.id) {
      setAttachments([
        ...attachments,
        {
          contentId: fileStatus.content.id,
          name: fileStatus.name,
          thumbnailId: fileStatus.thumbnail ? fileStatus.thumbnail.id : undefined,
        },
      ])

      setUploadedFiles([
        ...uploadedfiles,
        {
          url: fileStatus.content.url,
          id: fileStatus.content.id,
          name: fileStatus.name,
        },
      ])
    }
  }

  // Handle deletion based on URL
  const handleDeleteUploadedFile = (id: string) => {
    const matchedFile = uploadedfiles.find((uploadedfile) => uploadedfile.id === id)

    if (matchedFile) {
      setUploadedFiles((prevUrls) => prevUrls.filter((thumbnail) => thumbnail.id !== id))
      setAttachments((prevAttachments) => prevAttachments.filter((attachment) => attachment.contentId !== id))
    }
  }

  const submissionIsActive = useMemo(() => (commTo.length > 0
      && subject.length > 0
      && editorContent.length > 0
  ), [ commTo, subject, editorContent ])

  return (
    <Modal
      title={ (isBatch) ? translate("New Batch Message") : translate("Create New Message") }
      disabled={ !submissionIsActive }
      primaryLabel={ translate("SEND") }
      open={ modalCreateMessageOpen }
      closeAction={ handleCancelAction }
      hasSecondaryButton={ true }
      secondaryLabel={ translate("Save Draft") }
      primaryAction={ handleCreateMessageSubmission }
      secondaryAction={ handleSaveAsDraftSubmission }
      maxWidth="lg"
      className="cp_modal-comm-create-message"
    >
      { isBatch && (
        <section className="cp_modal-comm-create-message-message-bar">
          <MessageBar variant="warning" message={ translate("A separate conversation will be created for each recipient!") } />
        </section>
      ) }
      <section className="cp_modal-comm-create-message-template">
        <h5>{ `1. ${ translate("Select a template") } ` }</h5>
        <div className="cp_modal-comm-create-message-template-select">
          <p>{ translate("Template") }</p>
          <div className="top-fields-container">
            { API.isSuccess(commTemplates) && (
            <Select
              id="commTemplateSelect"
              className="comm-template-select"
              label=""
              labelId="modal-manage-brand-managers-label"
              fullWidth={ true }
              value={ selectedTemplate || "" }
              onChange={ (e) => handleTemplateChange(e.target.value) }
              displayEmpty={ true }
              menuItems={ [
                // Placeholder MenuItem
                <MenuItem key="placeholder" value="" disabled={ true }>
                  <em>{ translate("Select message template") }</em>
                </MenuItem>,
                // Dynamically generated menu items
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...commTemplates.payload?.getCommunicationTemplates.map((template) => (
                  <MenuItem key={ template.id } value={ template.id }>
                    { template.name }
                  </MenuItem>
                )),
              ] }
            />
            ) }
            <div className="upload-link-container">
              <Checkbox
                id="commTemplateCheckbox"
                color="primary"
                checked={ isContentUploadLink }
                onChange={ () => setIsContentUploadLink(!isContentUploadLink) }
              />
              <label htmlFor="commTemplateCheckbox">{ translate("Content Upload Link") }</label>
            </div>
          </div>
        </div>
      </section>
      <Divider />
      <section className="cp_modal-comm-create-message-configure">
        <h5>{ `2. ${ translate("Configure Email") } ` }</h5>
        <div className="cp_modal-comm-create-message-configure-fields">
          <p className="labelfor-to">{ `${ translate("To") }:` }</p>
          { commGroupID && (
          <CommGroupEmailsAutocomplete
            communicationGroupId={ commGroupID }
            setSelectedEmails={ setCommTo }
            selectedEmails={ commTo }
            setShowCommCC={ (!isBatch) ? handleShowCommCC : null }
          />
          ) }
          { (commGroupID && showCommCC && !isBatch) && (
            <>
              <p>CC:</p>
              <CommGroupEmailsAutocomplete
                communicationGroupId={ commGroupID }
                setSelectedEmails={ setCommCCEmails }
                selectedEmails={ commCCEmails }
                setShowCommCC={ null }
              />
            </>
          ) }
          <Input
            className="cp_modal-comm-create-message-subject"
            id="commCC"
            label=""
            placeholder="Subject Line:"
            fullWidth={ true }
            value={ subject }
            onChange={ (e) => setSubject(e.target.value) }
          />
          <RichTextEditor
            editorContent={ editorContent }
            setEditorContent={ setEditorContent }
            handleFileAttachment={ handleFileAttachment }
            uploadedFiles={ uploadedfiles }
            onDeleteThumbnail={ (id) => handleDeleteUploadedFile(id) }
          />
        </div>
      </section>
    </Modal>
  )
}
