import React from "react"
import { Folder } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import { shorthandNumber } from "../../util/miscHelper"

import "./style.sass"

type Props = {
    name: string
    numberOfLists: number
    numberOfAccounts: number
    numberOfFollowers: number
}

export default function ListGroupHeaderCard({
  name, numberOfLists, numberOfAccounts, numberOfFollowers,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListGroupOverview" })
  return (
    <div className="cp_list-group-header-card">
      <div className="cp_list-group-header-card-name-container">
        <div className="cp_list-group-header-card-name-container-flex">
          <Folder className="cp_list-group-header-card-name-container-flex-folder-icon" />
          <p className="cp_list-group-header-card-name-container-flex-group-name">{ name }</p>
        </div>
      </div>
      <div className="cp_list-group-header-card-right-column">
        <div className="cp_list-group-header-card-right-column-wrapper">
          <div className="cp_list-group-header-card-right-column-wrapper-metric">
            <p className="cp_list-group-header-card-right-column-wrapper-metric-value">{ numberOfLists }</p>
            <p className="label_large-caps-bold">{ translate("Lists") }</p>
          </div>
          <div className="cp_list-group-header-card-right-column-wrapper-metric">
            <p className="cp_list-group-header-card-right-column-wrapper-metric-value">{ numberOfAccounts }</p>
            <p className="label_large-caps-bold">{ translate("Accounts") }</p>
          </div>
          <div className="cp_list-group-header-card-right-column-wrapper-metric">
            <p className="cp_list-group-header-card-right-column-wrapper-metric-value">{ shorthandNumber(numberOfFollowers) }</p>
            <p className="label_large-caps-bold">{ translate("Reach") }</p>
          </div>
        </div>
      </div>
    </div>
  )
}
