import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import Button from "../Button"
import NetworkAvatar from "../NetworkAvatar"
import { shorthandNumber } from "../../util/miscHelper"
import { useSelector } from "../../state/hooks"
import Select from "../Select"
import MenuItem from "../MenuItem"
import CopyIconButton from "../CopyIconButton"

import "./overview.sass"

function Overview(): React.JSX.Element {
  const {
    deliverable,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)
  const [ copyUserHandle, setCopyUserHandle ] = useState<string | null>(null)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const handleCopyUserHandleMouseEnter = (text: string) => {
    setCopyUserHandle(text)
  }

  const handleCopyUserHandleMouseLeave = () => {
    setCopyUserHandle(null)
  }

  const filterOptions = useMemo(() => [
    {
      label: translate("Approve"), value: "Approve",
    },
    {
      label: translate("Approve with Suggestions"), value: "Approve with suggestions",
    },
    {
      label: translate("Request Revision"), value: "Request Revision",
    },
  ].map(({ label, value }) => (
    <MenuItem
      id={ `cp_dashboard-campaigns_component-header-filter-option-${ value }` }
      key={ label }
      onClick={ async () => null }
      value={ value }
    >
      { translate(label) }
    </MenuItem>
  )), [ translate ])

  if (!deliverable) return <> </>

  return (
    <div className="cp_deliverable-content-overview_component">
      <div className="cp_deliverable-content-overview_component-left">
        <NetworkAvatar
          size="lg"
          network={ deliverable.campaignNetworkAccount.socialAccount.network }
          src={ deliverable.campaignNetworkAccount.socialAccount.profilePictureUrl }
        >
          { deliverable.campaignNetworkAccount.socialAccount.userName.charAt(0) }
        </NetworkAvatar>
        <div className="cp_deliverable-content-overview_component-left-details">
          <div>
            <p
              className="cp_deliverable-content-overview_component-left-details-name"
              onMouseEnter={
                () => handleCopyUserHandleMouseEnter(`@${ deliverable.campaignNetworkAccount.socialAccount.userName }`)
              }
              onMouseLeave={ handleCopyUserHandleMouseLeave }
            >
              { `@${ deliverable.campaignNetworkAccount.socialAccount.userName }` }
              { copyUserHandle && (
              <CopyIconButton textToCopy={ copyUserHandle } />
              ) }
            </p>
          </div>
          <p className="cp_deliverable-content-overview_component-left-details-demographics">
            {
              [
                deliverable.campaignNetworkAccount.socialAccount.name,
                `${ shorthandNumber(deliverable.campaignNetworkAccount.socialAccount.socialAccountStatistics.followers) }
                ${ translate("FOLLOWERS") }`,
              ].filter((components) => !!components).join(" | ")
            }
          </p>
        </div>
      </div>
      <div className="cp_deliverable-content-overview_component-review">
        <Select
          id="deliverable-review-status"
          label=""
          labelId="select-deliverable-status"
          menuItems={ filterOptions }
          className="deliverable-select"
          value="Approve"
        />
        <Button
          label={ translate("Finalize Feedback") }
          variant="outlined"
          isPrimary={ true }
          className="finalizeFeedback"
          onClick={ async () => null }
        />
      </div>
    </div>
  )
}

export default Overview
