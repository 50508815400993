import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import { RootState } from "../../state/store"
import Divider from "../Divider"
import Input from "../Input"
import ScopeExpansionPanel from "./ScopeExpansionPanel"
import { pushToast } from "../../state/toastSlice"
import { Toast } from "../../util/types"
import { isError, isSuccess } from "../../util/apiClient"
import * as Helper from "./helper"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import {
  createRole,
  fetchRole,
  openRoleModal,
  resetRoleModalState,
  updateRole,
} from "../../state/ModalRoleSlice"

/**
 * CreateRoleModal: The react modal component to create a new role in the system
 * @returns React component element
 */
export default function RoleModal() {
  // Local field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.RoleModal" })
  const dispatch = useDispatch()

  // Global state
  const {
    open,
    scopeMap,
    isEditing,
    roleId,
    role,
  } = useSelector((root: RootState) => root.roleModal)
  const { scopeGroups } = useSelector((root: RootState) => root.roles)
  const { role: currentUserRole } = useSelector((root: RootState) => root.user)

  // Local state
  const [ roleCode, setRoleCode ] = React.useState<string | undefined>()
  const [ displayName, setDisplayName ] = React.useState<string | undefined>()

  // React hooks
  React.useEffect(() => {
    if (isSuccess(scopeGroups)) {
      Helper.createScopeMap(scopeGroups.payload.scopeGroups, dispatch)
    }
  }, [ scopeGroups ])

  React.useEffect(() => {
    if (isEditing && roleId) {
      if (role === "init") dispatch(fetchRole(roleId))
      if (isSuccess(role)) {
        setRoleCode(role.payload.role.code)
        setDisplayName(role.payload.role.display)
        if (scopeMap) {
          Helper.updateScopeMap(scopeMap, role.payload.role.scopes, dispatch)
        }
      }
      if (isError(role)) {
        const toast: Toast = {
          type: "error",
          message: translate("Failed to fetch role data! Please try again later!"),
        }
        dispatch(pushToast(toast))
        closeModal()
      }
    }
  }, [ role ])

  // Functions

  /**
   * closeModal: resets local and global states
   */
  const closeModal = () => {
    setRoleCode(undefined)
    setDisplayName(undefined)
    dispatch(resetRoleModalState())
    if (isSuccess(scopeGroups)) Helper.createScopeMap(scopeGroups.payload.scopeGroups, dispatch)
  }

  /**
   * submitSaveRole: Submits the newly created role to be saved to the database
   * @returns void
   */
  const submitSaveRole = () => {
    // Ensure they are not undefined
    if (!roleCode) return
    if (!displayName) return

    // Close the modal
    dispatch(openRoleModal(false))

    if (isEditing) {
      if (roleId && currentUserRole) {
        dispatch(updateRole({
          id: roleId,
          code: roleCode,
          display: displayName,
          memberOfRole: currentUserRole.id === roleId,
          onSuccess: () => {
            const toast: Toast = {
              type: "success",
              message: translate("Role successfully updated!"),
            }
            dispatch(pushToast(toast))
            closeModal()
          },
          onError: () => {
            const toast: Toast = {
              type: "error",
              message: translate("Failed to update role! Please try again later!"),
            }
            dispatch(pushToast(toast))
            closeModal()
          },
        }))
      }
    } else {
      // Save the role
      dispatch(createRole({
        code: roleCode,
        display: displayName,
        onSuccess: () => {
          const toast: Toast = {
            type: "success",
            message: translate("Role successfully created!"),
          }
          dispatch(pushToast(toast))
          closeModal()
        },
        onError: () => {
          const toast: Toast = {
            type: "error",
            message: translate("Failed to create role! Please try again later!"),
          }
          dispatch(pushToast(toast))
          closeModal()
        },
      }))
    }
  }

  // The react component elements
  return (
    <Modal
      className="cp_component_create-role-modal"
      title={ (isEditing) ? translate("Edit Role") : translate("Create Role") }
      primaryLabel={ translate("Save Role") }
      secondaryLabel={ translate("Cancel") }
      secondaryAction={ closeModal }
      primaryAction={ submitSaveRole }
      disabled={ (!roleCode || roleCode === "" || !displayName || displayName === "") }
      closeAction={ closeModal }
      open={ open }
      maxWidth="xl"
    >
      {
        (isEditing && (role === "init" || role === "loading"))
          ? <LoadingIndicatorCard />
          : (
            <div className="cp_component_create-role-modal_body">
              <section className="cp_component_create-role-modal_body_basics">
                <h5 className="title_large basics-title">
                  { translate("Basics") }
                </h5>
                <Divider />
                <div className="basics-input">
                  <div className="basics-input_label">
                    <p className="label_small">{ translate("Role Code*") }</p>
                    <p className="body_small">
                      {
                        (roleCode) ? `${ roleCode.length }/140` : "0/140"
                      }
                    </p>
                  </div>
                  <Input
                    id="input-role-code"
                    placeholder={ translate("Enter Role Code") }
                    InputProps={ {
                      inputProps: { maxLength: 140 },
                    } }
                    onChange={ (e) => setRoleCode(e.currentTarget.value) }
                    value={ roleCode }
                    fullWidth={ true }
                  />
                </div>
                <div className="basics-input">
                  <div className="basics-input_label">
                    <p className="label_small">{ translate("Display*") }</p>
                    <p className="body_small">
                      {
                        (displayName) ? `${ displayName.length }/140` : "0/140"
                      }
                    </p>
                  </div>
                  <Input
                    id="input-role-display-name"
                    placeholder={ translate("Enter Display Name") }
                    InputProps={ {
                      inputProps: { maxLength: 140 },
                    } }
                    onChange={ (e) => setDisplayName(e.currentTarget.value) }
                    value={ displayName }
                    fullWidth={ true }
                  />
                </div>
              </section>
              { (scopeMap) && Object.keys(scopeMap).map((key) => (
                <ScopeExpansionPanel summary={ key } scopes={ scopeMap[key] } />
              )) }
            </div>
          )
      }
    </Modal>
  )
}
