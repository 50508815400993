import React, { useMemo } from "react"
import { Link } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useTranslation } from "react-i18next"

import LoadingIndicator from "../LoadingIndicator"
import ScoreHistogram from "./ScoreHistogram"
import { SCOREBREAKDOWN } from "../../util/constant"
import { useSelector } from "../../state/hooks"
import {
  calcGrade,
  formatPercent,
  floor,
  capitalizeEachWord,
} from "../../util/miscHelper"

import "./style.sass"

interface ScoreComponentInterface {
  raw: number;
  value: number;
  display: string;
  description: string;
  weight: number;
}

interface Props {
  component: ScoreComponentInterface;
  totalWeight: number
}

function ScoreComponent({ component, totalWeight }: Props) {
  const {
    raw,
    value,
    display,
    description,
    weight,
  } = component
  const [ grade, modifier ] = useMemo(() => calcGrade(value), [ value ])
  const formattedValue = useMemo(() => {
    if (display === SCOREBREAKDOWN.Posting_Frequency) {
      return `${ floor(2, raw).toString() }`
    }
    return `${ formatPercent(2, raw).toString() }`
  }, [ display, raw ])
  return (
    <div className="component">
      <p className="percentage">{ formatPercent(1, (weight / totalWeight)) }</p>
      <p className="descriptor">
        { display }
        <span>{ description }</span>
      </p>
      <p className="value">{ formattedValue }</p>
      <p className={ `grade grade-${ grade }` }>{ `${ grade }${ modifier }` }</p>
    </div>
  )
}

export default function ScoreBreakDown() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ScoreBreakDown" })
  const scoreData = useSelector(({ scoreBreakDown }) => scoreBreakDown)

  if (
    scoreData.title === null
    || scoreData.selected === null
    || !scoreData.counts.length
  ) {
    return (
      <div className="cp_component_scorebreakdown-loading">
        <LoadingIndicator size={ 50 } color="info" />
      </div>
    )
  }
  const [ grade, modifier ] = calcGrade(scoreData.selected)
  const totalWeight = scoreData.components.reduce((prev, comp) => prev + comp.weight, 0)
  const currNetwork = scoreData.network ? capitalizeEachWord(scoreData.network) : "network"

  return (
    <div className="cp_component_scorebreakdown">
      <div className="header">
        <div className={ `score-box grade-${ grade }` }>
          <h2 className="score-grade">{ `${ grade }${ modifier }` }</h2>
        </div>
        <h2 className="score-title">{ translate(scoreData.title) }</h2>
      </div>
      <div className="score-data">
        <div className="histogram">
          <ScoreHistogram data={ scoreData.counts } selected={ scoreData.selected } />
          <Link
            href="https://support.influential.co/hc/en-us/articles/360023437751-Scoring-Methodology"
            target="_blank"
            rel="noopener noreferrer"
            className="info-link"
          >
            { `Scores across ${ currNetwork } accounts` }
            <InfoOutlinedIcon />
          </Link>
        </div>
        <div className="breakdown">
          <div className="score-headers">
            <p className="score-header percentage">{ translate("Weight") }</p>
            <p className="score-header descriptor">{ translate("Component") }</p>
            <p className="score-header value">{ translate("Value") }</p>
            <p className="score-header value">{ translate("Grade") }</p>
          </div>
          { scoreData.components && (
            <div className="scores">
              { scoreData.components.map(
                (component) => <ScoreComponent component={ component } totalWeight={ totalWeight } />,
              )
              }
            </div>
          ) }
        </div>
      </div>
    </div>
  )
}
