import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"

/**
 * ConfigurationAccountsTab: Component for the accounts tab in the configuration preview
 * @returns Accounts tab for configuration
 */
export default function ConfigurationAccountsTab() {
  // Field variables
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListConfigurationAccountsTab" })

  return (
    <div className="cp_lcp-tabs_account-tab-content">
      <h4 className="headline_medium">{ translate("Accounts Preview") }</h4>
      <p>{ translate("Paragraph Text") }</p>
    </div>
  )
}
