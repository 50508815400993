import React from "react"
import "./style.sass"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import { ReadableNetwork } from "../../util/constant"
import { fetchCurrentUser, setOpenOAuthContinueModal } from "../../state/userSlice"
import { Network } from "../../graphql"

export default function UserOauthContinueModal() {
  // Field variabls/imported functions
  const { t: translate } = useTranslation([], { keyPrefix: "component.OAuthUserContinueModal" })
  const dispatch = useDispatch()

  // Global state values
  const {
    openOAuthContinueModal: open,
    oauthModalNetwork: network,
  } = useSelector(({ user }) => user)

  // Functions

  const closeAction = () => {
    // close the dialog box
    dispatch(setOpenOAuthContinueModal(false))
  }

  const handleContinue = () => {
    // Placeholder
    dispatch(fetchCurrentUser())
    closeAction()
  }

  return (
    <Modal
      className="cp_component_modal_oauth-user-continue"
      title={ `${ translate("OAuth") } ${ (network === Network.Youtube)
        ? "DV360" : ReadableNetwork[network] } ${ translate("Account") }` }
      primaryLabel={ translate("Continue") }
      secondaryLabel={ translate("Cancel") }
      open={ open }
      closeAction={ closeAction }
      primaryAction={ handleContinue }
      secondaryAction={ closeAction }
      disabled={ false }
      secondaryDisabled={ false }
    >
      <div className="cp_component_modal_oauth-continiue">
        <p className="body_medium">{ translate("Please press continue once you have completed OAuth.") }</p>
      </div>
    </Modal>
  )
}
