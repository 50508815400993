/* eslint-disable max-len */
import React, { JSX, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams, useParams } from "react-router-dom"
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material"
import {
  AccountCircle,
  ArrowDropDown,
  CheckOutlined,
  InsertPhotoOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material"

import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import * as SearchHelper from "../../util/searchHelper"
import Button from "../Button"
import Dropdown from "../Dropdown"
import LoadingIndicator from "../LoadingIndicator"
import SearchResultsTable from "../SearchResultsTable"
import { SearchContentEllipsisMenuOptions } from "../SearchResultsTable/ContentEllipsisMenuCell"
import StartsWithInput from "./StartsWithInput"
import { Scope } from "../../util/types"
import { addCampaignNetworkAccounts } from "../../state/campaignSlice"
import {
  clearSearchState,
  fetchSearchResults,
  setSearchInput,
  setSelectedAccounts,
  startCsvExport,
} from "../../state/searchSlice"
import { setSelectedAccountIds, setSelectedNetwork } from "../../state/modalAddToLists"
import {
  setShowSelectedAccounts,
  submitAccountsToList,
  toggleShowAcountSearch,
} from "../../state/listAddAccount"
import { useDispatch, useSelector } from "../../state/hooks"
import IconButton from "../IconButton"

import "./style.sass"
import { pushToast } from "../../state/toastSlice"

function checkmarkVisibleClass(visible: boolean): string {
  if (visible) {
    return "list-item-icon"
  }

  return "list-item-icon cp_component_search-results_disabled"
}

function renderContentSortByButton(buttonText: string): JSX.Element {
  return (
    <Button
      isEnabled={ true }
      isPrimary={ true }
      label={ buttonText }
      size="small"
    />
  )
}

function renderResultTypeIcon(resultType: GraphQL.GodSearchType): JSX.Element {
  const resultTypeIcon = resultType === GraphQL.GodSearchType.Social
    ? <AccountCircle />
    : <InsertPhotoOutlined />

  return (
    <div className="cp_component_search-results-type-dropdown">
      { resultTypeIcon }
      <ArrowDropDown />
    </div>
  )
}

type Props = {
  mountContext: SearchHelper.MountContext,
  showFormFields?: boolean,
  isSelectable?: boolean,
  addButtonCallback?: () => any,
  /**
   * Props passed down to the Content Ellipsis Menu.
   *
   * https://influential-team.atlassian.net/jira/software/c/projects/ID/boards/9?assignee=712020%3A2ebe1106-d27b-4989-976f-fd24255f27d3&selectedIssue=ID-2534
   */
  contentContextMenu: SearchContentEllipsisMenuOptions
}

export default function SearchResults(
  {
    mountContext,
    showFormFields = true,
    isSelectable = true,
    addButtonCallback,
    contentContextMenu,
  }: Props,
): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchResults" })
  const dispatch = useDispatch()
  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)
  const {
    searchInput,
    searchResultsAccounts,
    searchResultsContent,
    selectedAccounts: selectedAccountIds,
  } = useSelector(({ search }) => search)
  const addAccountInfo = useSelector((state) => state.listAddAccount)
  const { campaignNetworkAccounts } = useSelector(({ campaignPage }) => campaignPage)
  const currList = useSelector(({ list }) => list.list)
  const { campaignID } = useParams()

  // On unmount, clean up search state
  useEffect(() => () => {
    dispatch(clearSearchState())
  }, [])

  const [ searchParams, setSearchParams ] = useSearchParams()
  const addToCtaLabel = mountContext === "campaign"
    ? translate("Add to Campaign")
    : translate("Add to List")

  const canSearchByAccount = scopes.includes(Scope.FEATURE_SEARCH_INFLUENCER)
    || scopes.includes(Scope.FEATURE_SEARCH_AUDIENCE)

  const handleQueueAccountsForLists = () => {
    // Queuing accounts opens Add To Lists modal
    if (selectedAccountIds.length === 0) return

    // Find network based on CONTENT result type and selected rows
    // Post Searches yield postIds in selectedAccountIds NOT accountIds
    if (searchInput.resultType === GraphQL.GodSearchType.Post) {
      const selectedPosts = searchResultsContent.filter((post) => selectedAccountIds.includes(post.id))
      const selectedAccountIdsFromPosts = Array.from(new Set(selectedPosts.map((post) => post.socialAccount.id)))

      dispatch(setSelectedAccountIds(selectedAccountIdsFromPosts))

      const selectedNetwork = searchResultsContent
        .find(({ id }) => id === selectedAccountIds[0])
        ?.socialAccount.network || null

      dispatch(setSelectedNetwork(selectedNetwork))
    }

    // Find network based on ACCOUNT result type and selected rows
    if (searchInput.resultType === GraphQL.GodSearchType.Social) {
      dispatch(setSelectedAccountIds(selectedAccountIds))

      const selectedNetwork = searchResultsAccounts
        .find((account) => account.socialAccount.id === selectedAccountIds[0])
        ?.socialAccount.network || null
      dispatch(setSelectedNetwork(selectedNetwork))
    }
  }

  const handleAddAccountsToList = async () => {
    if (API.isSuccess(currList) && currList.payload) {
      const { suggestionListCategories, id } = currList.payload.suggestionListById
      const { selectedAccounts } = addAccountInfo
      if (selectedAccounts.length === 0 || !suggestionListCategories.some((category) => category.id !== undefined && category.id !== null)) {
        dispatch(toggleShowAcountSearch())
        return
      }
      let networkAccountIds = selectedAccounts
      if (searchInput.resultType === GraphQL.GodSearchType.Post) {
        const selectedPosts = searchResultsContent.filter((post) => selectedAccounts.includes(post.id))
        const selectedAccountIdsFromPosts = Array.from(new Set(selectedPosts.map((post) => post.socialAccount.id)))
        networkAccountIds = selectedAccountIdsFromPosts
      }

      await dispatch(await submitAccountsToList(
        id,
        suggestionListCategories[0].id,
        networkAccountIds,
        translate,
      ))
      dispatch(toggleShowAcountSearch())
    }
  }

  const handleAddAccountToCampaign = async () => {
    /*
      If the user is performing
      a Post search, we recieve the selected Post Ids. We can use this to find the unique social
      account Ids and add them to the campaign.
      */

    if (campaignID && selectedAccountIds && selectedAccountIds.length > 0) {
      const addCampaignPayload = {
        campaignId: campaignID,
        networkAccountIds: selectedAccountIds,
      }
      if (searchInput.resultType === GraphQL.GodSearchType.Post) {
        const selectedPosts = searchResultsContent.filter((post) => selectedAccountIds.includes(post.id))
        const selectedAccountIdsFromPosts = Array.from(new Set(selectedPosts.map((post) => post.socialAccount.id)))
        addCampaignPayload.networkAccountIds = selectedAccountIdsFromPosts
      }

      await dispatch(addCampaignNetworkAccounts(addCampaignPayload, translate))

      dispatch(setSelectedAccounts([]))
      if (addButtonCallback) addButtonCallback()
    }
  }

  const handleViewSelectedAccountsToggle = () => {
    dispatch(setShowSelectedAccounts(!addAccountInfo.showSelectedAccounts))
  }

  const startCsvDownload = () => {
    dispatch(startCsvExport({
      onSuccess: (message: string) => {
        dispatch(pushToast({
          type: "success",
          message,
        }))
      },
      onError: (message: string) => {
        dispatch(pushToast({
          type: "error",
          message,
        }))
      },
    }))
  }

  return (
    <div className="cp_component_search-results">
      { showFormFields && (
      <aside>
        <div>
          <StartsWithInput />
          { mountContext === "list" && (
          <Typography onClick={ handleViewSelectedAccountsToggle }>
            { `${ addAccountInfo.selectedAccounts.length } ${ translate("Selected Accounts") }` }
          </Typography>
          ) }
        </div>
        <div>
          { (mountContext === "search"
            && scopes.includes(Scope.FEATURE_INFLUENCER_LIST)) && (
            <Button
              isEnabled={
                addAccountInfo.addAccountStatus !== "loading"
                && selectedAccountIds.length > 0
              }
              isPrimary={ true }
              label={
                addAccountInfo.addAccountStatus === "loading"
                  ? <LoadingIndicator />
                  : addToCtaLabel
                }
              onClick={ handleQueueAccountsForLists }
              size="small"
            />
          ) }
          { (mountContext === "list"
            && scopes.includes(Scope.FEATURE_INFLUENCER_LIST)) && (
            <Button
              isEnabled={ addAccountInfo.addAccountStatus !== "loading" }
              isPrimary={ true }
              label={
                addAccountInfo.addAccountStatus === "loading"
                  ? <LoadingIndicator />
                  : addToCtaLabel
                }
              onClick={ handleAddAccountsToList }
              size="small"
            />
          ) }
          { mountContext === "campaign" && (
            <Button
              isEnabled={ campaignNetworkAccounts !== "loading" }
              isPrimary={ true }
              label={
                campaignNetworkAccounts === "loading"
                  ? <LoadingIndicator />
                  : addToCtaLabel
                }
              onClick={ handleAddAccountToCampaign }
              size="small"
            />
          ) }
          { searchInput.resultType === GraphQL.GodSearchType.Post && (
            <Dropdown
              buttonType="custom"
              customButtonChildren={
                renderContentSortByButton(translate("Sort By"))
              }
            >
              <List disablePadding={ true }>
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    onClick={ async () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.sortDirection = GraphQL.SortDirection.Asc
                      await dispatch(setSearchInput(newInput))
                      dispatch(fetchSearchResults(true))
                      SearchHelper.setSearchInputQueryParams(
                        newInput,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                  >
                    <ListItemIcon>
                      <CheckOutlined
                        className={
                          checkmarkVisibleClass(
                            searchInput.sortDirection
                              === GraphQL.SortDirection.Asc,
                          )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={ translate("Ascending") } />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    onClick={ async () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.sortDirection = GraphQL.SortDirection.Desc
                      await dispatch(setSearchInput(newInput))
                      dispatch(fetchSearchResults(true))
                      SearchHelper.setSearchInputQueryParams(
                        newInput,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                  >
                    <ListItemIcon>
                      <CheckOutlined
                        className={
                          checkmarkVisibleClass(
                            searchInput.sortDirection
                              === GraphQL.SortDirection.Desc,
                          )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={ translate("Descending") } />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    onClick={ async () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.sortBy = SearchHelper.SearchFieldString.PostDate
                      await dispatch(setSearchInput(newInput))
                      dispatch(fetchSearchResults(true))
                      SearchHelper.setSearchInputQueryParams(
                        newInput,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                  >
                    <ListItemIcon>
                      <CheckOutlined
                        className={
                          checkmarkVisibleClass(
                            searchInput.sortBy
                              === SearchHelper.SearchFieldString.PostDate,
                          )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={ translate("Date Posted") } />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    onClick={ async () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.sortBy = SearchHelper.SearchFieldString.EngagementRate
                      await dispatch(setSearchInput(newInput))
                      dispatch(fetchSearchResults(true))
                      SearchHelper.setSearchInputQueryParams(
                        newInput,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                  >
                    <ListItemIcon>
                      <CheckOutlined
                        className={
                          checkmarkVisibleClass(
                            searchInput.sortBy
                              === SearchHelper.SearchFieldString.EngagementRate,
                          )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={ translate("Engagement Rate") } />
                  </ListItemButton>
                </ListItem>
              </List>
            </Dropdown>
          ) }
          <IconButton
            id="search-results-csv-download-button"
            className={ `cp_component_search-results-csv-download-button${ (searchResultsAccounts.length === 0 && searchResultsContent.length === 0) ? " disabled-csv-icon" : " enabled-csv-icon" }` }
            variant="outlined"
            onClick={ startCsvDownload }
            disabled={ searchResultsAccounts.length === 0 && searchResultsContent.length === 0 }
          >
            <FileDownloadOutlined />
          </IconButton>
          <Dropdown
            buttonType="custom"
            customButtonChildren={
            renderResultTypeIcon(searchInput.resultType)
          }
          >
            <List disablePadding={ true }>
              { canSearchByAccount && (
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    onClick={ async () => {
                      const newInput = SearchHelper.cloneSearchInput(searchInput)
                      newInput.resultType = GraphQL.GodSearchType.Social
                      newInput.sortBy = SearchHelper.SearchFieldString.EngagementScore
                      await dispatch(setSearchInput(newInput))
                      dispatch(fetchSearchResults(true))
                      SearchHelper.setSearchInputQueryParams(
                        newInput,
                        searchParams,
                        setSearchParams,
                      )
                    } }
                  >
                    <ListItemIcon>
                      <AccountCircle className="list-item-icon" />
                    </ListItemIcon>
                    <ListItemText primary={ translate("Accounts") } />
                  </ListItemButton>
                </ListItem>
              ) }
              <ListItem disablePadding={ true } disableGutters={ true }>
                <ListItemButton
                  onClick={ async () => {
                    const newInput = SearchHelper.cloneSearchInput(searchInput)
                    newInput.resultType = GraphQL.GodSearchType.Post
                    await dispatch(setSearchInput(newInput))
                    dispatch(fetchSearchResults(true))
                    SearchHelper.setSearchInputQueryParams(
                      newInput,
                      searchParams,
                      setSearchParams,
                    )
                  } }
                >
                  <ListItemIcon>
                    <InsertPhotoOutlined className="list-item-icon" />
                  </ListItemIcon>
                  <ListItemText primary={ translate("Content") } />
                </ListItemButton>
              </ListItem>
            </List>
          </Dropdown>
        </div>
      </aside>
      ) }
      <section className="cp_component_search-results-table-container">
        <SearchResultsTable
          mountContext={ mountContext }
          isSelectable={ isSelectable }
          contentContextMenu={ contentContextMenu }
        />
      </section>
    </div>
  )
}
