import React from "react"
import { IconButton } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import CampaignIcon from "@mui/icons-material/Campaign"
import { useTranslation } from "react-i18next"

import Badge from "../Badge"
import Tooltip from "../Tooltip"
import { CampaignPermissionType, DeliverableCaptionFragment } from "../../graphql"
import { Scope } from "../../util/types"
import { isSuccess } from "../../util/apiClient"
import {
  setCaptionFeedback,
  setCaptionToApprove,
  setEdited,
} from "../../state/campaignDeliverableContentModalSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./caption.sass"
import CopyIconButton from "../CopyIconButton"

interface DeliverableCaptionProps {
  caption: DeliverableCaptionFragment | undefined | null
}

function DeliverableCaption({ caption }: DeliverableCaptionProps): React.JSX.Element {
  const dispatch = useDispatch()
  const {
    captionFeedback, captionToApprove, deliverable, campaign,
  } = useSelector(({
    campaignDeliverableContentModal,
  }) => campaignDeliverableContentModal)
  const {
    user,
    scopes,
  } = useSelector(({ user: userSlice }) => userSlice)
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContentTab" })

  const hasFeedbackPermission = React.useMemo(() => {
    /*
      if they are not from the team owner of the deliverable,
      then they must be assigned the permission to VIEW as a brand manager.
    */
    if (!campaign || !isSuccess(user)) return false
    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id
    const brandManager = campaign.userPermissions.find((permission) => permission.user.id === user.payload.currentUser?.id)
    const brandManagerWithViewPermission = !!brandManager && brandManager.permissions
      .includes(CampaignPermissionType.View)
    if (!userTeamIsCreator) return brandManagerWithViewPermission
    return true
  }, [ campaign, user, scopes ])

  const hasApprovalPermission = React.useMemo(() => {
    /*
      if they are not from the team owner of the deliverable,
      then they must be assigned the permission to APPROVE as a brand manager.
    */
    if (!campaign || !isSuccess(user)) return false

    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id
    const brandManager = campaign.userPermissions.find((permission) => permission.user.id === user.payload.currentUser?.id)
    const brandManagerWithApprovalPermission = !!brandManager && brandManager.permissions
      .includes(CampaignPermissionType.Approve)

    if (!userTeamIsCreator) return brandManagerWithApprovalPermission
    return brandManagerWithApprovalPermission || scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
  }, [ campaign, user, scopes ])
  if (!caption || !caption.text) return <> </>

  return (
    <div className="cp_campaign-deliverable-caption_component">
      <p
        className="body_large"
      >
        { translate("Caption") }
        <CopyIconButton textToCopy={ caption.text } />
      </p>
      <p
        className={ `cp_campaign-deliverable-caption_component-text ${ captionToApprove ? "compact" : "" }` }
      >
        { caption.text }
      </p>
      <div className="cp_campaign-deliverable-caption_component-controls">
        <IconButton
          className={ `btn-approve-caption ${ caption.accepted ? "approved" : "" } ${ captionToApprove ? "active" : "" }` }
          disabled={ caption.accepted || !hasApprovalPermission }
          onClick={ () => {
            dispatch(setCaptionToApprove({ caption }))
            dispatch(setEdited(true))
            dispatch(setCaptionFeedback({ caption: undefined }))
          } }
        >
          <CheckIcon />
        </IconButton>
        <Tooltip title={ translate("Leave Feedback") }>
          <div>
            <Badge badgeContent={ caption.feedback.length }>
              <IconButton
                className={ `btn-feedback ${ captionFeedback ? "active" : "" }` }
                disabled={ !hasFeedbackPermission }
                onClick={ () => {
                  dispatch(setCaptionFeedback({ caption }))
                  dispatch(setCaptionToApprove({ caption: undefined }))
                } }
              >
                <CampaignIcon />
              </IconButton>
            </Badge>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default DeliverableCaption
