/* eslint-disable max-len */
export default {
  TIN: {
    APP: {
      fullStoryOrgId: "o-1Z6XC3-na1",
      muiLicenseKey: "9055bcb4775f3711c7efc2cc7f3708ddTz0xMDYxMDYsRT0xNzY4Njk0Mzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
      platformSignature: "react_v1",
      BACKENDS: {
        API_V1: "/api/graphql",
        API_V2: "/api-v2/graphql",
        LOGIN: "/api/login",
        SEARCH: "/api/search",
      },
    },
    PRIMM: {
      authCookieName: "x-primm-token",
      url: "http://localhost:49019",
    },
  },
}
