import React from "react"
import { Typography } from "@mui/material"
import * as styles from "../../style/mui-styles"
import "./style.sass"

export default function BoldedText({ text, shouldBeBold }: { text: string; shouldBeBold: string[] }) {
  const regex = new RegExp(shouldBeBold.join("|"), "i")
  const textArray = text.split(regex)
  const boldedFromText = text.match(regex)
  return (
    <p className="cp_bolded-keywords_component">
      { textArray.map((item, index) => (
        <>
          { item }
          { index !== textArray.length - 1 && boldedFromText && (
          <Typography
            component="span"
            fontWeight={ styles.TYPOGRAPHY.FONT_WEIGHT.BOLDER }
            color="inherit"
          >
            { boldedFromText[index] }
          </Typography>
          ) }
        </>
      )) }
    </p>
  )
}
