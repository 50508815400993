import React, {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
  Chip,
  IconButton,
  InputAdornment,
} from "@mui/material"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import CommGroupEmailsAutocomplete, { NetworkInfo } from "../ModalCreateMessage/CommGroupEmailsAutocomplete"
import Input from "../Input"
import Select from "../Select"
import MenuItem from "../MenuItem"
import Checkbox from "../Checkbox"
import Divider from "../Divider"
import Button from "../Button"
import { CommunicationDraftData, Toast } from "../../util/types"
import { pushToast } from "../../state/toastSlice"
import { attachCommFile } from "../../util/miscHelper"
import { fetchCommTemplates } from "../../state/modalCreateMessageSlice"
import RichTextEditor from "../RichTextEditor"
import LoadingIndicator from "../LoadingIndicator"
import {
  replyToConversation,
  fetchConversationThread,
  resetReplyToConversation,
  saveReplyToDraft,
} from "../../state/commEmailListView"

import "./communications-reading-panel-view.sass"
import "../ModalCreateMessage/style.sass"

export interface UploadedFiles {
  url: string
  id: string
  name: string
}

type Props = {
  handleCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ReplyToConversation({ handleCancel }: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsMessages" })
  const {
    replyConversation, communicationMessages,
  } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const { commTemplates } = useSelector(({ ModalCreateMessageSlice }) => ModalCreateMessageSlice)
  const { commGroupID } = useParams()
  const [ selectedTemplate, setSelectedTemplate ] = useState("")
  const [ isContentUploadLink, setIsContentUploadLink ] = useState(false)
  const [ commCCEmails, setCommCCEmails ] = useState<NetworkInfo[] | []>([])
  const [ showCommCC, setShowCommCC ] = useState(false)
  const [ editorContent, setEditorContent ] = useState("")
  const [ attachments, setAttachments ] = useState<GraphQL.ConversationAttachmentInput[]>([])
  const [ uploadedfiles, setUploadedFiles ] = useState<UploadedFiles[]>([])

  if (!API.isSuccess(communicationMessages)) {
    return <LoadingIndicator />
  }

  const toValue = useMemo(() => {
    const { getConversation } = communicationMessages.payload
    if (getConversation) {
      const { socialAccount, conversationThread } = getConversation
      return {
        socialAccount,
        conversationTo: conversationThread.to[0],
      }
    }
    return null
  }, [ communicationMessages ])

  const threadId = useMemo(() => communicationMessages.payload.getConversation.id, [ communicationMessages ])
  const subject = useMemo(() => communicationMessages.payload.getConversation.subject, [ communicationMessages ])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!API.isSuccess(commTemplates)) {
      dispatch(fetchCommTemplates())
    }
  }, [ commTemplates ])

  const clearForm = () => {
    setCommCCEmails([])
    setEditorContent("")
    setIsContentUploadLink(false)
    setAttachments([])
    setUploadedFiles([])
  }

  useEffect(() => {
    let toast: Toast
    if (API.isSuccess(replyConversation)) {
      toast = {
        type: "success",
        message: translate("Success! Message sent."),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetReplyToConversation())
      if (commGroupID) {
        dispatch(fetchConversationThread(threadId, commGroupID))
      }
      handleCancel(false)
    } else if (API.isError(replyConversation)) {
      toast = {
        type: "error",
        message: translate("Failed to create message. try again later."),
      }
      dispatch(pushToast(toast))
      clearForm()
      dispatch(resetReplyToConversation())
      if (commGroupID) {
        dispatch(fetchConversationThread(threadId, commGroupID))
      }
      handleCancel(false)
    }
  }, [ replyConversation ])

  const handleCancelAction = () => {
    clearForm()
    handleCancel(false)
  }

  const handleReplayToConversation = () => {
    if (threadId) {
      const messageInfo = {
        communicationGroupDraftId: null,
        threadId,
        body: editorContent,
        cc: commCCEmails.map((cc) => cc.email),
        attachments,
        enableUploadLink: isContentUploadLink,
      }
      dispatch(replyToConversation(messageInfo))
    }
  }

  const handleSaveReplyToDraft = () => {
    if (threadId && commGroupID && toValue) {
      // Create draft message data
      const draftMessageData: CommunicationDraftData = {
        mode: GraphQL.CommunicationGroupDraftType.Reply,
        toList: [ {
          email: toValue.conversationTo.address,
          unsubscribed: toValue.conversationTo.unsubscribedTags.length > 0,
          socialAccountId: toValue.socialAccount.id,
          socialAccountUsername: toValue.socialAccount.userName,
        } ],
        ccList: commCCEmails.map((cc) => ({
          email: cc.email,
          unsubscribed: true,
          socialAccountId: cc.socialAccountId,
          socialAccountUsername: cc.accountName,
        })),
        enableUploadLink: isContentUploadLink,
        subject,
        template: selectedTemplate,
        richTextEditorState: {
          attachementHistoryIndex: 0,
          attachmentHistory: [],
          attachments,
          uploadedfiles,
          content: editorContent,
        },
      }

      // Create the content to send
      const info: GraphQL.CreateCommunicationsDraftMutationVariables = {
        communicationGroupId: commGroupID,
        threadId,
        type: GraphQL.CommunicationGroupDraftType.Reply,
        data: JSON.stringify(draftMessageData),
      }

      // Create draft message
      dispatch(saveReplyToDraft({
        vars: info,
        onSuccess: () => {
          const toast: Toast = {
            type: "success",
            message: "Message succesfully saved to drafts folder",
          }
          dispatch(pushToast(toast))
          clearForm()
          dispatch(resetReplyToConversation())
          if (commGroupID) {
            dispatch(fetchConversationThread(threadId, commGroupID))
          }
          handleCancel(false)
        },
        onError: () => {
          const toast: Toast = {
            type: "error",
            message: "Failed to save to drafts folder, please try again later!",
          }
          dispatch(pushToast(toast))
          clearForm()
          dispatch(resetReplyToConversation())
          if (commGroupID) {
            dispatch(fetchConversationThread(threadId, commGroupID))
          }
          handleCancel(false)
        },
      }))
    }
  }

  const handleShowCommCC = () => {
    setShowCommCC(!showCommCC)
  }

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId)
    // filter template and add to editor
    if (API.isSuccess(commTemplates)) {
      const { getCommunicationTemplates } = commTemplates.payload
      if (getCommunicationTemplates) {
        const currTemplate = getCommunicationTemplates.find((template) => template.id === templateId)
        if (currTemplate) {
          setEditorContent(currTemplate.body)
        }
      }
    }
  }

  const handleFileAttachment = async (file: File) => {
    const fileStatus = await attachCommFile(file)
    if (fileStatus.id) {
      setAttachments([
        ...attachments,
        {
          contentId: fileStatus.content.id,
          name: fileStatus.name,
          thumbnailId: fileStatus.thumbnail ? fileStatus.thumbnail.id : undefined,
        },
      ])

      setUploadedFiles([
        ...uploadedfiles,
        {
          url: fileStatus.content.url,
          id: fileStatus.content.id,
          name: fileStatus.name,
        },
      ])
    }
  }

  // Handle deletion based on URL
  const handleDeleteUploadedFile = (id: string) => {
    const matchedFile = uploadedfiles.find((uploadedfile) => uploadedfile.id === id)

    if (matchedFile) {
      setUploadedFiles((prevUrls) => prevUrls.filter((thumbnail) => thumbnail.id !== id))
      setAttachments((prevAttachments) => prevAttachments.filter((attachment) => attachment.contentId !== id))
    }
  }

  // Remove Rich Text Editor HTML tags
  const stripHtmlTags = (html: string): string => html.replace(/<\/?[^>]+(>|$)/g, "").trim()

  return (
    <div className="cp_ct-message-replay-form">
      <section className="cp_ct-message-replay-form-template">
        <div className="cp_ct-message-replay-form-template-select">
          <p>{ translate("Template") }</p>
          <div className="top-fields-container">
            { API.isSuccess(commTemplates) && (
            <Select
              id="commTemplateSelect"
              className="comm-template-select"
              label=""
              labelId="modal-manage-brand-managers-label"
              fullWidth={ true }
              value={ selectedTemplate || "" }
              onChange={ (e) => handleTemplateChange(e.target.value) }
              displayEmpty={ true }
              menuItems={ [
                // Placeholder MenuItem
                <MenuItem key="placeholder" value="" disabled={ true }>
                  <em>{ translate("Select message template") }</em>
                </MenuItem>,
                // Dynamically generated menu items
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...commTemplates.payload?.getCommunicationTemplates.map((template) => (
                  <MenuItem key={ template.id } value={ template.id }>
                    { template.name }
                  </MenuItem>
                )),
              ] }
            />
            ) }
            <div className="upload-link-container">
              <Checkbox
                id="commTemplateCheckbox"
                color="primary"
                checked={ isContentUploadLink }
                onChange={ () => setIsContentUploadLink(!isContentUploadLink) }
              />
              <label htmlFor="commTemplateCheckbox">{ translate("Content Upload Link") }</label>
            </div>
          </div>
        </div>
      </section>
      <Divider className="cp_ct-message-replay-form-divider" />
      <section className="cp_ct-message-replay-form-configure">
        <div className="cp_ct-message-replay-form-configure-fields">
          <p className="labelfor-to">{ `${ translate("To") }:` }</p>
          <Input
            className="cp_ct-message-replay-form-configure-to_field"
            id="commTo"
            label=""
            fullWidth={ true }
            value=""
            disabled={ true }
            InputProps={ {
              startAdornment: (
                <InputAdornment position="start">
                  <Chip
                    label={ `${ toValue?.socialAccount.userName } (${ toValue?.conversationTo.address })` }
                    className="custom-chip"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={ handleShowCommCC } className="cc-labels">
                    CC
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: "text-field-root",
              },
            } }
          />
          { (commGroupID && showCommCC) && (
            <>
              <p>CC:</p>
              <CommGroupEmailsAutocomplete
                communicationGroupId={ commGroupID }
                setSelectedEmails={ setCommCCEmails }
                selectedEmails={ commCCEmails }
                setShowCommCC={ null }
              />
            </>
          ) }
          <RichTextEditor
            editorContent={ editorContent }
            setEditorContent={ setEditorContent }
            handleFileAttachment={ handleFileAttachment }
            uploadedFiles={ uploadedfiles }
            onDeleteThumbnail={ (id) => handleDeleteUploadedFile(id) }
          />
        </div>
      </section>
      <section className="cp_ct-message-replay-form-actions">
        <Button
          id="cp_ct-message-replay-form-actions-send"
          isPrimary={ true }
          isEnabled={ stripHtmlTags(editorContent) !== "" }
          label={ translate("SEND") }
          onClick={ handleReplayToConversation }
        />
        <Button
          id="cp_ct-message-replay-form-actions-cancel"
          isPrimary={ false }
          isEnabled={ true }
          label={ translate("CANCEL") }
          onClick={ handleCancelAction }
        />
        <Button
          id="cp-ct-message-reply-form-actions-save-draft"
          isPrimary={ false }
          label={ translate("Save Draft") }
          onClick={ handleSaveReplyToDraft }
        />
      </section>
    </div>
  )
}
