import React, { useMemo } from "react"
import dayjs from "dayjs"
import { GridColDef } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import {
  Share,
  Archive,
  Edit,
} from "@mui/icons-material"

import * as Constant from "../../util/constant"
import * as GraphQL from "../../graphql"
import Button from "../Button"
import ContextMenu from "./ListGroupContextMenu"
import DataGrid from "../DataGrid"
import EmptyElement from "../EmptyElement"
import EntityInfoRow from "../EntityInfoRow"
import Pill from "../Pill"
import Timestamp from "../DataGrid/RenderCellComponents/Timestamp"
import { fetchListGroup, setArchiveModalOpen } from "../../state/listGroupSlice"
import { setListGroupModalOpen, setListGroupToEdit } from "../../state/templateSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./list-group-management.sass"
import { isSuccess } from "../../util/apiClient"
import IconButton from "../IconButton"
import LoadingIndicator from "../LoadingIndicator"
import { openCopyLinkModal } from "../../state/copyLinkModalSlice"
import Tooltip from "../Tooltip"

type Props = {
    listGroup: GraphQL.SuggestionListGroupFragment
}

export default function ListGroupManagementTable({ listGroup }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListGroupManagementTable" })
  const dispatch = useDispatch()

  const {
    listGroup: fetchedListGroup, isArchiving,
  } = useSelector((state) => state.listGroup)

  const COLUMNS: GridColDef[] = useMemo(() => [
    {
      field: "list",
      sortable: false,
      headerName: translate("List"),
      renderCell: (params) => (
        <EntityInfoRow
          key={ params.row.id }
          name={ params.row.name }
          network={ params.row.network }
          networkAvatarOnly={ true }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 3,
    },
    {
      field: "reach",
      headerName: translate("Reach"),
      sortable: false,
      renderCell: (params) => (
        <p>{ parseInt(params.row.reach, 10).toLocaleString() }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "demographic",
      headerName: translate("Demographic"),
      sortable: false,
      renderCell: (params) => {
        const {
          minAge,
          maxAge,
          sexes,
        } = params.row
        const renderAge = [ minAge, maxAge ].every((v) => !!v)
        const renderGender = sexes.length > 0
        if (!renderAge && !renderGender) {
          return null
        }
        const items = []
        if (renderAge) {
          items.push(`${ minAge }-${ maxAge }`)
        }
        if (renderGender) {
          items.push(`${ sexes.join(", ") }`)
        }
        return <Pill label={ items.join(" | ") } />
      },
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "accounts",
      headerName: translate("Accounts"),
      sortable: false,
      renderCell: (params) => (
        <Pill label={ parseInt(params.row.numberOfLinkedSocialAccounts, 10) } />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "created",
      headerName: translate("Created"),
      sortable: false,
      renderCell: (params) => (
        <Timestamp
          time={
            dayjs(params.row.created * 1000)
              .format(Constant.LONGFORM_DATE).toUpperCase()
          }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 1,
    },
    {
      field: "modified",
      headerName: translate("Modified"),
      sortable: false,
      renderCell: (params) => (
        <Timestamp
          time={
            dayjs(params.row.created * 1000)
              .format(Constant.LONGFORM_DATE).toUpperCase()
          }
        />
      ),
      disableColumnMenu: true,
      resizable: false,
      flex: 2,
    },
    {
      field: "ellipsisMenu",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ContextMenu
          listGroupId={ listGroup.id }
          suggestionList={ params.row }
        />
      ),
      maxWidth: 60,
    },
  ], [ translate ])

  const suggestListGroup = (isSuccess(fetchedListGroup))
    ? fetchedListGroup.payload.suggestionListGroupById
    : undefined
  if (!suggestListGroup) return null
  const {
    id,
    archived,
    publicUrl,
  } = suggestListGroup

  return (
    <div className="cp_list-group_management-table">
      <div className="cp_list-group_management-table-action-buttons">
        <Tooltip title={ !archived ? translate("Archive") : translate("Archived") } arrow={ true }>
          <div>
            <IconButton id="group-list-archive-button" onClick={ () => dispatch(setArchiveModalOpen(true)) } size="small">
              { isArchiving ? <LoadingIndicator /> : <Archive /> }
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={ translate("Share") } arrow={ true }>
          <div>
            <IconButton
              id="group-list-share-button"
              onClick={ () => dispatch(openCopyLinkModal({
                link: publicUrl,
                title: translate("Share Group"),
                subtitle: translate("Copy the public URL for this suggestion list group below."),
              }))
              }
              size="small"
            >
              <Share />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={ translate("Edit") } arrow={ true }>
          <div>
            <IconButton
              id="group-list-edit-button"
              size="small"
              onClick={ () => {
                dispatch(setListGroupModalOpen(true))
                dispatch(setListGroupToEdit({
                  listGroup: suggestListGroup,
                  listGroupUpdateCallback: () => dispatch(fetchListGroup(id)),
                }))
              } }
            >
              <Edit />
            </IconButton>
          </div>
        </Tooltip>
        <div>
          <Button
            id="group-list-add-to-list-button"
            className="cp_list-group_management-table-action-buttons-add-button"
            label={ translate("+ Add List") }
            isEnabled={ true }
            isPrimary={ true }
            onClick={ () => {
              dispatch(setListGroupModalOpen(true))
              dispatch(setListGroupToEdit({
                listGroup,
                listGroupUpdateCallback: () => dispatch(fetchListGroup(listGroup.id)),
              }))
            } }
          />
        </div>
      </div>
      <DataGrid
        className="cp_list-group_management-table-data-table"
        getRowId={ (row) => row.id }
        disableRowSelectionOnClick={ true }
        rowHeight={ 90 }
        columnHeaderHeight={ 40 }
        columns={ COLUMNS }
        disableColumnReorder={ true }
        hideFooter={ true }
        pinnedColumns={
            {
              left: [ "list" ],
              right: [ "ellipsisMenu" ],
            }
        }
        rows={ listGroup.suggestionLists }
        slots={ {
          noRowsOverlay: EmptyElement,
          noResultsOverlay: EmptyElement,
        } }
      />
    </div>
  )
}
