import React, {
  useMemo,
  useState,
  useEffect,
} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "../../state/hooks"
import "./style.sass"
import * as GraphQL from "../../graphql"
import {
  generateDemographicRangeString,
  prettyPrintDecimal,
  shorthandNumber,
} from "../../util/miscHelper"
import { Success, ScoreBreakDown } from "../../util/types"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import { LIST_DISPLAY_TOGGLES, scoreModalTypes } from "../../util/constant"
import ModalScoreBreakDown from "../ModalScoreBreakDown"
import Button from "../Button"

type Props = {
  listProfile: Success<GraphQL.SearchListSocialProfileQuery>
}

export default function ListProfileScore({
  listProfile,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileOverview" })
  const [ scoreModalOpen, setScoreModalOpen ] = useState(false)
  const dispatch = useDispatch()

  const {
    suggestionList: {
      toggles,
      sexes,
      maxAge,
      minAge,
    },
    score,
    socialAccount: {
      socialAccountStatistics: {
        followers,
        engagementRate,
      },
    },
  } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId

  const demographicString = useMemo(() => (generateDemographicRangeString(sexes, minAge, maxAge)), [ sexes, minAge, maxAge ])

  useEffect(() => {
    if (scoreModalOpen === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ scoreModalOpen ])

  const handleScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string, isAdCouncilScore: boolean) => {
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType, isAdCouncilScore))
    setScoreModalOpen(true)
  }

  function handleScorePill(modalType: string, isAdCouncilScore: boolean) {
    const { socialAccount } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
    const scoreBreakDown = {
      socialAccount: socialAccount as GraphQL.SocialAccount,
      scores: [ score as GraphQL.Score ],
    }
    handleScoreModal(scoreBreakDown, modalType, isAdCouncilScore)
  }

  const selectedScore = useMemo(() => {
    const flatToggles = toggles.map((value) => value.name)
    if (!score) {
      return null
    }
    if (flatToggles.includes(LIST_DISPLAY_TOGGLES.AD_COUNCIL_SCORE)) {
      return (
        <div>
          <p className="label_small-caps-bold cp_profile-overview_component-scores-heading">
            { translate("AD COUNCIL") }
          </p>
          <Button
            className="cp_profile-overview_component-scores-button"
            isTextButton={ true }
            label={ Math.round(score?.value || 0) }
            onClick={ () => handleScorePill(scoreModalTypes.ADD_COUNCIL, false) }
          />
        </div>
      )
    }
    if (flatToggles.includes(LIST_DISPLAY_TOGGLES.ENG_SCORE)) {
      return (
        <div>
          <p className="label_small-caps-bold cp_profile-overview_component-scores-heading">
            { translate("ENG. SCORE") }
          </p>
          { score && (
            <Button
              className="cp_profile-overview_component-scores-button"
              isTextButton={ true }
              label={ Math.round(score.value) }
              onClick={ () => handleScorePill(scoreModalTypes.ENGAGEMENT, false) }
            />
          ) }
        </div>
      )
    }
    if (flatToggles.includes(LIST_DISPLAY_TOGGLES.ENG_RATE)) {
      return (
        <div>
          <p className="label_small-caps-bold cp_profile-overview_component-scores-heading">
            { translate("ENG. RATE") }
          </p>
          <p className="label_large-bold">{ `${ prettyPrintDecimal(engagementRate || 0) }%` }</p>
        </div>
      )
    }
    if (flatToggles.includes(LIST_DISPLAY_TOGGLES.ISCORE)) {
      return (
        <div>
          <p className="label_small-caps-bold cp_profile-overview_component-scores-heading">
            { translate("I-SCORE") }
          </p>
          <Button
            className="cp_profile-overview_component-scores-button"
            isTextButton={ true }
            label={ Math.round(score?.value || 0) }
            onClick={ () => handleScorePill(scoreModalTypes.I_SCORE, false) }
          />
        </div>
      )
    }

    if (flatToggles.includes(LIST_DISPLAY_TOGGLES.DEMOGRAPHIC_SCORE)) {
      return (
        <div>
          <p className="label_small-caps-bold cp_profile-overview_component-scores-heading">
            { translate("DEMO. SCORE") }
          </p>
          <p className="label_large-bold">{ Math.round(score?.value || 0) }</p>
        </div>
      )
    }
    return null
  }, [ translate, toggles ])

  return (
    <div className="cp_profile-overview_component-scores-wrapper">
      <div>
        <p className="label_small-caps-bold cp_profile-overview_component-scores-heading">
          { translate("FOLLOWERS") }
        </p>
        <p className="label_large-bold">{ shorthandNumber(followers) }</p>
      </div>
      { selectedScore }
      { demographicString && (
      <div>
        <p className="label_small-caps-bold cp_profile-overview_component-scores-heading">
          { translate("DEMOGRAPHIC") }
        </p>
        { demographicString
          && <p className="label_large-bold">{ demographicString }</p> }
      </div>
      ) }
      <ModalScoreBreakDown isModalScoreOpen={ scoreModalOpen } closeModal={ () => setScoreModalOpen(false) } />
    </div>
  )
}
