import React from "react"
import { useTranslation } from "react-i18next"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import histogram from "highcharts/modules/histogram-bellcurve"
import { useSelector } from "react-redux"

import { HISTOGRAM_COLORS_DARK, HISTOGRAM_COLORS_LIGHT } from "../../util/constant"

histogram(Highcharts)

interface Count {
  count: number;
  value: number;
}

interface Props {
  data: Count[];
  selected: number;
}

function ScoreHistogram({ data, selected }: Props) {
  const { theme } = useSelector(({ themeMode: themeModeSlice }) => themeModeSlice)

  const HISTOGRAM_COLORS = theme === "dark"
    ? HISTOGRAM_COLORS_DARK
    : HISTOGRAM_COLORS_LIGHT

  if (!data || !data.length || selected === undefined) return null
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ScoreBreakDown" })

  // Process the data
  const processedData = data.map((dataPoint) => ({
    x: dataPoint.value,
    y: dataPoint.count,
    color: dataPoint.value === Math.floor(selected) ? HISTOGRAM_COLORS.selected : HISTOGRAM_COLORS.default,
  }))

  const options: Highcharts.Options = {
    chart: {
      type: "column", // Use column type to easily customize individual columns
    },
    title: {
      text: translate("Percentile 100%"),
      align: "left",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      alignTicks: false,
      tickLength: 0,
      tickAmount: 5,
      min: 0,
      max: 100,
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Histogram",
        data: processedData,
        type: "column", // Ensure each unique value has its own bin
        borderColor: "transparent",
        showInLegend: false,
        states: { hover: { enabled: false } },
      },
    ],
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  }

  return <HighchartsReact highcharts={ Highcharts } options={ options } />
}

export default ScoreHistogram
