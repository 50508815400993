import React, { useMemo, useState } from "react"
import dayjs from "dayjs"

import * as Constant from "../../util/constant"
import Divider from "../Divider"
import { DeliverableItemFeedbackFragment } from "../../graphql"

import "./feedback-card.sass"
import CopyIconButton from "../CopyIconButton"

interface DeliverableFeedbackCardProps {
  feedback: DeliverableItemFeedbackFragment
}

function DeliverableFeedbackCard({ feedback }: DeliverableFeedbackCardProps): React.JSX.Element {
  const formattedDate = useMemo(() => dayjs(feedback.created * 1000)
    .format(Constant.LONGFORM_DATE_TIME), [ feedback.created ])

  const [ copyText, setCopyText ] = useState<string | null>(null)

  const handleCopyTextMouseEnter = (text: string) => {
    setCopyText(text)
  }

  const handleCopyTextMouseLeave = () => {
    setCopyText(null)
  }

  return (
    <div className="cp_media-feedback-card_component">
      <div className="cp_media-feedback-card_component-header">
        <div className="cp_media-feedback-card_component-header-name">
          <p className="cp_media-feedback-card_component-header-name-tag">
            @
            { feedback.reviewer.username }
          </p>
          <p className="cp_media-feedback-card_component-header-name-company">
            { feedback.reviewer.customer.company?.name }
          </p>
        </div>
        <div className="cp_media-feedback-card_component-header-date">
          { formattedDate }
        </div>
      </div>
      <Divider />
      <div
        className="cp_media-feedback-card_component-body"
        onMouseEnter={ () => handleCopyTextMouseEnter(feedback.text) }
        onMouseLeave={ handleCopyTextMouseLeave }
      >
        <p>{ feedback.text }</p>
        { copyText && (
          <CopyIconButton textToCopy={ copyText } />
        ) }
      </div>
    </div>
  )
}

export default DeliverableFeedbackCard
