import * as React from "react"
import { useTranslation } from "react-i18next"
import "../style.sass"
import { useSelector } from "../../../../state/hooks"
import * as API from "../../../../util/apiClient"
import "../../AccountInsights/account-insights-details.sass"
import Divider from "../../../Divider"
import {
  Network,
  ToggleFragment,
  SocialAccount,
} from "../../../../graphql"
import EngagementChartWrapper from "./EngagementChartWrapper"
import EngagementTableWrapper from "./EngagementTableWrapper"
import EngagementMapWrapper from "./EngagementMapWrapper"
import ImageAnalysisWrapper from "./ImageAnalysisWrapper"
import BrandsWrapper from "./BrandsWrapper"
import RecentMediaWrapper from "./RecentMediaWrapper"
import PersonalityTraitsWrapper from "./PersonalityTraitsWrapper"
import RecentPosts from "../../SocialProfileTabs/AccountInsightsDetails/RecentPost"
import { INSIGHTS_DISPLAY_TOGGLES } from "../../../../util/constant"

type Props = {
  toggles: ToggleFragment[]
}

export default function AccountInsightsDetails({ toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountInsights" })
  const {
    listProfile,
  } = useSelector(({ listSocialProfile }) => listSocialProfile)

  const socialAccount = React.useMemo(() => API.isSuccess(listProfile)
    ? listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId.socialAccount
    : null, [ listProfile ])

  const shouldDisplayImageAnalysis = React.useMemo(() => {
    if (API.isSuccess(listProfile)) {
      const { socialAccount: { network } } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ listProfile ])

  const shouldDisplayBrands = React.useMemo(() => {
    if (API.isSuccess(listProfile)) {
      const { socialAccount: { network } } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      return network === Network.Instagram || network === Network.Facebook
    }
    return false
  }, [ listProfile ])

  const displayRecentMediaPosts = React.useMemo(() => {
    if (API.isSuccess(listProfile)) {
      const { suggestionList } = listProfile.payload.suggestionListSocialAccountByListIdSocialAccountId
      return suggestionList.toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES)
    }
    return false
  }, [ listProfile ])

  const [
    displayEngagementRateOverTime,
    displayEngagementRateByPostType,
    displayEngagementRateByPostTypeReel,
    displayEngagementHeatMap,
    displayMediaAIAnalysis,
    displayBrandsMentionedWorked,
    displayBrandsDetected,
    displayPITraits,
  ] = React.useMemo(() => [
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME),
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE),
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL),
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP),
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS),
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH),
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED),
    toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS),
  ], [ toggles ])

  return (
    <div className="cp_account-insights-details_component">
      { displayEngagementRateOverTime
        && (
        <div className="cp_account-insights-details_component-widget">
          <EngagementChartWrapper />
        </div>
        ) }

      { (displayEngagementRateByPostType || displayEngagementRateByPostTypeReel)
      && (
      <div className="cp_account-insights-details_component-widget">
        <div>
          <h6 className="cp_account-insights-details_component-widget-title">
            { translate("Engagement by Post Type") }
          </h6>
          <Divider />
          <EngagementTableWrapper
            displayPostType={ displayEngagementRateByPostType }
            displayPostTypeReel={ displayEngagementRateByPostTypeReel }
          />
        </div>
      </div>
      ) }
      { displayEngagementHeatMap && (
      <div className="cp_account-insights-details_component-widget">
        <EngagementMapWrapper />
      </div>
      ) }
      { displayMediaAIAnalysis && (
      <div className="cp_account-insights-details_component-row">
        { shouldDisplayImageAnalysis && (
        <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-2">
          <div>
            <h6 className="cp_account-insights-details_component-widget-title">
              { translate("AI Image Analysis") }
            </h6>
            <Divider />
            <ImageAnalysisWrapper />
          </div>
        </div>
        ) }
        <div className="cp_account-insights-details_component-widget cp_account-insights-details_component-column-1">
          <div>
            <h6 className="cp_account-insights-details_component-widget-title">
              { translate("Recent Media") }
            </h6>
            <Divider />
            <RecentMediaWrapper />
          </div>
        </div>
      </div>
      ) }
      { displayRecentMediaPosts && (socialAccount?.oauthed && socialAccount?.recentStories.length > 0) && (
      <div className="cp_account-insights-details_component-widget">
        <div>
          <h6 className="cp_account-insights-details_component-widget-title">
            { translate("Recent Stories") }
          </h6>
          <Divider />
          <RecentPosts socialAccount={ socialAccount as SocialAccount } />
        </div>
      </div>
      ) }
      { (shouldDisplayBrands
       && (displayBrandsMentionedWorked || displayBrandsDetected)) && (
       <div className="cp_account-insights-details_component-widget">
         <h6 className="cp_account-insights-details_component-widget-title">
           { translate("Brands") }
         </h6>
         <Divider />
         <BrandsWrapper
           displayBrandsMentionedWorked={ displayBrandsMentionedWorked }
           displayBrandsDetected={ displayBrandsDetected }
         />
       </div>
      ) }
      { displayPITraits && (
      <div className="cp_account-insights-details_component-widget">
        <PersonalityTraitsWrapper />
      </div>
      ) }
    </div>
  )
}
