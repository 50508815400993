import React from "react"
import "./style.sass"

import { useTranslation } from "react-i18next"

import Modal from "../Modal"

interface ModalListAccountsDataPointProps {
  open: boolean
  setOpen: (value: boolean) => void
}

export default function ModalListAccountsDataPoint({ open, setOpen }: ModalListAccountsDataPointProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalListAccountsDataPoint" })

  return (
    <Modal
      className="cp_component_list-acct-data-point-modal"
      title={ translate("Account Data Points") }
      open={ open }
      closeAction={ () => setOpen(false) }
      hasPrimaryButton={ false }
      hasSecondaryButton={ false }
      hasFooter={ false }
    >
      <p className="body_large header">{ translate("Engagement Score") }</p>
      <p className="body_small content">{ translate("Display the engagement score") }</p>
      <p className="body_large header">{ translate("Engagement Rate") }</p>
      <p className="body_small content">{ translate("Display the engagement rate") }</p>
      <p className="body_large header">{ translate("Audience Quality Score") }</p>
      <p className="body_small content">{ translate("Display the audience quality score") }</p>
      <p className="body_large header">{ translate("Ad Council Score") }</p>
      <p className="body_small content">{ translate("Display Ad Council proprietary account score") }</p>
      <p className="body_large header">{ translate("I-Score") }</p>
      <p className="body_small content">{ translate("Display OMD's proprietary account score") }</p>
      <p className="body_large header">{ translate("Demographic Score") }</p>
      <p className="body_small content">{ translate("Display match selected demographic parameters") }</p>
      <p className="body_large header">{ translate("None") }</p>
      <p className="body_small content">{ translate("No scores or engagement rate") }</p>
    </Modal>
  )
}
