import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import DetailsTab from "./DetailsTab"
import DisplayOptionsTab from "./DisplayOptionsTab"
import LoadingIndicator from "../LoadingIndicator"
import Modal from "../Modal"
import SuccessDialog from "../SuccessModal"
import Tabs, { TabLabelProps } from "../Tabs"
import { fetchList } from "../../state/listSlice"
import { pushToast } from "../../state/toastSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  manageListUpdate,
  resetCreateListForm,
  resetCreateListStatus,
  resetUpdateListStatus,
  getUpdateList,
  resetOriginalList,
  setListModalOpen,
} from "../../state/listModalFormSlice"

import "./style.sass"

export interface ListMode {
  isEditMode: boolean;
  listId: string;
}

function ListFormModal() {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ListFormModal" })
  const { t: translateEdit } = useTranslation("component.ModalUpdateList")
  const { t } = useTranslation("common")
  const [ loading, setLoading ] = useState(false)

  const dispatch = useDispatch()
  const listModalFormState = useSelector((state) => state.listModalForm)
  const {
    updateListStatus,
    createListStatus,
    listModalMode,
    listModalOpen,
  } = useSelector(({ listFormManager }) => listFormManager)

  useEffect(() => {
    if (listModalOpen && listModalMode.isEditMode && listModalMode.listId) {
      dispatch(getUpdateList(listModalMode.listId))
    }
  }, [ listModalOpen, listModalMode ])

  useEffect(() => {
    if (API.isError(updateListStatus)) {
      dispatch(pushToast({
        message: t("There was an error. Please try again!"),
        type: "error",
      }))
    }
  }, [ updateListStatus ])

  useEffect(() => {
    if (API.isError(createListStatus)) {
      dispatch(pushToast({
        message: t("There was an error. Please try again!"),
        type: "error",
      }))
    }
  }, [ createListStatus ])

  useEffect(() => {
    if (updateListStatus === "loading"
    || createListStatus === "loading") {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [ updateListStatus, createListStatus ])

  const navigate = useNavigate()
  const { vanity } = useParams()
  const tabs: TabLabelProps[] = [
    { label: translate("Details") }, { label: translate("Display Options") },
  ]
  // Local State
  const [ activeTab, setActiveTab ] = useState(0)
  const [ isSuccessDialogOpen, setIsSuccessDialogOpen ] = useState(false)

  // Handlers
  const handleTabChange = (newValue: number): void => {
    setActiveTab(newValue)
  }

  const closeAction = () => {
    dispatch(setListModalOpen(false))
  }

  const isCreateListDisable = () => {
    const {
      name,
      network,
      toggles,
      demographicScoreMinAge,
      demographicScoreMaxAge,
      demographicScoreGender,
      demographicScoreEthnicity,
      demographicScoreFamily,
      demographicScoreReligion,
      demographicScoreIncome,
      demographicScoreLocations,
      demographicScoreOccupations,
      demographicScoreAffinities,
    } = listModalFormState

    // Check if 'name' or 'network' is falsy
    if (!name || !network) return true

    // Check if 'ToggleDemographicScore' is included and validate demographic scores
    if (toggles.includes("ToggleDemographicScore")) {
      const demographicToggles = [
        demographicScoreMinAge,
        demographicScoreMaxAge,
        demographicScoreGender,
        demographicScoreEthnicity,
        demographicScoreFamily,
        demographicScoreReligion,
        demographicScoreIncome,
        demographicScoreLocations,
        demographicScoreOccupations,
        demographicScoreAffinities,
      ]
      return demographicToggles.every(
        (prop) => prop === null || (Array.isArray(prop) && prop.length === 0),
      )
    }

    return false
  }

  const CreateListFormReset = () => {
    dispatch(resetCreateListStatus())
    dispatch(resetUpdateListStatus())
    dispatch(resetCreateListForm())
  }

  const redirectoToNewList = () => {
    if ((createListStatus !== "init" && createListStatus !== "loading")) {
      if (API.isSuccess(createListStatus)) {
        CreateListFormReset()
        closeAction()
        navigate(`/${ vanity }/lists/${ createListStatus.payload.createSuggestionList.id }`)
      }
    }
  }

  const handleCloseSuccessDialog = () => {
    CreateListFormReset()
    closeAction()
    setIsSuccessDialogOpen(false)
    if (listModalMode.listId) {
      dispatch(fetchList(listModalMode.listId))
    }
  }

  const handleCloseUpdateModal = () => {
    CreateListFormReset()
    closeAction()
  }

  const submitList = () => {
    setIsSuccessDialogOpen(true)
    if (listModalFormState.network) {
      if (listModalMode.listId) {
        dispatch(resetOriginalList())
        dispatch(manageListUpdate(listModalMode.listId, listModalFormState))
      } else {
        dispatch(manageListUpdate(null, listModalFormState))
      }
    }
  }

  if (API.isSuccess(createListStatus)) {
    return (
      <SuccessDialog
        isOpen={ isSuccessDialogOpen }
        handleClose={ handleCloseSuccessDialog }
        dialogAction={ redirectoToNewList }
        dialogTitle={ translate("Create List") }
        dialogContextText={ translate("Create list successful!") }
        buttonLabel={ translate("GO TO LIST") }
      />
    )
  }

  if (API.isSuccess(updateListStatus)) {
    return (
      <SuccessDialog
        isOpen={ isSuccessDialogOpen }
        handleClose={ handleCloseSuccessDialog }
        dialogAction={ handleCloseSuccessDialog }
        dialogTitle={ translate("Update List") }
        dialogContextText={ translate("Update list successful!") }
        buttonLabel={ translate("GO BACK") }
      />
    )
  }

  const mainCTALabel = listModalMode.isEditMode ? translateEdit("Save List") : translate("Create List")
  const isEditModeReady = listModalMode.isEditMode && !listModalFormState.name

  return (
    <Modal
      title={ listModalMode.isEditMode
        ? translate("Edit List")
        : translate("Create List")
      }
      primaryLabel={ loading ? <LoadingIndicator /> : mainCTALabel }
      secondaryLabel={ t("Cancel") }
      open={ listModalOpen }
      closeAction={ listModalMode.isEditMode ? handleCloseUpdateModal : closeAction }
      secondaryAction={ listModalMode.isEditMode ? handleCloseUpdateModal : closeAction }
      primaryAction={ submitList }
      maxWidth="xl"
      className="cp_component_modal-create-list"
      disabled={ loading || isCreateListDisable() }
    >
      { (isEditModeReady)
        ? (
          <div className="cp_component_modal-create-list_wrapper-empty">
            <LoadingIndicator size={ 60 } />
          </div>
        )
        : (
          <div className="cp_component_modal-create-list_wrapper">
            <Tabs
              className="cp_component_modal-create-list_tabs"
              tabs={ tabs }
              handleChange={ handleTabChange }
              defaultValue={ activeTab }
            />
            <div hidden={ activeTab !== 0 }>
              <DetailsTab mode={ listModalMode } />
            </div>

            <div hidden={ activeTab !== 1 }>
              <DisplayOptionsTab mode={ listModalMode } />
            </div>
          </div>
        )
      }
    </Modal>
  )
}

export default ListFormModal
