import React from "react"
import "./style.sass"
import { useSelector } from "../../state/hooks"
import Avatar from "../Avatar"
import * as API from "../../util/apiClient"
import UserSettingsTabs from "../UserSettingsTabs"

export default function UserSettingsOverview() {
  const { currentUser } = useSelector(({ user }) => ({ currentUser: user.user }))
  const user = (API.isSuccess(currentUser)) ? currentUser.payload.currentUser : undefined

  const hasImage = (user?.contact.avatar.url.address)
  const isGenericPic = (hasImage) ? user.contact.avatar.url.address.includes("/user-avatar.png") : false
  const firstLetterName = (user)
    ? (user.contact.name) ? user.contact.name.substring(0, 1).toUpperCase() : ""
    : ""

  return (
    <div className="cp_component_user-settings">
      <div className="cp_component_user-settings-overview">
        <div className="cp_component_user-settings-overview_user-avatar">
          <div className="avatar_image-container">
            <Avatar
              size="lg"
              src={ (hasImage && !isGenericPic) ? user.contact.avatar.url.address : undefined }
              className="avatar_image-container_avatar"
            >
              { (!hasImage || isGenericPic) ? firstLetterName : null }
            </Avatar>
          </div>
          <div className="avatar_user-info">
            <div className="avatar_user-info_name">
              { user?.contact.name }
            </div>
            <div className="avatar_user-info_company">
              { user?.customer.company?.name }
            </div>
          </div>
        </div>
      </div>
      <div className="cp_component_user-settings-tabs">
        <UserSettingsTabs />
      </div>
    </div>
  )
}
