import React, { JSX, useMemo } from "react"
import { EditNote } from "@mui/icons-material"
import { useTranslation } from "react-i18next"

import Content from "./Content"
import EditUrlCard from "./EditUrlCard"
import History from "./History"
import IconButton from "../IconButton"
import Modal from "../Modal"
import Overview from "./Overview"
import PostPreview from "./PostPreview"
import StatusDeliverable from "../StatusDeliverable"
// import { isSuccess } from "../../util/apiClient"
// import { openTikTokInviteModal } from "../../state/tikTokInviteLinkModalSlice"
import { useSelector, useDispatch } from "../../state/hooks"
import { DeliverableStatus, Network } from "../../graphql"
import { Scope } from "../../util/types"
import { setEdittingUrl, closeDeliverableContentModal } from "../../state/campaignDeliverableContentModalV2Slice"

import "./style.sass"

function ModalCampaignDeliverableContentV2(): React.JSX.Element {
  const {
    modalOpen,
    deliverable,
    edittingUrl,
    onClose,
    hidden,
  } = useSelector(({ campaignDeliverableContentModalV2 }) => campaignDeliverableContentModalV2)

  const { scopes } = useSelector(({ user: userSlice }) => userSlice)

  // const availableAccounts = React.useMemo(() => campaign?.campaignNetworkAccounts || [], [ campaign ])

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableContent" })

  const dispatch = useDispatch()

  const closeModal = async () => {
    dispatch(closeDeliverableContentModal())
    if (onClose) onClose(true)
  }

  function customActions(): JSX.Element[] {
    if (!deliverable) return [ <> </> ]

    const componentRight = (
      <div className="cp_deliverable-content-overview_component-right">
        <StatusDeliverable deliverableStatus={ deliverable.contentStatus } />
        { deliverable.contentStatus === DeliverableStatus.Live && scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <IconButton
            variant={ edittingUrl ? "filled" : "outlined" }
            onClick={ () => dispatch(setEdittingUrl(!edittingUrl)) }
          >
            <EditNote />
          </IconButton>
        ) }
      </div>
    )
    return [ componentRight ]
  }

  const primaryButtonLabel = useMemo(() => {
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !deliverable.tiktokTcmOrder?.id
    ) return translate("Generate Invite Link")
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      || deliverable?.contentStatus === DeliverableStatus.Uploaded
    ) return translate("Go Live")
    return translate("Finalize Deliverable")
  }, [ deliverable ])

  /* const getDeliverableHistory = async () => {
    if (!deliverable?.id) return
    dispatch(getHistory({ deliverableId: deliverable.id }))
  }

  useEffect(() => {
    getDeliverableHistory()
  }, [ deliverable ])

  const finalizeDeliverableHandler = () => dispatch(openFinalizeConfirmModal())

  const generateTikTokLink = () => {
    if (!deliverable) return
    dispatch(closeDeliverableContentModal())
    dispatch(openTikTokInviteModal({
      deliverable,
      onGenerateLink: () => onClose,
      onClose: () => onClose,
    }))
  }

  const isInValid = React.useMemo(() => {
    if (!deliverable) return true
    if (deliverable.deliverableMedia.length === 0 && !deliverable.deliverableCaption) return true
    if (deliverable.deliverableMedia.length > 0 && !deliverable.deliverableMedia.some((media) => media.accepted)) return true
    if (deliverable.deliverableCaption && !deliverable.deliverableCaption.accepted) return true
    return false
  }, [ deliverable ])

  const primaryButtonLabel = useMemo(() => {
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !deliverable.tiktokTcmOrder?.id
    ) return translate("Generate Invite Link")
    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      || deliverable?.contentStatus === DeliverableStatus.Uploaded
    ) return translate("Go Live")
    return translate("Finalize Deliverable")
  }, [ deliverable ])

  const hasPermission = React.useMemo(() => {
    if (!deliverable || !campaign || !isSuccess(user)) return false

    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id
    const brandManager = campaign.userPermissions.find((permission) => permission.user.id === user.payload.currentUser?.id)
    const brandManagerWithApprovalPermission = !!brandManager && brandManager.permissions
      .includes(CampaignPermissionType.Approve)

    if (deliverable?.contentStatus === DeliverableStatus.Finalized
      && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !deliverable.tiktokTcmOrder?.id
      && !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    //
    // Brand Managers with Approval permission can finalize deliverables
    //
    if (
      (deliverable.contentStatus === DeliverableStatus.Pending
        || deliverable.contentStatus === DeliverableStatus.AwaitingContent
      )
        // eslint-disable-next-line max-len
        && (!userTeamIsCreator && brandManagerWithApprovalPermission)
    ) {
      return true
    }

    if (!scopes.includes(Scope.CAMPAIGN_MANAGEMENT) || !scopes.includes(Scope.FEATURE_CAMPAIGN)) {
      return false
    }
    if (deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    return true
  }, [ deliverable, scopes, campaign, user ])

  const showPrimaryButton = React.useMemo(() => {
    if (!deliverable || !campaign || !isSuccess(user)) return false

    const userTeamIsCreator = deliverable?.creator.customer.id === user.payload.currentUser?.customer.id

      Brand Managers with Approval permission can ONLY finalize deliverables. Hide the button if
      the deliverable is not in a state that can be finalized.

    if (!userTeamIsCreator
      && (deliverable.contentStatus !== DeliverableStatus.AwaitingContent
        && deliverable.contentStatus !== DeliverableStatus.Pending)
    ) {
      return false
    }
    if (deliverable.contentStatus === DeliverableStatus.Live) return false
    if (deliverable?.contentStatus === DeliverableStatus.Uploaded
      && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    if (deliverable.contentStatus === DeliverableStatus.Finalized
      && !scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    if (deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
      && deliverable.contentStatus === DeliverableStatus.Finalized
      && !scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
    ) {
      return false
    }
    return true
  }, [ deliverable, scopes ])
  */

  if (!deliverable) return <> </>

  return (
    <Modal
      hidden={ hidden }
      open={ modalOpen }
      title={ deliverable?.name }
      clipboardTitle={ deliverable?.name }
      primaryLabel={ primaryButtonLabel } // TODO: Add rules
      secondaryLabel={ translate("Cancel") }
      closeAction={ closeModal }
      secondaryAction={ closeModal }
      customTopRightActions={ customActions() }
      primaryAction={ () => null } // TODO: Implement CTA
      maxWidth="xl"
      className="cp_component_modal-campaign-deliverable-content"
      hasPrimaryButton={ true } // TODO: Implement CTA
      disabled={ false } // TODO: Implement CTA
      // primaryToolTip={ isInValid
      //  ? translate("To finalize this deliverable the post media and caption must be approved.") : undefined
      // }
    >
      <Overview />
      { edittingUrl && (
        <EditUrlCard
          loadedUrl={ deliverable?.liveStatusUrl?.address }
          deliverableId={ deliverable.id }
        />
      ) }
      <div className="cp_component_modal-campaign-deliverable-content-columns">
        <div className="content-history">
          <div className="content-history-title">
            { translate("Content") }
          </div>
          <Content />
          <History />
        </div>
        <PostPreview />
      </div>
    </Modal>
  )
}

export default ModalCampaignDeliverableContentV2
