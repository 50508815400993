import React, {
  JSX,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

// TODO: Replace DataGridPro component with our own custom DataGrid component
// and resolve style conflicts thereunto pertaining...
import {
  DataGridPro,
  GridCellParams,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridTreeNode,
} from "@mui/x-data-grid-pro"
import {
  LinearProgress,
  Link,
  Typography,
} from "@mui/material"
import { WarningRounded } from "@mui/icons-material"
import { useNavigate, useParams } from "react-router-dom"

import * as API from "../../util/apiClient"
import * as AccountsTable from "./AccountsTable"
import * as ListAddAccountState from "../../state/listAddAccount"
import * as SearchGQL from "../../graphql/search"
import * as SearchState from "../../state/searchAISlice"
import { openModalSearchAISummary } from "../../state/ModalSearchAISummary"
import { Accounts, SearchColumn } from "../../state/searchAISlice/helper"
import AccountsEmptySplash from "./AccountsEmptySplash"
import ModalScoreBreakDown from "../ModalScoreBreakDown"
import { ScoreBreakDown } from "../../util/types"
import { rawDataToScoreBreakDown, resetScoreBreakDown } from "../../state/scoreBreakDownSlice"
import { useDispatch, useSelector } from "../../state/hooks"
import * as Misc from "../../util/miscHelper"

import "./style.sass"
import EmptySplash from "../EmptySplash"
import { HREF_AI_SEARCH_GUIDE } from "../../util/constant"

export default function SearchAIAccountsTable(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.SearchAIResultsTable" })

  const {
    t: translateCommon,
  } = useTranslation([], { keyPrefix: "common" })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { vanity } = useParams()

  const [ scoreModalOpen, setScoreModalOpen ] = useState(false)

  const {
    searchResultsAccounts,
    selectedAccounts: searchSelectedAccounts,
    searchAIInput,
  } = useSelector(({ searchAI }) => searchAI)

  const scopes = useSelector(({ user: userSlice }) => userSlice.scopes)

  // On unmount, clean up search state
  useEffect(() => () => {
    dispatch(SearchState.setSearchResultsAccounts("init"))
    dispatch(SearchState.setSelectedAccounts([]))
    dispatch(ListAddAccountState.clearSelectedItems())
  }, [])

  useEffect(() => {
    if (scoreModalOpen === false) {
      dispatch(resetScoreBreakDown())
    }
  }, [ scoreModalOpen ])

  const handleScoreModal = (scoreBreakDown: ScoreBreakDown, modalType: string, isAdCouncilScore?: boolean) => {
    dispatch(rawDataToScoreBreakDown(scoreBreakDown, modalType, isAdCouncilScore))
    setScoreModalOpen(true)
  }

  const cellClickAction = (
    { field, row }: GridCellParams<
    any, unknown, unknown, GridTreeNode
  >,
  ): void => {
    const socialAccountId = row.id

    // NAVIGATE TO ACCOUNT PROFILE
    // If cell is "account" on either table, navigate to profile page
    if (field === "account" && row.account.hasPersonality) {
      navigate(Misc.generateProfileUrl(socialAccountId, vanity || ""))
      return
    }

    // NAVIGATE TO SEARCH SOCIAL ACCOUNT SUBPAGE
    // If cell is "account" on either table, navigate to social account page
    if (field === "account") {
      navigate(Misc.generateSearchSocialAccountUrl(
        socialAccountId,
        vanity || "",
      ))
    }
    if (field === SearchColumn.Summary) dispatch(openModalSearchAISummary(row.summary))
  }

  const tableColumns = useMemo(() => AccountsTable.generateAccountResultsColumns(
    translate,
    scopes,
    handleScoreModal,
  ), [ searchResultsAccounts ])

  const tableRows = useMemo(
    () => {
      if (!API.isSuccess(searchResultsAccounts)) return []

      return searchResultsAccounts.payload.searchAccounts.accounts
        .map((r: SearchGQL.SearchAccountsTableRowFragment) => AccountsTable
          .generateAccountResultsRow(r, translateCommon, searchAIInput.profileParams.bioKeywords || []))
    },
    [ searchResultsAccounts, scopes, searchAIInput.contentParams?.keywords ],
  )

  const tableClassName = tableRows.length === 0
    ? "cp_component_search-results-table "
    + "cp_component_search-results-table-empty"
    : "cp_component_search-results-table"

  const handleRowSelectability = (params: GridRowParams<any>): boolean => {
    if (searchSelectedAccounts.length === 0) return true
    const selectedNetwork = searchSelectedAccounts[0].network
    return params.row.account.network === selectedNetwork
  }

  const handleSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ): void => {
    if (searchResultsAccounts === "loading" || !API.isSuccess(searchResultsAccounts)) return
    const sanitizedRowIds: string[] = rowSelectionModel
      .filter((r: GridRowId): r is string => typeof r === "string")

    const newSelection: Accounts = searchResultsAccounts
      .payload
      .searchAccounts
      .accounts
      .filter(({ id }) => sanitizedRowIds.includes(id))

    dispatch(SearchState.setSelectedAccounts(newSelection))
  }

  const table = useMemo(
    () => (
      <div className={ tableClassName }>
        <DataGridPro
          checkboxSelection={ true }
          columns={ tableColumns }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          isRowSelectable={ handleRowSelectability }
          loading={ searchResultsAccounts === "loading" }
          onCellClick={ cellClickAction }
          onRowSelectionModelChange={ handleSelectionModelChange }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "ellipsisMenu" ],
          } }
          rowHeight={ 80 }
          rows={ tableRows }
          rowSelectionModel={ searchSelectedAccounts.map(({ id }) => id) }
          slots={ {
            loadingOverlay: LinearProgress,
            noResultsOverlay: AccountsEmptySplash,
            noRowsOverlay: AccountsEmptySplash,
          } }
        />
        <ModalScoreBreakDown isModalScoreOpen={ scoreModalOpen } closeModal={ () => setScoreModalOpen(false) } />
      </div>
    ),
    [ searchResultsAccounts,
      searchSelectedAccounts,
      scoreModalOpen,
    ],
  )

  if (searchResultsAccounts === "init") {
    return (
      <div className="cp_component_search-results-table">
        <aside className="cp_component_search-results-table-placeholder">
          <div>
            <EmptySplash>
              <>
                <h4>
                  { translate("Click") }
                  <em>
                    { " " }
                    { translate("Search") }
                    { " " }
                  </em>
                  { translate("to see results") }
                </h4>
                <Typography>
                  { translate("New to our AI Search?") }
                  <br />
                  { translate("Learn the basics of our Influential AI search or our filtered search") }
                  { " " }
                  <Link
                    href={ HREF_AI_SEARCH_GUIDE }
                    underline="hover"
                    target="_blank"
                  >
                    { translate("here") }
                  </Link>
                </Typography>
              </>
            </EmptySplash>
          </div>
        </aside>
      </div>
    )
  }

  if (searchResultsAccounts === "loading") {
    return table
  }

  if (API.isError(searchResultsAccounts)) {
    return (
      <div className="cp_component_search-results-table">
        <aside className="cp_component_search-results-table-placeholder">
          <div>
            <WarningRounded />
            <h3>{ translate("Something went wrong...") }</h3>
          </div>
        </aside>
      </div>
    )
  }

  return table
}
