import React, { useState } from "react"
import CopyIconButton from "../../../CopyIconButton"

interface HoverableCopyableTextProps {
  text: string
  containerClass: string
  contentClass: string
}

export default function HoverableCopyableText({
  text,
  containerClass,
  contentClass,
}: HoverableCopyableTextProps) {
  const [ activeCopyIcon, setActiveCopyIcon ] = useState(false)

  function handleMouseEnter() {
    setActiveCopyIcon(true)
  }

  function handleMouseLeave() {
    setActiveCopyIcon(false)
  }

  return (
    <div
      className={ containerClass }
      onMouseEnter={ handleMouseEnter }
      onMouseLeave={ handleMouseLeave }
    >
      <p className={ `${ contentClass } ${ activeCopyIcon ? "display-icon" : "" }` }>
        { text }
      </p>
      { activeCopyIcon && <CopyIconButton textToCopy={ text } /> }
    </div>
  )
}
