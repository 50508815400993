import React from "react"
import { TFunction } from "i18next"
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro"
import daysjs from "dayjs"
import { useDispatch, useSelector } from "../../../state/hooks"
import SocialAvatar from "../../SocialAvatar"
import * as Constant from "../../../util/constant"
import * as GraphQL from "../../../graphql"
import GroupAccountsTableContextMenu from "./ContextMenu"
import { Scope, Toast } from "../../../util/types"
import Pill from "../../Pill"
import { getGroupAccounts, removeLabelFromSocialAccount } from "../../../state/communicationsPage"
import { pushToast } from "../../../state/toastSlice"
import { GroupAccountFilters } from "../../../state/ModalGroupAccountsFilterSlice"

// Set the result type from GraphQL query
type GroupAccountsNetworkGroupAccount =
  GraphQL.SearchGroupAccountsByCommGroupIdQuery["searchCommunicationGroupNetworkAccount"]["rows"][number]

export function getColumnHeaders(commsGroupId: string, searchInputValue: string, translate: TFunction): GridColDef[] {
  // Set the dispatch
  const dispatch = useDispatch()

  // Get sope and label colors
  const { scopes } = useSelector(({ user }) => user)

  /**
   * Deletes the label from the database and refreshes the table
   * @param labelId The ID value for the label
   */
  const deleteLabelFromAccount = (labelId: string) => {
    // Issue delete for pill
    dispatch(removeLabelFromSocialAccount({
      vars: {
        socialAccountLabelId: labelId,
      },
      onSuccess: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "success",
          message: translate("Successfully removed label from account"),
        }
        dispatch(pushToast(toast))

        // Reload group accounts
        dispatch(getGroupAccounts({
          startsWith: searchInputValue,
          communicationGroupId: `${ commsGroupId }`,
        }))
      },
      onError: () => {
        // Create and submit toast
        const toast: Toast = {
          type: "error",
          message: translate("Failed to remove label from account"),
        }
        dispatch(pushToast(toast))
      },
    }))
  }

  /**
   * Create a pill using the color index generated after the pull from database
   * @param label The label to create pill from
   * @returns The pill element
   */
  const renderLabelPill = (label: GraphQL.CommunicationGroupNetworkAccountLabel) => {
    // Check to see if it was found
    if (Constant.COMM_GROUP_LABELS.includes(label.label.toLowerCase())) {
      // Return the label with correct color
      return (
        <Pill
          id={ label.id }
          className={ `custom-pill-color-${ label.label.toLowerCase().replaceAll(" ", "-") }` }
          label={ label.label }
          onDelete={ () => { deleteLabelFromAccount(label.id) } }
        />
      )
    }

    // Retrun label with default color
    return (
      <Pill
        id={ label.id }
        label={ label.label }
        onDelete={ () => { deleteLabelFromAccount(label.id) } }
      />
    )
  }

  // Check to see if social account contact info is not in scope
  if (!scopes.includes(Scope.SOCIAL_ACCOUNT_CONTACT_INFO)) {
    // Return the header information without the contact info column
    return [
      {
        field: "account",
        headerName: translate("ACCOUNT"),
        sortable: false,
        renderCell: (params) => (
          <SocialAvatar { ...params.row.account } />
        ),
        disableColumnMenu: true,
        resizable: false,
        width: 275,
      },
      {
        field: "labels",
        headerName: translate("LABELS"),
        sortable: false,
        renderCell: (params) => (
          <div id="label-pills">
            { params.row.labels.map((label: GraphQL.CommunicationGroupNetworkAccountLabel) => renderLabelPill(label)) }
          </div>
        ),
        disableColumnMenu: true,
        resizable: false,
        width: 350,
      },
      {
        field: "addedToGroup",
        headerName: translate("ADDED TO GROUP"),
        sortable: false,
        renderCell: (params) => (
          <p className="body_medium">{ params.row.addedToGroup }</p>
        ),
        disableColumnMenu: true,
        resizable: false,
        width: 200,
      },
      {
        field: "groupsContextMenu",
        headerName: "",
        sortable: false,
        renderCell: (params) => (
          <GroupAccountsTableContextMenu params={ params } />
        ),
        disableColumnMenu: true,
        width: 50,
      },
    ]
  }

  // Return the header information
  return [
    {
      field: "account",
      headerName: translate("ACCOUNT"),
      sortable: false,
      renderCell: (params) => (
        <SocialAvatar { ...params.row.account } />
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 275,
    },
    {
      field: "contactInfo",
      headerName: translate("CONTACT INFO"),
      sortable: false,
      renderCell: (params) => (
        <div>
          <p className="body_medium">
            { params.row.contactInfo.firstName }
            &nbsp;
            { params.row.contactInfo.lastName }
          </p>
          <p className="body_small">
            { params.row.contactInfo.email }
          </p>
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 275,
    },
    {
      field: "labels",
      headerName: translate("LABELS"),
      sortable: false,
      renderCell: (params) => (
        <div id="label-pills">
          { params.row.labels.map((label: GraphQL.CommunicationGroupNetworkAccountLabel) => renderLabelPill(label)) }
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 350,
    },
    {
      field: "addedToGroup",
      headerName: translate("ADDED TO GROUP"),
      sortable: false,
      renderCell: (params) => (
        <p className="body_medium">{ params.row.addedToGroup }</p>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 200,
    },
    {
      field: "groupsContextMenu",
      headerName: "",
      sortable: false,
      renderCell: (params) => (
        <GroupAccountsTableContextMenu params={ params } />
      ),
      disableColumnMenu: true,
      width: 50,
    },
  ]
}

export function hasFilters(filters: GroupAccountFilters) {
  if (!(filters.dateAdded)
    && filters.emailStatus.length === 0
    && filters.labelStatus.length === 0
    && filters.listCategories.length === 0
    && filters.listStatus.length === 0
    && filters.networks.length === 0) return false
  return true
}

export function applyFiltersToRow(row: GroupAccountsNetworkGroupAccount, filters: GroupAccountFilters) {
  // Return true if no filters
  if (!(filters.dateAdded)
    && filters.emailStatus.length === 0
    && filters.labelStatus.length === 0
    && filters.listCategories.length === 0
    && filters.listStatus.length === 0
    && filters.networks.length === 0) return true

  // Check network filters
  let networkShow = false
  if (filters.networks.length > 0) networkShow = filters.networks.includes(row.socialAccount.network)
  else networkShow = true

  // Check date added filter
  let dateAddedShow = false
  if (filters.dateAdded) {
    const created = daysjs.unix(row.created).format(Constant.INPUT_DATE_FORMAT)
    dateAddedShow = (filters.dateAdded === created)
  } else dateAddedShow = true

  // Check list status filter
  let listStatusShow = false
  if (filters.listStatus.length > 0) {
    // Determine what's check
    const approve = filters.listStatus.includes("Approved")
    const notApprove = filters.listStatus.includes("Not Approved")
    const approvalStatus = row.statuses.find((sts) => sts.type === GraphQL.SuggestionListSocialAccountStatusType.Approval)

    // If both checked, then show it all
    if (approve && notApprove) listStatusShow = true
    else if (approvalStatus) {
      // Check wich one is checked
      if (approve && approvalStatus.value) listStatusShow = true
      if (notApprove && !approvalStatus.value) listStatusShow = true
    } else if (notApprove && !approve) listStatusShow = true
  } else listStatusShow = true

  // Check label status filter
  let labelStatusShow = false
  if (filters.labelStatus.length > 0) {
    // Set constant values
    const hasLabel: boolean = filters.labelStatus.includes("Has Label")
    const noLabel: boolean = filters.labelStatus.includes("No Label")
    const labelIncludes = row.labels.filter((lbl) => filters.labelStatus.includes(lbl.label))
    let hasLabelShow = false
    let noLabelShow = false
    let otherLabelSHow = false

    // Check the filter
    if ((hasLabel && !noLabel) || (!hasLabel && noLabel)) {
      if (hasLabel) hasLabelShow = (row.labels.length > 0)
      else hasLabelShow = true
      if (noLabel) noLabelShow = (row.labels.length === 0)
      else noLabelShow = true
    } if (!hasLabel && !noLabel) {
      hasLabelShow = true
      noLabelShow = true
    }

    if (hasLabel && filters.labelStatus.length === 1) otherLabelSHow = true
    else if (noLabel && filters.labelStatus.length === 1) otherLabelSHow = true
    else if (hasLabel && labelIncludes.length > 0) otherLabelSHow = true
    else if (noLabel && labelIncludes.length === 0) otherLabelSHow = true
    else otherLabelSHow = (labelIncludes.length > 0)

    // Determine if we should show
    labelStatusShow = (hasLabelShow && noLabelShow && otherLabelSHow)
  } else labelStatusShow = true

  // Check list categories filter
  let categoryShow = false
  if (filters.listCategories.length > 0) {
    // Pull out categories that are included
    const includedCats = row.suggestionListCategories.filter((cat) => filters.listCategories.includes(cat.name))

    // Check to see if filtered
    categoryShow = (includedCats.length > 0)
  } else categoryShow = true

  // Check email status filter
  let emailShow = false
  if (filters.emailStatus.length > 0) {
    // Set up constants to make easier to read
    const noEmail = filters.emailStatus.includes("No Email")
    const unsubscribed = filters.emailStatus.includes("Unsubscribed")
    const hasEmail = (row.socialAccount.primaryEmail)
    let hasUnsubscribedTags = false

    // Get the unsubscribed tags
    if ((row.socialAccount.primaryEmail)
      && row.socialAccount.primaryEmail.unsubscribedTags.length > 0
      && row.socialAccount.emails.filter((email) => email.unsubscribedTags.length > 0).length > 0
      && row.socialAccount.emailsSourcedFromTeam.filter((email) => email.unsubscribedTags.length > 0).length > 0
    ) hasUnsubscribedTags = true
    else hasUnsubscribedTags = false

    // Perform filter checks
    let noEmailShow = false
    let unscubscribedShow = false
    if (noEmail) noEmailShow = !hasEmail
    else noEmailShow = true
    if (unsubscribed) unscubscribedShow = hasUnsubscribedTags
    else unscubscribedShow = true

    // Set the show
    emailShow = (noEmailShow && unscubscribedShow)
  } else emailShow = true

  // Return the default if all fail above
  return (networkShow && dateAddedShow && listStatusShow && labelStatusShow && categoryShow && emailShow)
}

export function getRowData(row: GroupAccountsNetworkGroupAccount): GridValidRowModel | null {
  // Extract the social account and the statistics
  const { socialAccount } = row
  const { socialAccountStatistics: stats } = socialAccount

  // Create the data for this row
  return {
    id: row.id,
    account: {
      id: socialAccount.id,
      followers: stats.followers,
      fullName: socialAccount.name || "",
      isBlacklisted: socialAccount.personality?.blacklist || false,
      isNSFW: socialAccount.isNsfw,
      profilePictureUrl: socialAccount.profilePictureUrl,
      username: socialAccount.userName,
      network: socialAccount.network,
      isPlaceholder: socialAccount.isPlaceholder,
      emails: socialAccount.emails,
      emailsFromTeam: socialAccount.emailsSourcedFromTeam,
      websiteUrl: socialAccount.websiteUrl,
    },
    contactInfo: {
      firstName: socialAccount.contact?.firstName || "First Name",
      lastName: socialAccount.contact?.lastName || "Last Name",
      email: socialAccount.primaryEmail?.address || "No Primary Email",
    },
    labels: row.labels,
    addedToGroup: daysjs.unix(row.created).format(Constant.MONTH_DAY_ABBR_FORMAT),
  }
}
