import * as React from "react"
import "./style.sass"

import ConfigurationVisualizations from "./ConfigurationVisualizations"
import ConfigurationPreview from "./ConfigurationPreview"
import { useDispatch, useSelector } from "../../state/hooks"
import { populateState } from "../../state/listConfigurationSlice"
import { isSuccess } from "../../util/apiClient"

export default function ListConfiguration() {
  const dispatch = useDispatch()
  const { list } = useSelector((state) => state.list)

  React.useEffect(() => {
    if (isSuccess(list)) {
      dispatch(populateState())
    }
  }, [ list ])

  return (
    <div className="cp_list-configuration-wrapper">
      <ConfigurationVisualizations />
      <ConfigurationPreview />
    </div>
  )
}
